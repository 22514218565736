import { Img } from 'react-image';
import { CircularProgress, Box } from '@mui/material';
import React from 'react';

export default function Image({
  src,
  alt = '',
  loaderBoxStyle = {},
  circularProgressStyle = {},
  fallbackSrc,
  clickHandler
}) {

  const handleClick = clickHandler || (() => {});

  return (
    <Img
      src={src}
      alt={alt}
      onClick={handleClick}
      loader={
        (
          <Box sx={loaderBoxStyle}>
            <CircularProgress
              sx={circularProgressStyle}
            />
          </Box>
        )
      }
    />
  )
}
