/* eslint-disable default-param-last */
import {
  ADD_USER_ADDRESSES,
  DELETE_USER_ADDRESS,
  GET_USER_REQUEST, GET_USER_REQUEST_FAIL,
  GET_USER_REQUEST_SUCCESS,
  LOGIN_REQUEST,
  LOGIN_REQUEST_FAIL,
  LOGIN_REQUEST_SUCCESS,
  LOGOUT_REQUEST,
  LOGOUT_REQUEST_FAIL,
  LOGOUT_REQUEST_SUCCESS,
  REQUIRE_AUTH,
  UPDATE_ERROR,
  UPDATE_USER_ADDRESS,
  UPDATE_USER_POINTS
} from './userTypes';

const defaultState = {
  loading: false,
  isLoggedIn: false,
  data: {},
  lastUser: {},
  error: '',
  errors: {},
  requireAuth: false,
};

const reducer = (state = defaultState, action) => {

  switch (action.type) {

    // login
    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true
      }

    case LOGIN_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        data: action.payload,
        lastUser: {},
        error: '',
        errors: {}
      }

    case LOGIN_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        data: {},
        error: action.payload?.message,
        errors: action.payload?.errors || {}
      }
    case UPDATE_USER_POINTS:
      return {
        ...state,
        data: {
          ...state.data,
          point: {
            ...state.data.point,
            points: action.payload,
          }
        },
      }

    // get user
    case GET_USER_REQUEST:
      return {
        ...state,
        loading: true
      }

    case GET_USER_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        data: action.payload,
        error: '',
        errors: {}
      }

    case ADD_USER_ADDRESSES:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          addresses: [
            ...state.data.addresses,
            action.payload.data
          ]
        },
      }

    case DELETE_USER_ADDRESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          addresses: [
            ...userAddressDelete(state.data.addresses, action.payload.data),
          ]
        },
      }

    case UPDATE_USER_ADDRESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          addresses: [
            ...userAddressUpdate(state.data?.addresses, action.payload?.data),
          ]
        },
      }

    case GET_USER_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        data: {},
        error: action.payload?.message,
        errors: action.payload?.errors || {}
      }

    // logout
    case LOGOUT_REQUEST:
      return {
        ...state,
        loading: true
      }

    case LOGOUT_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        data: {},
        lastUser: action.payload,
        error: '',
        errors: {}
      }

    case LOGOUT_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        errors: action.payload?.errors || {}
      }
    case UPDATE_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case REQUIRE_AUTH:
      return {
        ...state,
        requireAuth: action.payload,
      }

    default:
      return state
  }

};

export default reducer;


function userAddressDelete(addresses, address) {

  const existingAddress = addresses.filter((addr) => addr.id !== address.id);

  // if item not found just return all items
  if (!existingAddress.length) {
    return '';
  }

  return existingAddress;
}

function userAddressUpdate(addresses, address) {
  const existingAddressIndex = addresses.findIndex((addr) => addr.id === address.id);

  // if item not found just return all items
  if (existingAddressIndex === -1) {
    return addresses;
  }

  addresses.splice(existingAddressIndex, 1, address);

  return addresses;
}
