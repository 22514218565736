/* eslint-disable default-param-last */
import {
  ADDED_MENUS, IS_SET_MENU_SELECTED,
  SELECTED_MENU
} from './menuTypes';

const defaultState = {
  categories: [],
  items: [],
  set_menus: [],
  isSetMenuSelected: false,
  selectedCategory: {},
  selectedItems: [],
};


const MenuReducer = (state = defaultState, { type, payload }) => {

  switch (type) {

    case ADDED_MENUS:
      return {
        ...state,
        categories: [...payload.categories],
        items: { ...payload.items },
        set_menus: [...payload.set_menus]
      }
    case SELECTED_MENU:
      return {
        ...state,
        selectedCategory: { ...payload.selectedCategory },
        selectedItems: [payload.selectedItems],
      }
    case IS_SET_MENU_SELECTED:
      return {
        ...state,
        isSetMenuSelected: payload,
      }

    default: return state
  }

};

export default MenuReducer;
