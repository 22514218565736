/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react';
import PageHeader from '../../components/pageHeader/pageHeader';
import { ImageList, ImageListItem, styled } from '@mui/material';
import useSetting from '../../hooks/useSetting';
import { SETTING_FILE_PATH } from '../../util/constants';
import { connect } from 'react-redux';
import '../../assets/css/custom.scss';
import Image from '../../components/util/Image';
import { isMobile } from 'react-device-detect';
import Lightbox from 'react-image-lightbox';
import PropTypes from 'prop-types';
import 'react-image-lightbox/style.css';
import SlideAnimation from '../../components/util/Animations/SlideAnimation';
import { updateFirstArrival } from '../../store/layout/layoutActions';


function GalleryPage({ imagePerCol = 4, ...otherProps }) {

  const styles = {
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: (theme) => theme.palette.background.paper,
    },
    gridList: {
      width: '100%',
      height: '100%',
    },
    onHover: {
      cursor: 'zoom-in',
    },
    onImageBox: {
      textAlign: 'center',
      marginBottom: '5px',
    },
    circularProgress: {
      color: 'primary',
      size: '4rem',
      thickness: 1.6,
    },
  };

  const Div = styled('div')``;

  const gallerySetting = useSetting([
    'gallery_img_1',
    'gallery_img_2',
    'gallery_img_3',
    'gallery_img_4',
    'gallery_img_5',
    'gallery_img_6',
    'gallery_img_7',
    'gallery_img_8',

  ]);


  const [imagePopup, setImagePopup] = useState(false);

  const [photoIndex, setPhotoIndex] = useState(0);

  const [imageSize, setImageSize] = useState(350);

  const tileData = [
    {
      img: gallerySetting?.gallery_img_1,
      title: 'Image',
      author: 'author',
      cols: 2,
    },
    {
      img: gallerySetting?.gallery_img_2,
      title: 'Image',
      author: 'author',
      cols: 1,
    },
    {
      img: gallerySetting?.gallery_img_3,
      title: 'Image',
      author: 'author',
      cols: 3,
    },
    {
      img: gallerySetting?.gallery_img_4,
      title: 'Image',
      author: 'author',
      cols: 4,
    },
    {
      img: gallerySetting?.gallery_img_5,
      title: 'Image',
      author: 'author',
      cols: 2,
    },
    {
      img: gallerySetting?.gallery_img_6,
      title: 'Image',
      author: 'author',
      cols: 1,
    },
    {
      img: gallerySetting?.gallery_img_7,
      title: 'Image',
      author: 'author',
      cols: 3,
    },
    {
      img: gallerySetting?.gallery_img_8,
      title: 'Image',
      author: 'author',
      cols: 2,
    }
  ];

  useEffect(() => {
    if (imagePerCol === 2) {
      setImageSize(500);
    } else if (imagePerCol === 3) {
      setImageSize(380);
    } else if (imageSize === 4) {
      setImageSize(500);
    } else {
      setImageSize(350);
    }
  }, [imagePerCol]);

  const imagePreviewHandler = (idx) => {
    setImagePopup(true);
    setPhotoIndex(idx)
  }

  const getImageElements = () => tileData
    .filter((tile) => tile.image !== null)
    .map((tile, idx) => (

      <ImageListItem sx={styles.onHover} key={idx} cols={parseInt(`${isMobile ? imagePerCol * 2 : 2}`)}>
        <Image
          clickHandler={() => imagePreviewHandler(idx)}
          src={SETTING_FILE_PATH + tile.img}
          alt={tile?.title}
          circularProgressStyle={styles.circularProgress}
          loaderBoxStyle={styles.onImageBox}
        />


      </ImageListItem>

    ));

  return (
    <SlideAnimation in>
      <>
        <PageHeader pageName="Our Gallery" />

        <h3 className="p-3 text-center backgroundDark exo2">Our Gallery</h3>

        <Div sx={styles.root}>
          <ImageList
            sx={styles.gridList}
            cols={imagePerCol * 2}
          >
            {
              getImageElements()
            }
          </ImageList>

          {imagePopup && (
          <Lightbox
            mainSrc={SETTING_FILE_PATH + tileData[photoIndex]?.img}
            nextSrc={SETTING_FILE_PATH + tileData[(photoIndex + 1) % tileData.length]?.img}
            prevSrc={SETTING_FILE_PATH + tileData[(photoIndex + tileData.length - 1) % tileData.length]?.img}
            onCloseRequest={() => setImagePopup(false)}
            onMovePrevRequest={() => setPhotoIndex((photoIndex + tileData.length - 1) % tileData.length)}
            onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % tileData.length)}
          />
          )}
        </Div>

      </>
    </SlideAnimation>
  );
}

GalleryPage.propTypes = {
  imagePerCol: PropTypes.number,
}

const mapStateToProps = (state) => ({
  settings: state.settting,
  first_arrival: state.layout.first_arrival,
});

const mapDispatchToProps = (dispatch) => ({
  updateFirstArrival: (IsFirstArrive) => dispatch(updateFirstArrival(IsFirstArrive)),
});


export default connect(mapStateToProps, mapDispatchToProps)(GalleryPage);
