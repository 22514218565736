import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from './rootReducer';
import { APP_DEBUG } from '../util/constants';

const store = APP_DEBUG
  ? createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))
  : createStore(rootReducer, applyMiddleware(thunk))
;

export default store;
