import React from 'react';
import { deepPurple, deepOrange, red } from '@mui/material/colors';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Avatar, styled } from '@mui/material';


function UserAvatar({ ...otherProps }) {

  const classes = {
    root: {
      display: 'flex',
      '& > *': {
        margin: (theme) => theme.spacing(1),
      },
    },
    orange: {
      color: (theme) => theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: deepOrange[500],
    },
    purple: {
      color: (theme) => theme.palette.getContrastText(deepPurple[500]),
      backgroundColor: '#ef5c24',
    },
  };

  const Div = styled('div')``;

  if (!otherProps.isLoggedIn && _.isEmpty(otherProps.lastUser)) {
    return (
      <Div sx={classes.root}>
        <Avatar sx={classes.purple} />
      </Div>
    );
  }

  const getUserChars = () => {
    const userName = otherProps.user.name || otherProps.lastUser.name;

    let nameSplit = userName.split(' ');

    let chars = '';

    if (nameSplit.length === 1) {
      chars += nameSplit[0][0]; // first char
      chars += nameSplit[nameSplit.length - 1][1]; // last char
      return chars;
    }

    chars += nameSplit[0][0]; // first char of first element
    chars += nameSplit[nameSplit.length - 1][0]; // first char of last element

    return chars;
  };

  return (
    <Div sx={classes.root} title={otherProps.user.name || otherProps.lastUser.name}>
      <Avatar sx={classes.purple}>{getUserChars()}</Avatar>
    </Div>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.user.isLoggedIn,
  user: state.user.data,
  lastUser: state.user.lastUser
});

export default connect(mapStateToProps)(UserAvatar);
