import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
 Button, Grid, Typography, Box, TextField
} from '@mui/material';
import * as dateFns from 'date-fns';
import React, {
  useEffect, useMemo, useState
} from 'react';
import { connect } from 'react-redux';
import useNotify from '../../hooks/useNotify';
import useSetting from '../../hooks/useSetting';
import { cartDeliveryDateUpdate, cartDeliveryTimeUpdate, cartUpdateASAPTime } from '../../store/cart/cartActions';
import { clearUnavailableOrderWithTime, updateUnavailableOrderWithTime } from '../../store/opening/openingActions';
import {
  applyTimeZone, getCurrentClosingTime, getDateFormat, getTimeFormat, getTimeFormatFromTimeString, getValidOpeningStatus
} from '../../util/util';
import TimePicker from '../util/TimePicker/TimePicker';


function DateTime2({ ...otherProps }) {

  const [selectedDate, setSelectedDate] = useState(() => (otherProps.deliveryDate.length ? applyTimeZone(new Date(otherProps.deliveryDate)) : applyTimeZone(new Date())));

  const [timePickerOpen, setTimePickerOpen] = useState(false);
  const [notify] = useNotify();

  const timingSetting = useSetting([
    'full_time_order_start',
    'full_time_order_end',
    'delivery_order_delivery_time',
    'collection_order_delivery_time',
  ]);

  const [availableTodayDelivery, setAvailableTodayDelivery] = useState([]);

  const [orderTypeUnavailable, setOrderTypeUnavailable] = useState('');

  const [hideTimePicker, setHideTimePicker] = useState(false);

  const [bufferMin, setBufferMin] = useState(() => (0));

  const [selectedHoursTrigger, setSelectedHoursTrigger] = useState(false);


  const selectedDaysHours = useMemo(() => {
    // sync updated time & date to store
    otherProps.setDeliveryDate(dateFns.format(selectedDate, getDateFormat()));

    const selectedDayName = dateFns.format(selectedDate, 'EEE').toLowerCase();

    // set selected days hours
    const selectedDay = otherProps.opening.find((itm) => itm.day === selectedDayName);



    const hours = selectedDay ? selectedDay.hours : [{ from: '00:00:00', to: '23:59:59' }];
    setSelectedHoursTrigger(true)
    return hours;

  }, [selectedDate, otherProps.opening]);

  const [selectedTime, setSelectedTime] = useState(() => {
    const date = otherProps.deliveryDate.length ? applyTimeZone(new Date(otherProps.deliveryDate)) : null;
    // if date time available then set that
    if (otherProps.deliveryTime.length) {
      return new Date(`${date?.toDateString()} ${otherProps.deliveryTime}`);
    }

    return false;
  });

  useEffect(() => {

    otherProps.clearUnavailableOrderWithTime();
    otherProps.setDeliveryIsASAPTime(false);


    const time = otherProps.todayTiming.hours?.map((val) => {

      const [toHour, toMinute] = val.to.split(':');

      const [fromHour, fromMinute] = val.to.split(':');

      const toTime = dateFns.setHours(dateFns.setMinutes(applyTimeZone(new Date()), toMinute), toHour);

      const fromTime = dateFns.setHours(dateFns.setMinutes(applyTimeZone(new Date()), fromMinute), fromHour);

      const currentDate = applyTimeZone(new Date());

      if (currentDate >= fromTime || currentDate <= toTime) {

        const isDelivery = val.enabled_delivery;
        const isCollection = val.enabled_collection;

        if (!isDelivery && otherProps.orderType === 'Delivery') {
          setOrderTypeUnavailable('Delivery');
        } else if (!isCollection && otherProps.orderType === 'Collection') {
          setOrderTypeUnavailable('Collection');
        }
      }

    });

    if (otherProps.orderType === 'Delivery') {
      const availableDelivery = otherProps.todayTiming.hours?.filter((val) => val.enabled_delivery === true);
      setAvailableTodayDelivery(availableDelivery);
      setBufferMin(timingSetting.delivery_order_delivery_time)
    } else {
      const availableCollection = otherProps.todayTiming.hours.filter((val) => val.enabled_collection === true);
      setAvailableTodayDelivery(availableCollection);
      setBufferMin(timingSetting.collection_order_delivery_time)
    }


  }, [otherProps.orderType])

  useEffect(() => {
    if (selectedTime === false) return;

    otherProps.updateUnavailableOrderWithTime('');

    otherProps.todayTiming.hours.map((val) => {


      const [toHour, toMinute] = val.to.split(':');

      const [fromHour, fromMinute] = val.from.split(':');

      const toTime = dateFns.setHours(dateFns.setMinutes(applyTimeZone(new Date()), toMinute), toHour);

      const fromTime = dateFns.setHours(dateFns.setMinutes(applyTimeZone(new Date()), fromMinute), fromHour);

      if (selectedTime >= fromTime && selectedTime <= toTime) {


        const isDelivery = val.enabled_delivery;
        const isCollection = val.enabled_collection;

        if (!isDelivery && otherProps.orderType === 'Delivery') {
          notify.warning('Delivery is not available at this time.');
          otherProps.updateUnavailableOrderWithTime(otherProps.orderType);

        } else if (!isCollection && otherProps.orderType === 'Collection') {
          notify.warning('Collection is not available at this time.');
          otherProps.updateUnavailableOrderWithTime(otherProps.orderType);
        }
      }

    });
    otherProps.setDeliveryTime(dateFns.format(selectedTime, getTimeFormat()));
  }, [selectedTime]);

  const openingDays = useMemo(() => otherProps.opening.map((itm) => itm.day), [otherProps.opening]);

  const dayDisableHandler = (day) => {

    // enable all days if opening days not set
    if (openingDays.length === 0) {
      return false;
    }

    const dayName = dateFns.format(day, 'EEE').toLowerCase();

    // only enable days that have timing set
    return !openingDays.includes(dayName);
  };

  const getSelectedTime = () => {
    if (selectedTime === false) return '';
    let time = dateFns.format(selectedTime, getTimeFormat())
    return time;
  };


  const handleSelectTimeClick = () => {
    otherProps.setDeliveryIsASAPTime(false);
    setTimePickerOpen(true);
  }

  const isToday = () => {
    const currentTime = applyTimeZone(new Date());
    const todayDate = `${currentTime.getFullYear()}-${currentTime.getMonth() + 1}-${currentTime.getDate()}`;
    const selectedDateValue = `${selectedDate.getFullYear()}-${selectedDate.getMonth() + 1}-${selectedDate.getDate()}`;
    return todayDate === selectedDateValue;
  }

  const today = isToday();

  const handleAsapClick = () => {
    otherProps.setDeliveryIsASAPTime(true);
  }



  const [hideTimePickerButton, setHideTimePickerButton] = useState(false);

  const isRestaurantOpen = useMemo(() => getValidOpeningStatus(otherProps.todayOpening.hours), [otherProps.todayOpening.hours]);

  useEffect(() => {
    if (otherProps.deliveryTime.length) return;

    if (orderTypeUnavailable === '') {
      otherProps.setDeliveryIsASAPTime(isRestaurantOpen);

    } else {
      otherProps.setDeliveryIsASAPTime(false);
    }
  }, [isRestaurantOpen, orderTypeUnavailable]);

  useEffect(() => {

    if (!otherProps.isClosed && !selectedTime) {
      const [fromHour, fromMinute] = selectedDaysHours[0].from.split(':');

      const startTime = dateFns.setHours(dateFns.setMinutes(applyTimeZone(new Date()), fromMinute), fromHour);
      const currentTimeWithBuffer = new Date(startTime.getTime() + parseInt(bufferMin * 60000));
      setSelectedTime(currentTimeWithBuffer)
    }

    if (otherProps.isClosed && today) {
      // const [fromHour, fromMinute] = selectedDaysHours[0].from.split(':');
      // const startTime = dateFns.setHours(dateFns.setMinutes(applyTimeZone(new Date()), fromMinute), fromHour);
      // const currentTimeWithBuffer = new Date(startTime.getTime() + parseInt(bufferMin * 60000));
      // setSelectedTime(currentTimeWithBuffer)
      setHideTimePicker(true);
      // notify.warning('Restaurant has closed now you can order for')
    }

    if (otherProps.isClosed && !today) {
      setHideTimePicker(false);
    }

    if (!otherProps.isClosed && !today) {
      otherProps.setDeliveryIsASAPTime(false);

    }

  }, [otherProps.isClosed, today]);

  useEffect(() => {

    if (otherProps.isClosed) {
      const today = new Date();
      const tomorrow = new Date();
      let i;
    for (i = 1; i <= 7; i += 1) {
      tomorrow.setDate(today.getDate() + i)
      const dayName = dateFns.format(tomorrow, 'EEE');
      if (openingDays.includes(dayName.toLowerCase())) {
      otherProps.setDeliveryDate(tomorrow);
      setSelectedDate(applyTimeZone(new Date(tomorrow)));
      // return dateFns.format(tomorrow, 'EEEE');
      break;
      }
    }

   }


}, [otherProps.isClosed]);

  useEffect(() => {

    const clossingTimeArray = getCurrentClosingTime(selectedDaysHours);

    const closingTime = clossingTimeArray.filter((time) => time);

    const currentTime = applyTimeZone(new Date());

    const closingBuffer = parseInt(bufferMin);

    const currentTimeWithBuffer = new Date(currentTime.getTime() + parseInt(closingBuffer * 60000));

    if (today) {
      if (currentTimeWithBuffer?.getTime() >= closingTime[0]?.getTime()) {
        setHideTimePickerButton(true);
        otherProps.setDeliveryIsASAPTime(true);
      } else {
        setHideTimePickerButton(false);
      }
    } else {
      setHideTimePickerButton(false);
    }
  }, [today, bufferMin]);



  useEffect(() => {

    if (otherProps.isAsapTime) {
      setHideTimePicker(true);
    }

    if (!otherProps.isAsapTime && !otherProps.isClosed) {
      setHideTimePicker(false);
    }

  }, [otherProps.isAsapTime, otherProps.isClosed]);

  return (
    <Box container>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid item xs={12} md={6}>

          <Box component="form" noValidate>

            {/* <DatePicker
              label="Select Date"
              value={selectedDate}
              onChange={setSelectedDate}
              renderInput={(params) => <TextField {...params} />}
              showTodayButton
              name="date"
              id="date"
              minDate={new Date()}
              shouldDisableDate={dayDisableHandler}
            /> */}

            <Box>
              {
             ((isRestaurantOpen && orderTypeUnavailable === '') && today) && (
             <Box display="flex" alignItems="center" mt={3} mb={1}>
               <Button
                 variant={`${otherProps.cart.delivery.isAsapTime ? 'contained' : 'outlined'}`}
                 startIcon={otherProps.cart.delivery.isAsapTime ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
                 onClick={handleAsapClick}
                 color="primary"
                 sx={{ marginRight: '5px' }}
               >
                 ASAP
               </Button>
               {
                    !hideTimePickerButton
                    && (
                      <>
                        <Box sx={{ marginRight: '5px' }}>OR</Box>
                        <Button
                          variant={`${otherProps.cart.delivery.isAsapTime ? 'outlined' : 'contained'}`}
                          startIcon={otherProps.cart.delivery.isAsapTime ? <RadioButtonUncheckedIcon /> : <RadioButtonCheckedIcon />}
                          onClick={handleSelectTimeClick}
                          color="primary"
                        >
                          Select Time
                        </Button>
                      </>
                    )
                  }

             </Box>
              )
            }

              {
              orderTypeUnavailable !== '' && (
              <Typography
                variant="body1"
                style={{
                  marginLeft: 5,
                  marginTop: 10,
                }}
                color="primary"
              >
                {`${orderTypeUnavailable} order available in the following times:`}

              </Typography>
            )
            }

              {
              orderTypeUnavailable !== ''
              && availableTodayDelivery.map((val) => (
                <Typography
                  kay={`hr${val.id}`}
                  variant="body1"
                  style={{
                      marginLeft: 15
                    }}
                  color="secondary"
                >
                  {getTimeFormatFromTimeString(val.from, '', false)}
                  {' '}
                  to
                  {getTimeFormatFromTimeString(val.to, '', false)}
                </Typography>
              ))
            }


              <TextField
                hidden={hideTimePicker}
                type="text"
                variant="outlined"
                margin="normal"
                required
                name="time"
                label="Select Time"
                id="time"
                autoComplete="off"
                value={getSelectedTime()}
                onFocus={() => setTimePickerOpen(true)}
              />
            </Box>
          </Box>

        </Grid>

        <TimePicker
          open={timePickerOpen}
          date={selectedDate}
          hours={selectedDaysHours}
          defaultValue={selectedTime}
          closeHandler={() => setTimePickerOpen(false)}
          changeHandler={(time) => setSelectedTime(time)}
          minuteStep={5}
        />


      </LocalizationProvider>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  deliveryDate: state.cart.delivery.date,
  deliveryTime: state.cart.delivery.time,
  opening: state.opening.data,
  todayTiming: state.opening.todaysTiming,
  cart: state.cart,
  isClosed: state.opening.isClosed,
  isOffDay: state.opening.isOffDay,
  isAsapTime: state.cart.delivery.isAsapTime,
  isOpen: state.opening.isOpen,
  todayOpening: state.opening.todaysTiming,
  orderType: state.cart.order.type,
});

const mapDispatchToProps = (dispatch) => ({
  setDeliveryDate: (date) => dispatch(cartDeliveryDateUpdate(date)),
  setDeliveryTime: (time) => dispatch(cartDeliveryTimeUpdate(time)),
  setDeliveryIsASAPTime: (isASAP) => dispatch(cartUpdateASAPTime(isASAP)),
  updateUnavailableOrderWithTime: (type) => dispatch(updateUnavailableOrderWithTime(type)),
  clearUnavailableOrderWithTime: () => dispatch(clearUnavailableOrderWithTime()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DateTime2);
