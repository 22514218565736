import React, { useEffect, useState } from 'react';

import {
 Box, Typography, Card, CardHeader, CardContent, Popover, styled
} from '@mui/material';
import { CURRENCY_SYMBOL } from '../../util/constants';
import { connect } from 'react-redux';
import InfoIcon from '@mui/icons-material/Info';
import routes from '../../util/routes';
import { useNavigate } from 'react-router-dom';
import ModalComp from '../util/Modal/ModalComp';


function CartPreview({ ...otherProps }) {

  const styles = {
    root: {
      maxWidth: 500,
      paddingTop: 5,
    },
    header: {
      textAlign: 'center',
      fontSize: 25,
    },

    itemColor: {
      color: 'black',
    },

    itemTextSize: {
      fontSize: 18,
    },

    addonColor: {
      color: 'green',
    },

    addonTextSize: {
      fontSize: 14,
    },

    priceColor: {
      color: 'black',
    },

    priceTextSize: {
      fontSize: 16,
    },

    cartTitleSize: {
      fontSize: 18,
    },

    cartAdjustTotalIcon: {
      fontSize: 16,
    },

    cartTitleColor: {
      color: 'gray',
    },

    display: {
      display: 'flex',
      justifyContent: 'space-between',
    },

    total: {
      marginTop: 3,
    },

    itemMargin: {
      marginBottom: 1,
    },
    marginBottom20: {
      marginBottom: 2,
    },

    spaceBetween: {
      display: 'space-between',
    },

    itemRight: {
      textAlign: 'right',
    },
  };
  //declare all props values


  let items = otherProps.cartItem;
  let OrderType = otherProps.orderType.type;
  let discount = otherProps.orderDiscount.value.toFixed(2);
  let coupon = otherProps.orderCoupon.value.toFixed(2);
  let deliveryCost = otherProps.orderDelivery.charge.toFixed(2);
  let totalItemCost = otherProps.totalItemCost.toFixed(2);
  let totalAddonsCost = otherProps.totalAddonsCost.toFixed(2);
  let pointAmount = otherProps.pointAmount.toFixed(2);
  let deliveryTime = otherProps.orderDelivery.time;
  let deliveryDate = otherProps.orderDelivery.date;
  let subTotal = parseFloat(totalItemCost) + parseFloat(totalAddonsCost) + parseFloat(otherProps.cart.setMenusTotal);
  let totalCost = (parseFloat(totalItemCost)
      + parseFloat(otherProps.cart.setMenusTotal)
      + parseFloat(totalAddonsCost)
      + parseFloat(deliveryCost))
      - (parseFloat(coupon)
      + parseFloat(discount) + parseFloat(pointAmount) - parseFloat(otherProps.adjustPointMoney));


  const [couponType, setCouponType] = useState('');

  const [popOpen, setPopOpen] = useState(null);

  const popupClick = (event) => {
    setPopOpen(event.currentTarget);
  };

  const popupClose = () => {
    setPopOpen(null);
  };

  const open = Boolean(popOpen);
  const id = open ? 'simple-popover' : undefined;

  const navigate = useNavigate();

  const Div = styled('div')``;
  const P = styled('p')``;

  useEffect(() => {

    if (otherProps.couponObject?.type === '') {
      setCouponType('');
      return;
    }

    if (otherProps.couponObject.type === 'percent') {
      setCouponType(`(${otherProps.couponObject?.amount}%)`);
    } else {
      setCouponType(`(${CURRENCY_SYMBOL}${otherProps.couponObject?.amount})`);
    }

  }, [coupon]);

  const [discountType, setDiscountType] = useState('');

  const goPoints = () => {
    navigate(routes.points);
  }

  const [isAddon, setIsAddon] = useState(false);

  useEffect(() => {

    otherProps.cartItem.map((item) => {
      if (item.selected_addon.length) {
        setIsAddon(true);
      }
      return false;
    })


  }, [otherProps.cartItem]);

  useEffect(() => {

    if (otherProps.discountObject?.type === '') {
      setDiscountType('');
      return;
    }

    if (otherProps.discountObject.type === 'percent') {
      setDiscountType(`(${otherProps.discountObject?.amount}%)`);
    } else {
      setDiscountType(`(${CURRENCY_SYMBOL}${otherProps.discountObject?.amount})`);
    }

  }, [discount]);

  let itemList = items.map((item, index) => (
    <Div sx={styles.itemMargin} key={`${item.id}_${index}`}>
      <Div sx={styles.display}>
        <P sx={{ ...styles.itemColor, ...styles.itemTextSize }}>{`${item.qty} ${item.name}`}</P>
        <P sx={{ ...styles.priceColor, ...styles.priceTextSize }}>{`${CURRENCY_SYMBOL}${(item.price * item.qty).toFixed(2)}`}</P>
      </Div>
      <Div sx={{ ...styles.addonTextSize, ...styles.addonColor }}>


        {
            item?.selected_addon.map((addon) => (
              <Box
                key={addon.id}
                display="flex"
                justifyContent="space-between"
              >
                <Box>{addon.name}</Box>
                <Box>
                  {CURRENCY_SYMBOL}
                  {(addon.price * item.qty).toFixed(2)}
                </Box>
              </Box>
              ))

          }
      </Div>
    </Div>
    ))

  let setMenus = otherProps.cart?.set_menus?.map((item, index) => (
    <Div sx={styles.itemMargin} key={`${item.id}_${index}`}>
      <Div sx={styles.display}>
        <P sx={{ ...styles.itemColor, ...styles.itemTextSize }}>{`${item.qty} ${item.name}`}</P>
        <P sx={{ ...styles.priceColor, ...styles.priceTextSize }}>
          {
          `${CURRENCY_SYMBOL}${(item.total_cost * item.qty).toFixed(2)}`
          }
        </P>
      </Div>


      {
          item?.Additional_items?.length !== 0
          && (
            <Div sx={{ ...styles.addonTextSize, ...styles.addonColor }}>
              <Box
                display="flex"
                justifyContent="space-between"
              >
                <Box>With Additions *</Box>
              </Box>
            </Div>
          )
      }

    </Div>
  ))


  if (itemList.length || otherProps.cart?.set_menus.length) {
    return (
      <Card sx={styles.root}>
        <CardHeader
          sx={styles.header}
          title="Cart Summary"
        />

        <CardContent>
          <Div>

            <Div sx={styles.display}>
              <P sx={{ ...styles.cartTitleSize, ...styles.cartTitleColor }}>{'Order Type: '}</P>
              <P sx={{ ...styles.cartTitleSize, ...styles.cartTitleColor }}>{`${OrderType}`}</P>
            </Div>

            <Div sx={{ ...styles.display, ...styles.marginBottom20 }}>
              <P sx={{ ...styles.cartTitleSize, ...styles.cartTitleColor }}>{`Time: ${otherProps.asapTime ? 'ASAP' : deliveryTime}`}</P>
              <P sx={{ ...styles.cartTitleSize, ...styles.cartTitleColor }}>{`Date: ${deliveryDate}`}</P>
            </Div>


            {itemList}

            {setMenus}

            <Div sx={{ ...styles.display, ...styles.total }}>
              <P sx={{ ...styles.cartTitleSize, ...styles.cartTitleColor }}>Item Total: </P>
              <P sx={{ ...styles.cartTitleSize, ...styles.cartTitleColor }}>{`${CURRENCY_SYMBOL}${otherProps.totalItemCost.toFixed(2)}`}</P>
            </Div>

            {
              isAddon
              && (
              <Div sx={styles.display}>
                <P sx={{ ...styles.cartTitleSize, ...styles.cartTitleColor }}>Addons Total: </P>
                <P sx={{ ...styles.cartTitleSize, ...styles.cartTitleColor }}>{`${CURRENCY_SYMBOL}${otherProps.totalAddonsCost.toFixed(2)}`}</P>
              </Div>
              )

            }

            <Div sx={{ ...styles.display, ...styles.total }}>
              <P sx={{ ...styles.cartTitleSize, ...styles.cartTitleColor }}>Subtotal: </P>
              <P sx={{ ...styles.cartTitleSize, ...styles.cartTitleColor }}>{`${CURRENCY_SYMBOL}${subTotal.toFixed(2)}`}</P>
            </Div>

            {
              otherProps.discountObject.type !== '' && (
              <Div sx={styles.display}>
                <P sx={{ ...styles.cartTitleSize, ...styles.cartTitleColor }}>
                  Discount
                  {discountType}
                  :
                  {' '}
                </P>
                <P sx={{ ...styles.cartTitleSize, ...styles.cartTitleColor }}>{`- ${CURRENCY_SYMBOL}${discount}`}</P>
              </Div>
            )
            }

            {
              otherProps.couponObject.type !== '' && (
              <Div sx={styles.display}>
                <P sx={{ ...styles.cartTitleSize, ...styles.cartTitleColor }}>
                  Coupon
                  {couponType}
                  :
                  {' '}
                </P>
                <P sx={{ ...styles.cartTitleSize, ...styles.cartTitleColor }}>{`- ${CURRENCY_SYMBOL}${coupon}`}</P>
              </Div>
            )
            }

            {
              pointAmount > 0
              && (
              <Div sx={styles.display}>
                <P sx={{ ...styles.cartTitleSize, ...styles.cartTitleColor }}>
                  Points(
                  {otherProps.points}
                  ):
                  {' '}
                </P>
                <P sx={{ ...styles.cartTitleSize, ...styles.cartTitleColor }}>{`- ${CURRENCY_SYMBOL}${pointAmount}`}</P>
              </Div>
              )
            }


            <Div sx={styles.display}>
              <P sx={{ ...styles.cartTitleSize, ...styles.cartTitleColor }}>
                {OrderType}
                {' '}
                Charge:
                {' '}
              </P>
              <P sx={{ ...styles.cartTitleSize, ...styles.cartTitleColor }}>{`+ ${CURRENCY_SYMBOL}${deliveryCost}`}</P>
            </Div>

            {
              otherProps.adjustPointMoney !== 0
              && (
              <Div sx={styles.display}>
                <P sx={{ ...styles.cartTitleSize, ...styles.cartTitleColor }}>
                  Adjust Total:
                  <InfoIcon fontSize="medium" color="primary" className="pop-over" onClick={popupClick} />
                </P>
                {/*<Popover*/}
                {/*  id={id}*/}
                {/*  open={open}*/}
                {/*  anchorEl={popOpen}*/}
                {/*  onClose={popupClose}*/}
                {/*  anchorOrigin={{*/}
                {/*    vertical: 'bottom',*/}
                {/*    horizontal: 'center',*/}
                {/*  }}*/}
                {/*  transformOrigin={{*/}
                {/*    vertical: 'top',*/}
                {/*    horizontal: 'center',*/}
                {/*  }}*/}
                {/*>*/}
                {/*  <Typography className="pop-over-text">*/}
                {/*    As points always a number so here we*/}
                {/*    adjusted*/}
                {/*    {CURRENCY_SYMBOL}*/}
                {/*    {otherProps.adjustPointMoney}*/}
                {/*    {' '}*/}
                {/*    points for this order. To know more*/}
                {/*    checkout*/}
                {/*    {' '}*/}
                {/*    <a href="#" onClick={() => goPoints()}>how it works</a>*/}
                {/*  </Typography>*/}
                {/*</Popover>*/}

                <ModalComp
                  open={popOpen}
                  hideFooter
                  hideHeader
                  closeHandler={popupClose}
                >
                  <Typography className="pop-over-text">
                    As points always a number so here we
                    adjusted
                    {' '}
                    {CURRENCY_SYMBOL}
                    {otherProps.adjustPointMoney}
                    {' '}
                    points for this order. To know more
                    checkout
                    {' '}
                    <a href="#" onClick={() => goPoints()}>how it works</a>
                  </Typography>
                </ModalComp>

                <P sx={{ ...styles.cartTitleSize, ...styles.cartTitleColor }}>
                  {`+${CURRENCY_SYMBOL}${otherProps.adjustPointMoney}`}
                  {' '}
                </P>
              </Div>
              )
            }

            <Div sx={styles.display}>
              <P sx={{ ...styles.cartTitleSize, ...styles.cartTitleColor }}>Total Amount: </P>
              <P sx={{ ...styles.cartTitleSize, ...styles.cartTitleColor }}>{`${CURRENCY_SYMBOL}${totalCost.toFixed(2)}`}</P>
            </Div>

          </Div>
        </CardContent>
      </Card>
    );
  }
    return (
      <Card sx={styles.root}>
        <CardHeader
          sx={styles.header}
          title="Cart Summary"
        />

        <CardContent>
          <div>
            <h3>No Item in Cart</h3>
          </div>
        </CardContent>
      </Card>
    );


}

const mapStateToProps = (state) => ({

  cartItem: state.cart.items,
  cartAddons: state.cart.addons,
  totalItemCost: state.cart.itemsTotal,
  totalAddonsCost: state.cart.addonsTotal,
  orderType: state.cart.order,
  orderDiscount: state.cart.discount,
  orderCoupon: state.cart.coupon,
  orderDelivery: state.cart.delivery,
  couponObject: state.cart.coupon,
  discountObject: state.cart.discount,
  asapTime: state.cart.delivery.isAsapTime,
  pointAmount: state.cart.pointsToMoney,
  points: state.cart.orderPoint,
  cart: state.cart,
  adjustPointMoney: state.cart?.adjustPointMoney,

});


export default connect(mapStateToProps)(CartPreview);
