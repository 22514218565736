import '@animxyz/core';
import { XyzTransitionGroup } from '@animxyz/react';
import PropTypes from 'prop-types';

const StackedAnimation = (
  {
    initialAnimation = true,
    children,
    duration,
    tag = 'div',
    stackDuration,
    stackReverseOrder = false,
    additionalAnimateClasses,
    directionIn = 'left',
    directionOut = 'right',
    directionScale
  },
) => {

  const animationDuration = () => {
    let durationName = 'duration';

    if (duration === undefined) return durationName;

    durationName += `-${duration}`;
    return durationName;
  };

  const animationDirection = () => {

    const inDirection = `in-${directionIn}`;
    const outDirection = `out-${directionOut === undefined ? directionIn : directionOut}`;

    let direction = [inDirection, outDirection];
    if (directionScale === undefined) return direction;

    direction[0] = `${inDirection}-${directionScale}`;
    direction[1] = `${outDirection}-${directionScale}`;
    return direction;
  };

  const animateClasses = () => {

    const stack = stackReverseOrder ? 'stagger-rev' : 'stagger';

    const stackWithTiming = stackDuration === undefined
      ? stack
      : `${stack}-${stackDuration}`
    ;

    return [
      'fade',
      stackWithTiming,
      animationDuration(),
      ...animationDirection(),
      additionalAnimateClasses
    ];
  };

  return (
    <XyzTransitionGroup
      xyz={`${animateClasses().join(' ')}`}
      tag={tag}
      appear={initialAnimation}
    >
      {children}
    </XyzTransitionGroup>
  );
};

StackedAnimation.defaultProps = {
  directionIn: 'left',
  directionOut: 'right',
  tag: 'div',
  stackReverseOrder: false,
  duration: '5'
}


StackedAnimation.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
  initialAnimation: PropTypes.bool,
  tag: PropTypes.string,
  stackReverseOrder: PropTypes.bool,
  additionalAnimateClasses: PropTypes.string,
  stackDuration(props, propName) {
    const allowed = [0, 0.5, 1, 1.5, 2, 2.5, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25, 30];
    const value = props[propName];
    if (allowed.includes(parseFloat(value))) return null;
    return new Error(`Unsupported stack duration '${value}', allowed: ${allowed}`);
  },
  directionIn(props, propName) {
    const allowed = ['right', 'left', 'down', 'up', 'front', 'back'];
    const value = props[propName];
    if (allowed.includes(value)) return null;
    return new Error(`Invalid direction in '${value}', allowed: ${allowed}`);
  },
  directionOut(props, propName) {
    const allowed = ['right', 'left', 'down', 'up', 'front', 'back'];
    const value = props[propName];
    if (allowed.includes(value)) return null;
    return new Error(`Invalid direction out '${value}', allowed: ${allowed}`);
  },
  directionScale(props, propName) {
    const allowed = ['0', '1', '2', '3', '4', '5', '25%', '50%', '75%', '100%'];
    const value = props[propName];
    if (allowed.includes(value)) return null;
    return new Error(`Invalid direction Scale '${value}', allowed: ${allowed}`);
  },
  duration(props, propName) {
    const allowed = [0, 0.5, 1, 1.5, 2, 2.5, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25, 30];
    const value = props[propName];
    if (allowed.includes(parseFloat(value))) return null;

    return new Error(`Unsupported animation duration '${value}', allowed: ${allowed}`);
  }
}

export default StackedAnimation;
