import {
  ADDED_MENUS,
  IS_SET_MENU_SELECTED,
  SELECTED_MENU,
} from './menuTypes';

export const MenuAdded = (categories, items, set_menus) => (dispatch) => {
    dispatch({
      type: ADDED_MENUS,
      payload: { categories, items, set_menus }
    });
  };

export const SelectedMenuAdded = (selectedCategory, selectedItems) => (dispatch) => {
    dispatch({
      type: SELECTED_MENU,
      payload: { selectedCategory, selectedItems }
    });
  };

export const HandleSetMenuSelection = (isSelected) => (dispatch) => {
  dispatch({
    type: IS_SET_MENU_SELECTED,
    payload: isSelected,
  });
};


