import React, { useEffect, useState } from 'react';
import {
  FormControlLabel, Grid, Switch, Typography
} from '@mui/material';
import {
  cartDeliveryDateUpdate,
  cartDeliveryTimeUpdate,
  cartPaymentMethodUpdate,
  cartPaymentStatusUpdate,
  updateAdjustPointMoney,
  updateCartOrderPoints,
  updateCartPointsToMoney
} from '../../store/cart/cartActions';
import { connect } from 'react-redux';
import useNotify from '../../hooks/useNotify';
import { updateUserPoints } from '../../store/user/userActions';

function AddPoint({ orderPoints, ...otherProps }) {

  const [notify] = useNotify();

  const [isSwitchOn, setSwitchOn] = useState(false);

  const [pointText, setPointText] = useState('Not using points');


  useEffect(() => {

    if (!isSwitchOn) {

      otherProps.updateCartOrderPoints(0);

      otherProps.updateCartPointsToMoney(0);

      otherProps.cartPaymentMethodUpdate('');

      otherProps.cartPaymentStatusUpdate(false);

      otherProps.updateAdjustPointMoney(0);

      setPointText('Not using points');
      return;
    }

    let totalAmount = otherProps.totalItemCost + otherProps.totalAddonsCost;
    totalAmount += otherProps.orderDelivery.charge;
    totalAmount -= otherProps.orderDiscount.value;
    totalAmount -= otherProps.orderCoupon.value;

    if (totalAmount <= parseFloat(orderPoints.to_money)) {

      otherProps.cartPaymentMethodUpdate('points');

      otherProps.cartPaymentStatusUpdate(true);

      const adjustAmount = parseFloat(orderPoints.to_money - totalAmount);

      otherProps.updateAdjustPointMoney(adjustAmount.toFixed(2));
    }

    otherProps.updateCartOrderPoints(parseInt(orderPoints.points));

    otherProps.updateCartPointsToMoney(parseFloat(orderPoints.to_money));

    setPointText('Using points');

  }, [isSwitchOn]);

  const handlePoint = () => {

    if (orderPoints.total_points < 100) {
      notify.info('You need 100 points to use it.');
      return;
    }
    setSwitchOn((val) => !val);
  }


  return (
    <Grid container>
      <Grid item xs={12} md={6}>

        <form noValidate>

          {
              otherProps.userPoint === 0
              && (
                <Typography color="primary" margin={1} style={{ fontSize: 20 }}>
                  You have no points available
                </Typography>
              )

            }

          {
              otherProps.userPoint !== 0
              && (
                <Typography color="primary" margin={1} style={{ fontSize: 20 }}>
                  You have
                  {' '}
                  {otherProps.userPoint}
                  {' '}
                  points available
                </Typography>
              )
            }


          <FormControlLabel
            className="mt-1 mb-1"
            label={pointText}
            control={(
              <Switch
                color="primary"
                checked={isSwitchOn}
                onChange={() => handlePoint()}
              />
              )}
          />

        </form>

      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  todayTiming: state.opening.todaysTiming,
  cart: state.cart,
  totalItemCost: state.cart.itemsTotal,
  totalAddonsCost: state.cart.addonsTotal,
  orderDiscount: state.cart.discount,
  orderCoupon: state.cart.coupon,
  orderDelivery: state.cart.delivery,
  userPoint: state.user.data.point?.points,
});

const mapDispatchToProps = (dispatch) => ({
  setDeliveryDate: (date) => dispatch(cartDeliveryDateUpdate(date)),
  setDeliveryTime: (time) => dispatch(cartDeliveryTimeUpdate(time)),
  updateCartOrderPoints: (points) => dispatch(updateCartOrderPoints(points)),
  updateCartPointsToMoney: (amount) => dispatch(updateCartPointsToMoney(amount)),
  updateUserPoints: (points) => dispatch(updateUserPoints(points)),
  cartPaymentMethodUpdate: (method) => dispatch(cartPaymentMethodUpdate(method)),
  cartPaymentStatusUpdate: (isPaid) => dispatch(cartPaymentStatusUpdate(isPaid)),
  updateAdjustPointMoney: (amount) => dispatch(updateAdjustPointMoney(amount)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddPoint);
