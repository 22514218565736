// define custom
const validationMessages = {
  required: (field) => `${field} is required`,
  min: (field, validation, args) => `${field} cannot be less than ${args[0]}`,
  max: (field, validation, args) => `${field} cannot be more than ${args[0]}`,
  confirmed: (field) => `${field} confirmation does not matched`,
  email: (field) => `${field} must be a valid email address`,
  number: (field) => `${field} contains only numbers`,
  alpha: (field) => `${field} should contains only alphabetical character`,

  // custom rules
  matchWithField: (field, validation, args) => `${field} must match with ${args[0]}`,
  containsNumber: (field, validation, args) => `${field} must contains ${args[0]} number`,
  containOnlyNumber: (field, validation, args) => `${field} must contains Only numbers`,
  containOnlyAlphabet: (field, validation, args) => `${field} should contains only alphabetical character`,
  persons: (field, validation, args) => `${field} should between 1 to 500`,

};

export default validationMessages;
