import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import {
 cartItemAdd, cartItemAddonsUpdate, cartItemRemove, deleteCartItem
} from '../../store/cart/cartActions';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import useNotify from '../../hooks/useNotify';
import '../../assets/css/custom.scss';


import { Menu, MenuItem, Box } from '@mui/material';


const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.grey['300'],
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.black,
      },
    },
  },
}))(MenuItem);

function CartItemMenu2({
 item, index, qty, ...otherProps
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [notify] = useNotify();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const addItemHandler = () => {
    otherProps.cartAddItem(item, item?.selected_addon);
    notify.success('Item Quantity Increased');
  }


  const removeItemHandler = () => {

    const itemQtyInCart = otherProps.cartItems.find((itm) => itm.id === item.id).qty;

    // remove item addons if item gets completely removed from cart
    if (itemQtyInCart < 1) {
      otherProps.cartUpdateItemAddon(item, item?.selected_addon);
    }

    otherProps.cartRemoveItem(item, item?.selected_addon);
    notify.warning('Item Quantity Decreased');
  }

  const deleteItemFromCart = () => {

    otherProps.deleteCartItem(item, item?.selected_addon);
    notify.error('Item Deleted');


  }

  return (
    <Box>

      <Box display="flex">

        <Box mr="8px" fontWeight="900" fontSize="25px" className="hover-pointer">
          <DeleteForeverIcon color="secondary" onClick={deleteItemFromCart} />
        </Box>

        <Box display="flex" border={1} justifyContent="center" alignItems="center" borderColor="primary.main" borderRadius="10px">
          <Box className="hover-pointer">
            <RemoveOutlinedIcon color="secondary" onClick={removeItemHandler} />

          </Box>
          <Box ml="8px" mr="8px" fontWeight="900" fontSize="20px">
            {qty}
          </Box>
          <Box className="hover-pointer">
            <AddOutlinedIcon color="secondary" onClick={addItemHandler} />
          </Box>
        </Box>

      </Box>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  cartItems: state.cart.items
});

const mapDispatchToProps = (dispatch) => ({
  cartAddItem: (item, addon) => dispatch(cartItemAdd(item, addon)),
  cartRemoveItem: (item, addon) => dispatch(cartItemRemove(item, addon)),
  cartUpdateItemAddon: (item, addons) => dispatch(cartItemAddonsUpdate(item, addons)),
  deleteCartItem: (item, addons) => dispatch(deleteCartItem(item, addons)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CartItemMenu2);
