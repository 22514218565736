/* eslint-disable default-param-last */
import * as dateFns from 'date-fns';
import _ from 'lodash';

import {
  SETTING_GET_OPENING_HOURS_REQUEST,
  SETTING_GET_OPENING_HOURS_FAIL,
  SETTING_GET_OPENING_HOURS_SUCCESS,
  SETTING_VALIDATE_OPENING_HOURS_STATE,
  RESTAURANT_CLOSED_STATE,
  UNAVAILABLE_ORDER_TYPE,
  UNAVAILABLE_ORDER_TYPE_WITH_SELECTED_TIME,
  CLEAR_UNAVAILABLE_ORDER_TYPE_WITH_SELECTED_TIME
} from './openingTypes';
import { getTodaysTimeFromTimeString } from '../../util/util';

const defaultState = {
  loading: false,
  isOffDay: false,
  isOpen: false,
  isClosed: false,
  unavailableOrderType: '',
  unavailableOrderTypeWithSelectedTime: '',
  todaysTiming: {},
  data: [],
  errorMsg: '',
  errors: {}
};

const reducer = (state = defaultState, { type, payload }) => {

  switch (type) {

    case SETTING_GET_OPENING_HOURS_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case SETTING_GET_OPENING_HOURS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload,
        errorMsg: '',
        errors: {},
        todaysTiming: getTodaysTiming(payload),
        isOffDay: isOffDay(payload),
        isOpen: isOpen(payload)
      }

    case SETTING_GET_OPENING_HOURS_FAIL:
      return {
        ...state,
        loading: false,
        data: [],
        error: payload?.message,
        errors: payload?.errors || {}
      }

    case SETTING_VALIDATE_OPENING_HOURS_STATE:
      return {
        ...state,
        isOpen: payload
      }
    case RESTAURANT_CLOSED_STATE:
      return {
        ...state,
        isClosed: payload
      }
    case UNAVAILABLE_ORDER_TYPE:
      return {
        ...state,
        unavailableOrderType: payload
      }
    case UNAVAILABLE_ORDER_TYPE_WITH_SELECTED_TIME:
      return {
        ...state,
        unavailableOrderTypeWithSelectedTime: payload
      }
    case CLEAR_UNAVAILABLE_ORDER_TYPE_WITH_SELECTED_TIME:
      return {
        ...state,
        unavailableOrderTypeWithSelectedTime: defaultState.unavailableOrderTypeWithSelectedTime,
      }

    default: return state
  }

};

function getTodaysTiming(days) {
  // get day name
  const today = dateFns.format(new Date(), 'E').toLowerCase();

  // if today's time exist
  const find = days.find((itm) => itm.day === today);

  return (find === undefined) ? {} : find;
}

function isOffDay(days) {

  const timing = getTodaysTiming(days);
  return _.isEmpty(timing);
}

function isOpen(days) {
  // get day name
  const todaysTiming = getTodaysTiming(days);

  if (_.isEmpty(todaysTiming)) {
    return false;
  }

  return validateOpenStateForCurrentTime(todaysTiming.hours);
}

function validateOpenStateForCurrentTime(timings) {

  const currentTimestamp = new Date().getTime();

  // convert timings to timestamps
  const validTimings = timings.filter((hour) => {
    const fromTimestamp = getTodaysTimeFromTimeString(hour.from, true);
    const toTimestamp = getTodaysTimeFromTimeString(hour.to, true);

    return ((currentTimestamp >= fromTimestamp) && (currentTimestamp <= toTimestamp));
  });

  return !!validTimings.length;
}

export default reducer;
