import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: '"Lora", serif',
    lineHeight: '70px',
    fontWeight: 500,
    fontSize: 'min(60px, 11vw)',
    color: '#fff',
    marginBottom: '15px',
    maxWidth: 'min(500px, 100%)',
  },
  part1: {
    fontFamily: "'Yeseva One', cursive",
    fontWeight: 700
  },
  part2: {
    fontWeight: 400,
    marginLeft: 10,
    marginRight: 10,
  },
  part3: {
    fontWeight: 700,
    fontFamily: '"Dancing Script", serif'
  }
}));

export default function CompanyName({ tag = 'span' }) {

  const classes = useStyles();

  return (
    <Typography
      component={tag}
      className={classes.root}
    >
      <span className={classes.part1}>CAM</span>
      <span className={classes.part2}>SPICE</span>
      <span className={classes.part3}>Express</span>
    </Typography>
  );

}

CompanyName.propTypes = {
  tag: PropTypes.string
};
