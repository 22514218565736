import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { connect } from 'react-redux';
import { HandleSetMenuSelection, SelectedMenuAdded } from '../../store/menu/menuActions';
import '../../assets/css/custom.scss';



function SideMenu({ selectHandler, ...otherProps }) {

  const [selected, setSelected] = useState('none');

  const [expanded, setExpanded] = useState(false);
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  const handleCategorySelect = (category) => {

    if (category[0] === '1' && otherProps.isSetMenuSelected) {
      otherProps.HandleSetMenuSelection(true);
    } else {
      otherProps.HandleSetMenuSelection(false);
      otherProps.SelectedMenuAdded(category, otherProps.items[category.name]);
    }

  };

  useEffect(() => {

    if (selected === 'none' && otherProps.selectedCategory) {
      // update category selection
      handleSelection(otherProps.selectedCategory);

      // open accordion if its a parent or child
      if (otherProps.selectedCategory.is_parent || otherProps.selectedCategory.parent_id) {
        const accordionId = (otherProps.selectedCategory.is_parent)
          ? otherProps.selectedCategory.id : otherProps.selectedCategory.parent_id;
        setExpanded(accordionId);
      }
    }
  }, [otherProps.selectedCategory]);



  const handleSelection = (category) => {
    setSelected(category.name);
    handleCategorySelect(category);
  }

  const handleSetMenu = () => {
    otherProps.HandleSetMenuSelection(true);
    otherProps.SelectedMenuAdded('1', otherProps.setMenus);
  }


  useEffect(() => {
    setSelected(otherProps.selectedCategory.name);
  }, [otherProps.selectedCategory]);

  const styles = {
    categoryMenuItemElement: {
      cursor: 'pointer',
    },
    selectedColor: {
      color: '#FFFFFF !important',
    },
    selectedBackgroundColor: {
      backgroundColor: (theme) => `${theme.palette.primary.main}!important`,
      color: '#FFFFFF !important',
    },
    menuGroup: {
      width: '100%',
    },
    menuGroupHeading: {
      backgroundColor: (theme) => theme.palette.secondary.dark,
      borderRadius: 5
    },
  };

  const renderCategoryItem = (category) => (
    <ListItem
      key={`${category.id}c`}
      selected={selected === category.name}
      onClick={() => handleSelection(category)}
      sx={{ ...styles.categoryMenuItemElement, ...(selected === category.name ? styles.selectedBackgroundColor : '') }}
    >
      <ListItemText
        primary={`${category.name}`}
        sx={{ ...(selected === category.name ? styles.selectedColor : '') }}
      />
    </ListItem>
);

  const renderCategoryWithChilds = (category) => {

    const flatCategories = [category, ...category.childs];

    return (
      <Accordion
        key={category.id}
        expanded={expanded === category.id}
        onChange={handleAccordionChange(category.id)}
        style={{ bottomBorder: '1px solid #141414' }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id={`header${category.id}`}
          className={styles.menuGroupHeading}
        >
          <Typography component="h2">
            {category.name}
          </Typography>

        </AccordionSummary>
        <AccordionDetails style={{ flexDirection: 'column' }}>
          {
            flatCategories.map((cat) => renderCategoryItem(cat))
          }
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderCategory = (category) => {

    if (category.is_parent) {
      return renderCategoryWithChilds(category);
    }

    return renderCategoryItem(category);
  };

  return (
    <>
      <Typography component="h2" color="primary" style={{ fontSize: 25, padding: 8 }}>
        Menu Categories
      </Typography>
      <List component="nav">
        {
      otherProps.storeCategories.map((category) => renderCategory(category))
    }


        {
        otherProps.setMenus.length !== 0
        && (
        <ListItem
          sx={{ ...styles.categoryMenuItemElement, ...(selected ? '' : styles.selectedBackgroundColor) }}
          onClick={handleSetMenu}
        >
          <ListItemText
            primary="Set Menu"
          />
        </ListItem>
        )
      }

      </List>
    </>
  );
}

const mapStateToProps = (state) => ({
  storeCategories: state.menu.categories,
  items: state.menu.items,
  setMenus: state.menu.set_menus,
  selectedCategory: state.menu.selectedCategory,
  isSetMenuSelected: state.menu.isSetMenuSelected,
});

const mapDispatchToProps = (dispatch) => ({
  SelectedMenuAdded: (selectedCategory, selectedItems) => dispatch(SelectedMenuAdded(selectedCategory, selectedItems)),
  HandleSetMenuSelection: (isSelected) => dispatch(HandleSetMenuSelection(isSelected)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
