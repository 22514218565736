import React from 'react';
import { Backdrop, CircularProgress, red } from '@mui/material';


export default function PageLoader({ show = false, loaderColor, backgroundColor }) {

  const styles = {
    backdrop: {
      zIndex: (theme) => theme.zIndex.drawer + 1,
      color: (props) => props?.color || '#fff !important',
      backgroundColor: (props) => props?.bgColor || ''
    },
    color: loaderColor,
    bgColor: backgroundColor,
  };

  return (
    <Backdrop sx={styles.backdrop} open={show}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
