import React, { useEffect } from 'react';
import PageHeader from '../../components/pageHeader/pageHeader';
import ThemeImage from '../../components/util/ThemeImage';
import About from './about';
import SlideAnimation from '../../components/util/Animations/SlideAnimation';


function AboutPage() {

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);


  return (
    <SlideAnimation in>
      <PageHeader pageName="About Us" />

      {/* features-4 */}
      <section className="w3l-services-6-main home-services">
        <div className="services-6 py-5">
          <div className="container py-md-3">

            <About showBtn={false} />

            {/* <div className="row features-w3pvt-main" id="features">
            <div className="col-lg-4 col-md-6 feature-gird">
              <div className="row features-hny-inner-gd">
                <div className="col-md-3 col-2 featured_grid_left">
                  <div className="icon_left_grid">
                    <span className="fa fa-bath" aria-hidden="true" />
                  </div>
                </div>
                <div className="col-md-9 col-10 featured_grid_right_info pl-lg-0 ">
                  <h4><a className="link-hny">Hygienic Food</a></h4>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 feature-gird">
              <div className="row features-hny-inner-gd">
                <div className="col-md-3 col-2 featured_grid_left">
                  <div className="icon_left_grid">
                    <span className="fa fa-lightbulb-o" aria-hidden="true" />
                  </div>
                </div>
                <div className="col-md-9 col-10 featured_grid_right_info pl-lg-0">
                  <h4><a className="link-hny">Great atmosphere</a></h4>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 feature-gird">
              <div className="row features-hny-inner-gd">
                <div className="col-md-3 col-2 featured_grid_left">
                  <div className="icon_left_grid">
                    <span className="fa fa-shower" aria-hidden="true" />
                  </div>
                </div>
                <div className="col-md-9 col-10 featured_grid_right_info pl-lg-0">
                  <h4><a className="link-hny">Award Winning Restaurant</a></h4>
                </div>
              </div>
            </div>
          </div> */}
          </div>
        </div>
      </section>
      {/* <section className="content-with-photo-16 py-5">

      <div className="content-with-photo-16-main py-lg-5 py-sm-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 column">
              <div className="content-with-photo-16-inf">
                <h3>We are competitive in architecture, interior solutions </h3>
                <p>Aliquam blandit vel sapien eget aliquam. Phasellus eget volutpat libero. Suspendisse
                eleifend nibh quis dui placerat fermentum.n molestie nulla felis, vitae feugiat justo
                  pulvinar et. </p>
                <ul className="content-photo-list">
                  <li>
                    <span>We provide a architectural 3D modeling services.</span>
                  </li>
                  <li>
                    <span>Our specialists are ready to consult you on any topic.</span>
                  </li>
                  <li>
                    <span>We develop and implement better interior design.</span>
                  </li>
                  <li>
                    <span>We provide high-quality interior services for clients.</span>
                  </li>
                </ul>
                <a className="btn btn-outline-primary btn-outline-style mt-4" href="#url">Read More</a>
              </div>
            </div>
            <div className="col-lg-6 column mt-lg-0 mt-md-5 mt-4">
              <div className="companies-img">
                <ThemeImage src="images/about1.jpg" className="img-fluid radius-image" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> */}
      {/* //content-with-photo-16 */}
      {/* team */}
      {/* <section className="content-with-photo-16 py-5" id="w3l-team">
        <div className="container py-lg-4 py-sm-3">
          <h3 className=" mb-md-5 mb-4 text-center">Interior View</h3>
          <div className="row text-center">
            <div className="col-md-3 col-6">
              <div className="team-grids text-center">
                <ThemeImage src="images/decoration1.jpg" className="img-fluid decoration decoration-image" />

              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="team-grids text-center">
                <ThemeImage src="images/decoration2.jpg" className="img-fluid" alt />

              </div>
            </div>
            <div className="col-md-3 col-6 mt-md-0 mt-sm-5 mt-5">
              <div className="team-grids text-center">
                <ThemeImage src="images/decoration3.jpg" className="img-fluid" />

              </div>

            </div>
            <div className="col-md-3 col-6 mt-md-0 mt-sm-5 mt-5">
              <div className="team-grids text-center">
                <ThemeImage src="images/decoration4.jpg" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </SlideAnimation>
  );
}

export default AboutPage;
