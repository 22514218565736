import {
  Badge, BottomNavigation, BottomNavigationAction, SwipeableDrawer
} from '@mui/material';
import ViewListIcon from '@mui/icons-material/ViewList';
import PublishIcon from '@mui/icons-material/Publish';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useEffect, useState } from 'react';
import styles from './BottomNav.module.scss';
import clsx from 'clsx';
import Cart from '../../pages/order/cart/cart';
import Cart2 from '../../pages/order/cart/cart2';
import { connect } from 'react-redux';
import SideMenu from '../menu/sideMenu';
import CartPreview from '../cart/cartPreview';
import { CURRENCY_SYMBOL } from '../../util/constants';
import routes from '../../util/routes';
import { useLocation, useNavigate } from 'react-router-dom';
import { updateShowLeftSideBar } from '../../store/layout/layoutActions';



function BottomNav({ ...otherProps }) {

  const classes = {
    listRight: {
      width: '80vw',
    },
    listLeft: {
      width: '80vw',
    },
    fullList: {
      width: 'auto',
    },
  }

  const location = useLocation();

  const navigate = useNavigate();

  const [badgeDisable, setBadgeDisable] = useState(true);

  const orderPage = routes.order;

  const orderConfirmationPage = routes.orderConfirmation;

  const currentPath = location.pathname;

  const [totalOrderCost, setTotalOrderCost] = useState(0);

  const calculateTotalItems = (items) => {

    const itemsTotal = items.reduce((acc, item) => (acc + item.qty), 0);

    return itemsTotal || 'No';
  }


  useEffect(() => {

    let totalCost = otherProps.cart.itemsTotal + otherProps.cart.addonsTotal + otherProps.cart.setMenusTotal;
    totalCost -= otherProps.discount.value;
    totalCost -= otherProps.coupon.value;
    totalCost += otherProps.deliveryCharge.charge;

    setTotalOrderCost(totalCost);


  }, [otherProps.cart.items,
  otherProps.discount,
  otherProps.coupon,
  otherProps.deliveryCharge,
  otherProps.cart.setMenusTotal,
  ]);


  useEffect(() => {
    if (otherProps.itemsTotal <= 0) {
      setBadgeDisable(true);
      return;
    }
    setBadgeDisable(false);
  }, [otherProps.itemsTotal]);

  useEffect(() => {

    if (otherProps.isShowLeftSideBar) {
      toggleDrawer('left', true);
    }

  }, [otherProps.isShowLeftSideBar]);


  const [state, setState] = useState({
    left: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => {
    setState({ ...state, [anchor]: open });
  };

  const menuBottomHandler = () => {
    if (currentPath === orderPage) {
      toggleDrawer('left', true);

    } else {
      navigate(routes.order);
    }
  };


  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const scrollToMenu = () => {
    window.scrollTo(0, 150);
  };

  const listCategory = (anchor) => (
    <div
      className={clsx(classes.listLeft)}
      role="presentation"
      onClick={closeLeftSideBar}
    >

      <SideMenu />


    </div>
  );


  const closeLeftSideBar = () => {
    toggleDrawer('left', false);
    otherProps.updateShowLeftSideBar(false);
    scrollToMenu();
  }

  return (
    <div className={styles.BottomNavContainer}>

      <BottomNavigation className={styles.BottomNav}>

        {
          currentPath !== orderPage
          && (
            <BottomNavigationAction
              className={styles.BottomItem}
              label="Menu"
              showLabel
              icon={<ViewListIcon />}
              onClick={() => menuBottomHandler()}
            />
          )
        }

        {
          currentPath === orderPage
          && (
            <BottomNavigationAction
              className={styles.BottomItem}
              label="Jump"
              showLabel
              icon={<PublishIcon />}
              onClick={scrollToTop}
            />
          )
        }


        <Badge
          badgeContent={`${CURRENCY_SYMBOL}${totalOrderCost?.toFixed(2)}`}
          invisible={badgeDisable}
          color="primary"
        >
          <BottomNavigationAction
            className={styles.BottomItem}
            label="Cart"
            showLabel
            icon={<ShoppingCartIcon />}
            onClick={() => toggleDrawer('right', true)}
          />

        </Badge>


        {

          currentPath !== orderPage
          && (
            <BottomNavigationAction
              className={styles.BottomItem}
              label="Jump"
              showLabel
              icon={<PublishIcon />}
              onClick={scrollToTop}
            />
          )
        }

        {
          currentPath === orderPage
          && (
            <BottomNavigationAction
              className={styles.BottomItem}
              label="Items"
              showLabel
              icon={calculateTotalItems(otherProps.cart.items)}
            />
          )
        }



      </BottomNavigation>

      <SwipeableDrawer
        anchor="left"
        open={state['left']}
        onClose={closeLeftSideBar}
        onOpen={() => toggleDrawer('left', true)}
      >
        {listCategory('left')}

      </SwipeableDrawer>

      <SwipeableDrawer
        anchor="right"
        open={state['right']}
        onClose={() => toggleDrawer('right', false)}
        onOpen={() => toggleDrawer('right', true)}
      >


        <div
          className={clsx(classes.listRight)}
          role="presentation"
        >
          {
            currentPath !== orderConfirmationPage
            && <Cart2 drawerControlHandler={toggleDrawer} />
          }

          {
            currentPath === orderConfirmationPage
            && <CartPreview />
          }


        </div>

      </SwipeableDrawer>
    </div>
  );
}

const mapStateToProps = (state) => ({
  cart: state.cart,
  discount: state.cart.discount,
  coupon: state.cart.coupon,
  deliveryCharge: state.cart.delivery,
  itemsTotal: state.cart.itemsTotal,
  isShowLeftSideBar: state.layout.show_left_side_bar,
});

const mapDispatchToProps = (dispatch) => ({
  updateShowLeftSideBar: (isUpdate) => dispatch(updateShowLeftSideBar(isUpdate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BottomNav);
