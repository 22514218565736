import {
 Button, CardContent, Grid, Hidden, List
} from '@mui/material';
import { connect } from 'react-redux';
import MenuItem2 from './menuItem2';
import './categoryMenu.scss';
import { updateShowLeftSideBar } from '../../store/layout/layoutActions';


function CategoryMenu2({ ...otherProps }) {

  const groupItems = (item) => {

    if (!item.is_group) return [];

    const groupItems = otherProps.selectedItemsFromState[0].filter((itm) => itm.group_id === item.id);
    return groupItems;

  };

  const handleSideBar = () => {
    otherProps.updateShowLeftSideBar(true);
  }

  return (
    <List component="nav">

      <Grid className="item-card">


        <CardContent>
          {
            otherProps.selectedItemsFromState[0].map((item) => (
              <MenuItem2 key={item.id} item={item} groupItems={groupItems(item)} />
            ))
          }

        </CardContent>


      </Grid>
      <Hidden mdUp>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSideBar()}
          className="moreMenu"
          style={{ marginLeft: 145, marginBottom: 5, marginTop: 15 }}
        >
          Main Menu
        </Button>
      </Hidden>

    </List>
  );
}

const mapStateToProps = (state) => ({
  selectedCategoryFromState: state.menu.selectedCategory,
  selectedItemsFromState: state.menu.selectedItems,
});

const mapDispatchToProps = (dispatch) => ({
  updateShowLeftSideBar: (isUpdate) => dispatch(updateShowLeftSideBar(isUpdate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoryMenu2);
