export const HEADER_SHOW = 'HEADER_SHOW';
export const HEADER_HIDE = 'HEADER_HIDE';

export const FOOTER_SHOW = 'FOOTER_SHOW';
export const FOOTER_HIDE = 'FOOTER_HIDE';
export const SHOW_PROMOTIONAL_POPUP = 'SHOW_PROMOTIONAL_POPUP';
export const SHOW_AUTO_OFFER_POPUP = 'SHOW_AUTO_OFFER_POPUP';
export const SHOW_LEFT_SIDE_BAR = 'SHOW_LEFT_SIDE_BAR';
export const FIRST_ARRIVAL = 'FIRST_ARRIVAL';
export const SHOW_PAYMENT_MODAL = 'SHOW_PAYMENT_MODAL';
export const SET_TIME_OUT_REF = 'SET_TIME_OUT_REF';

