import React, { useEffect, useState } from 'react';
import {
 Box, Button, Grid, TextField, CircularProgress
} from '@mui/material';
import { cartCouponUpdate, clearCoupon } from '../../store/cart/cartActions';
import { connect } from 'react-redux';
import useNotify from '../../hooks/useNotify';
import { apiRequest } from '../../util/util';
import { API_COUPON_APPLY } from '../../util/constants';



function Coupon({ ...otherProps }) {

  const [notify] = useNotify();

  const [coupon, setCoupon] = useState(otherProps.currentCoupon);

  const [loading, setLoading] = useState(false);

  const [btnCouponTxt, setCouponTxt] = useState('Apply Coupon');

  const couponHandler = (e) => {
    setCoupon(e.target.value);
  }

  useEffect(() => {
    if (coupon !== '') {
      document.getElementById('coupon').disabled = true;
    }
  }, [setCoupon]);

  const removeCouponHandler = () => {

    setCoupon('');

    document.getElementById('coupon').disabled = false;

    otherProps.clearCoupon();
  }

  const handleCouponApply = async () => {

    if (coupon === '') {
      notify.warning('Please fillup Coupon Box');
      return;
    }

    setLoading(true);
    setCouponTxt('Process...');

    const totalItemCost = otherProps.totalItemCost + otherProps.totalAddonsCost - otherProps.discount;

    const couponObject = {
      total: totalItemCost.toFixed(2),
      order_type: otherProps.orderType.toLowerCase(),
      code: coupon,
    };

    try {

      if (couponObject.code === otherProps.currentCoupon) {

        setLoading(false);

        setCouponTxt('Apply Coupon');

        notify.info('You already apply this coupon');


      } else {
        const couponResponse = await apiRequest.post(API_COUPON_APPLY, couponObject);

        if (couponResponse.data.status) {

          setLoading(false);

          setCouponTxt('Apply Coupon');

          const couponDispatchObject = {
            value: couponResponse.data.data.value,
            code: couponObject.code,
            type: couponResponse.data.data.type,
            amount: couponResponse.data.data.amount,
          };

          otherProps.cartCouponUpdate(couponDispatchObject);

          notify.success('Coupon Successfully Added');

          document.getElementById('coupon').disabled = true;
        } else {

          setLoading(false);

          setCouponTxt('Apply Coupon');

          notify.error(couponResponse.data.message);
        }
      }


    } catch (e) {

      notify.error('Invalid Coupon');

      setLoading(false);

      setCouponTxt('Apply Coupon');
    }

  }

  return (
    <Grid container>
      <Grid item xs={12} md={4}>

        <form noValidate>
          <TextField
            type="text"
            variant="outlined"
            margin="dense"
            fullWidth
            name="time"
            label="Use Coupon Code"
            id="coupon"
            autoComplete="off"
            value={coupon}
            onChange={couponHandler}
          />

          <Box style={{ marginBottom: 5, marginTop: 7 }}>
            <Button
              color="primary"
              variant="outlined"
              disabled={loading}
              onClick={() => handleCouponApply()}
            >

              {
                loading
                && <CircularProgress color="primary" size={25} />
              }

              {btnCouponTxt}
            </Button>

            <Button
              color="secondary"
              variant="outlined"
              onClick={() => removeCouponHandler()}
              style={{ marginLeft: 5 }}
            >
              Remove Coupon
            </Button>

          </Box>


        </form>

      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  cart: state.cart,
  totalItemCost: state.cart.itemsTotal,
  totalAddonsCost: state.cart.addonsTotal,
  orderType: state.cart.order.type,
  discount: state.cart.discount.value,
  currentCoupon: state.cart.coupon.code,
});

const mapDispatchToProps = (dispatch) => ({
  cartCouponUpdate: (coupon) => dispatch(cartCouponUpdate(coupon)),
  clearCoupon: () => dispatch(clearCoupon()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Coupon);
