import {
  CART_ITEM_ADD,
  CART_ITEM_REMOVE,
  CART_ITEM_ADDONS_UPDATE,
  CART_COUPON_UPDATE,
  CART_DELIVERY_ADDRESS_UPDATE,
  CART_DELIVERY_CHARGE_UPDATE,
  CART_DELIVERY_DATE_UPDATE,
  CART_DELIVERY_TIME_UPDATE,
  CART_ORDER_TYPE_UPDATE,
  CART_ORDER_NOTE_UPDATE,
  CART_CLEAR,
  CART_PAYMENT_STATUS_UPDATE,
  CART_PAYMENT_METHOD_UPDATE,
  CART_PAYMENT_AMOUNT_UPDATE,
  CART_UPDATE_ITEMS_TOTAL,
  CART_UPDATE_ADDONS_TOTAL,
  CART_DISCOUNT_UPDATE,
  CART_UPDATE_ASAP_TIME,
  CLEAR_COUPON,
  CLEAR_DISCOUNT,
  CART_ORDER_POINTS,
  CART_POINTS_TO_MONEY,
  CART_ADJUST_POINT_MONEY,
  DELETE_CART_ITEM,
  CART_SET_MENU_ADDED,
  CART_SET_MENU_TOTAL,
  CART_SET_MENU_REMOVE,
  CART_SET_MENU_QUANTITY_INCREASE,
  CART_SET_MENU_QUANTITY_DECREASE
} from './cartTypes';

///// Item
export const cartItemAdd = (item, addon, isUpdate, index) => (dispatch) => {
    dispatch({
      type: CART_ITEM_ADD,
      payload: {
 item, addon, isUpdate, index
}
    });
    dispatch(cartUpdateItemsTotal());
    dispatch(cartUpdateAddonsTotal());
  };

export const clearCart = () => ({
    type: CART_CLEAR,
  })

export const clearCoupon = () => ({
    type: CLEAR_COUPON,
  })

export const clearDiscount = () => ({
    type: CLEAR_DISCOUNT,
  })

export const cartItemRemove = (item, addon) => (dispatch) => {
    dispatch({
      type: CART_ITEM_REMOVE,
      payload: { item, addon }
    });
    dispatch(cartUpdateItemsTotal());
    dispatch(cartUpdateAddonsTotal());
  };

export const deleteCartItem = (item, addon) => (dispatch) => {
    dispatch({
      type: DELETE_CART_ITEM,
      payload: { item, addon }
    });
    dispatch(cartUpdateItemsTotal());
    dispatch(cartUpdateAddonsTotal());
  }

const cartUpdateItemsTotal = () => ({
    type: CART_UPDATE_ITEMS_TOTAL
  });


///// Item Addon
export const cartItemAddonsUpdate = (item, addons) => (dispatch) => {
    dispatch({
      type: CART_ITEM_ADDONS_UPDATE,
      payload: { item, addons }
    });
    dispatch(cartUpdateAddonsTotal());
  };

const cartUpdateAddonsTotal = () => ({
  type: CART_UPDATE_ADDONS_TOTAL
});

///// Coupon
export const cartCouponUpdate = (coupon) => ({
    type: CART_COUPON_UPDATE,
    payload: coupon
  });

export const cartDiscountUpdate = (discount) => ({
    type: CART_DISCOUNT_UPDATE,
    payload: discount
  })

///// Address
export const cartDeliveryAddressUpdate = (address) => ({
    type: CART_DELIVERY_ADDRESS_UPDATE,
    payload: address
  });

///// Delivery charge
export const cartDeliveryChargeUpdate = (distance, charge) => ({
    type: CART_DELIVERY_CHARGE_UPDATE,
    payload: { distance, charge }
  });


///// Delivery date & time
export const cartDeliveryDateUpdate = (date) => ({
    type: CART_DELIVERY_DATE_UPDATE,
    payload: date
  });

export const cartDeliveryTimeUpdate = (time) => ({
    type: CART_DELIVERY_TIME_UPDATE,
    payload: time
  });

export const cartUpdateASAPTime = (isAsap) => ({
    type: CART_UPDATE_ASAP_TIME,
    payload: isAsap
  })

///// Order type & note
export const cartOrderTypeUpdate = (type) => ({
    type: CART_ORDER_TYPE_UPDATE,
    payload: type
  });

export const cartOrderNoteUpdate = (note) => ({
    type: CART_ORDER_NOTE_UPDATE,
    payload: note
  });

///// Payment
export const cartPaymentAmountUpdate = (amount) => ({
    type: CART_PAYMENT_AMOUNT_UPDATE,
    payload: amount
  });

export const cartPaymentStatusUpdate = (status) => ({
    type: CART_PAYMENT_STATUS_UPDATE,
    payload: status
  });

export const cartPaymentMethodUpdate = (method) => ({
    type: CART_PAYMENT_METHOD_UPDATE,
    payload: method
  });

export const updateCartOrderPoints = (payload) => ({
    type: CART_ORDER_POINTS,
    payload,
  });

export const updateCartPointsToMoney = (payload) => ({
    type: CART_POINTS_TO_MONEY,
    payload,
  });

export const updateAdjustPointMoney = (payload) => ({
    type: CART_ADJUST_POINT_MONEY,
    payload,
  });

export const setMenuAdd = (payload) => (dispatch) => {

  dispatch({
    type: CART_SET_MENU_ADDED,
    payload,
  });

  dispatch(cartItemSetMenuTotal());
};

export const removeSetMenu = (item, index) => (dispatch) => {
  dispatch({
    type: CART_SET_MENU_REMOVE,
    payload: { item, index },
  });
  dispatch(cartItemSetMenuTotal());
};

export const increaseSetMenuQty = (item, index) => (dispatch) => {
  dispatch({
    type: CART_SET_MENU_QUANTITY_INCREASE,
    payload: { item, index },
  });
  dispatch(cartItemSetMenuTotal());
}

export const decreaseSetMenuQty = (item, index) => (dispatch) => {
  dispatch({
    type: CART_SET_MENU_QUANTITY_DECREASE,
    payload: { item, index },
  });
  dispatch(cartItemSetMenuTotal());
}

const cartItemSetMenuTotal = () => ({
  type: CART_SET_MENU_TOTAL,
});
