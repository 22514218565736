import { colors, createTheme } from '@mui/material';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#CA0603',
      dark: '#BA0512',
    },
    secondary: {
      main: '#C90603',
      dark: '#B50603'
    },
    customStatus: {
      main: colors.red['900'],
    },
    colorDark: {
      main: '#141414',
    },
    customDanger: {
      main: '#CA0101',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    colors
  },
  typography: {
    fontFamily: [
      'Roboto',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});
