import React from 'react';
import PropTypes from 'prop-types';
import PageHeaderBreadcrumb from './pageHeaderBreadcrumb';

export default function PageHeader({
 pageName, bgClass, breadCrumbComponent, breadCrumbComponentPadding
}) {

  const backgroundImgClass = (bgClass === undefined) ? 'breadcrumb-bg-about' : bgClass;

  return (
    <>
      <section className="w3l-about-breadcrumb">
        <div className={`breadcrumb-bg ${backgroundImgClass} py-5`}>
          <div className="container py-lg-3 py-md-3">
            <h2 className="title">{ pageName }</h2>
          </div>
        </div>
      </section>

      <PageHeaderBreadcrumb pageName={pageName} component={breadCrumbComponent} padding={breadCrumbComponentPadding} />
    </>
  );
}

PageHeader.prototypes = {
  pageName: PropTypes.string.isRequired
};
