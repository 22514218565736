import PropTypes from 'prop-types';
import ModalComp from '../util/Modal/ModalComp';
import CardPaymentSteps from '../order/cardPaymentSteps';


function CardModal({
 show, selectedPaymentMethod, resetPaymentMethodHandler, ...otherProps
}) {

  return (
    <ModalComp
      title="Make Card Payment"
      open={show}
      hideFooter
      closeHandler={resetPaymentMethodHandler}
      closeOnBackdropClick={false}
      disableFocusEnforcement
    >
      <CardPaymentSteps />
    </ModalComp>
  );
}


CardModal.propTypes = {
  show: PropTypes.bool,
  resetPaymentMethodHandler: PropTypes.func,
};

export default CardModal;
