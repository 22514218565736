import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { Box, Hidden, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import BottomNav from './BottomNav';
import useSetting from '../../hooks/useSetting';
import { SETTING_FILE_PATH } from '../../util/constants';
import routes from '../../util/routes';
import Copyright from '../util/copyright';
import './footer.scss';

import { getTimeFormatFromTimeString } from '../../util/util';



function Footer({ ...otherProps }) {

  const setting = useSetting(['site_title',
    'facebook_url',
    'instagram_url',
    'trip_advisor',
    'address',
    'country_code',
    'phone',
    'display_email',
    'designed_by',
    'copyright_text',
    'site_logo_large',
    'about_description',

  ]);

  const footerTiming = useMemo(() => (
    <>
      {
        otherProps.opening.map((day) => (
          <Box key={day.id}>
            <Typography
              variant="body1"
              className="text-capitalize footer-time"
              style={{
                fontSize: 18,
                fontWeight: 600
              }}
            >
              {day.day}
            </Typography>
            {
              day.hours.map((hour) => (
                // eslint-disable-next-line react/jsx-key
                <Typography
                  kay={`hr${hour.id}`}
                  variant="body1"
                  className="footer-time"
                  style={{
                    marginLeft: 30
                  }}
                >
                  {getTimeFormatFromTimeString(hour.from, '', false)}
                  {' '}
                  -
                  {getTimeFormatFromTimeString(hour.to, '', false)}
                </Typography>
              ))
            }
          </Box>
        ))
      }
    </>
), [otherProps.opening]);

  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }


  if (!otherProps.show_footer) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }


  return (
    <>
      <section className="w3l-footer-29-main">
        <footer className="footer-32892 pb-0">
          <div className="site-section">
            <div className="container">
              <div className="row">
                <div className="col-md pr-md-5 mb-4 mb-md-0">

                  <h3 className="footer-title">
                    {setting.site_title}
                  </h3>

                  <img src={`${SETTING_FILE_PATH + setting.site_logo_large}`} height="70" alt="" />

                  <p className="footer-about">{setting.about_description?.substring(0, 235)}</p>
                </div>


                <div className="col-md mb-4 pr-md-5 mb-md-0">
                  <h3 className="footer-title">Contact Information</h3>
                  <div className="row gallery">

                    <ul className="list-unstyled quick-info mb-4">

                      <li>
                        <p className="footer-address">
                          <i className="fa fa-map-marker mr-2" />
                          {setting.address}
                        </p>
                      </li>

                      <li>
                        <a href={`tel:${setting.country_code}-${setting.phone}`} className="footer-phone">
                          <i className="fa fa-phone mr-2" />
                          (
                          {setting.country_code}
                          )-
                          {setting.phone}
                        </a>
                      </li>

                      <li>
                        <a href={`mailto: ${setting.display_email}`} className="footer-email">
                          <i className="fa fa-envelope-open-o mr-2" />
                          {setting.display_email}
                        </a>
                      </li>

                    </ul>
                  </div>
                </div>

                <div className="col-md mb-4 mb-md-0">
                  <h3 className="footer-title">Opening Times</h3>
                  {
                  footerTiming
                }
                </div>

                <div className="col-12">
                  <div className="py-5 footer-menu-wrap d-md-flex align-items-center">
                    <ul className="list-unstyled footer-menu mr-auto">
                      <li><Link to={routes.about}>About Us</Link></li>
                      <li><Link to={routes.privacyPolicy}>Privacy Policy</Link></li>
                      <li><Link to={routes.termsConditions}>Terms & Conditions</Link></li>
                      <li><Link to={routes.allergyInformation}>Allergy Information</Link></li>
                      <li><Link to={routes.contact}>Contact Us</Link></li>
                    </ul>

                    <div className="main-social-footer-29 mt-3 text-center">
                      <a href={setting.facebook_url} target="_blank" rel="noreferrer" className="facebook"><i className="fa fa-facebook" /></a>
                      <a href={setting.trip_advisor} target="_blank" rel="noreferrer" className="twitter"><i className="fa fa-tripadvisor" /></a>
                      <a href={setting.instagram_url} target="_blank" rel="noreferrer" className="instagram"><i className="fa fa-instagram" /></a>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>

      </section>

      <section className="w3l-footer-29-main w3l-copyright">
        <div className="container">
          <div className="bottom-copies">
            <p className="copy-footer-29 text-center">
              <Copyright />
            </p>
          </div>
        </div>

        <button onClick={() => topFunction()} id="movetop" title="Go to top">&#10548;</button>

        <Hidden lgDown>
          <button onClick={() => topFunction()} id="movetop" title="Go to top">&#10548;</button>
        </Hidden>


        <Hidden mdUp>
          <BottomNav />
        </Hidden>


      </section>
    </>
  );
}

const mapStateToProps = (state) => ({

  show_footer: state.layout.show_footer,
  opening: state.opening.data,
  isLogin: state.user.isLoggedIn,
});

export default connect(mapStateToProps)(Footer);
