export const CART_ITEM_ADD = 'CART_ITEM_ADD';
export const CART_ITEM_REMOVE = 'CART_ITEM_REMOVE';

export const CART_ITEM_ADDONS_UPDATE = 'CART_ITEM_ADDONS_UPDATE';

export const CART_SET_MENU_ADDED = 'CART_SET_MENU_ADDED';
export const CART_SET_MENU_TOTAL = 'CART_SET_MENU_TOTAL';
export const CART_SET_MENU_REMOVE = 'CART_SET_MENU_REMOVE';
export const CART_SET_MENU_QUANTITY_INCREASE = 'CART_SET_MENU_QUANTITY_INCREASE';
export const CART_SET_MENU_QUANTITY_DECREASE = 'CART_SET_MENU_QUANTITY_DECREASE';

export const CART_COUPON_UPDATE = 'CART_COUPON_UPDATE';
export const CLEAR_COUPON = 'CLEAR_COUPON';

export const CART_DISCOUNT_UPDATE = 'CART_DISCOUNT_UPDATE';
export const CLEAR_DISCOUNT = 'CLEAR_DISCOUNT';

export const CART_DELIVERY_ADDRESS_UPDATE = 'CART_DELIVERY_ADDRESS_UPDATE';
export const CART_DELIVERY_CHARGE_UPDATE = 'CART_DELIVERY_CHARGE_UPDATE';
export const CART_DELIVERY_DATE_UPDATE = 'CART_DELIVERY_DATE_UPDATE';
export const CART_DELIVERY_TIME_UPDATE = 'CART_DELIVERY_TIME_UPDATE';

export const CART_ORDER_TYPE_UPDATE = 'CART_ORDER_TYPE_UPDATE';
export const CART_ORDER_NOTE_UPDATE = 'CART_ORDER_NOTE_UPDATE';
export const CART_CLEAR = 'CLEAR_CART';

export const CART_PAYMENT_AMOUNT_UPDATE = 'CART_PAYMENT_AMOUNT_UPDATE';
export const CART_PAYMENT_STATUS_UPDATE = 'CART_PAYMENT_STATUS_UPDATE';
export const CART_PAYMENT_METHOD_UPDATE = 'CART_PAYMENT_METHOD_UPDATE';
export const DELETE_CART_ITEM = 'DELETE_CART_ITEM';

export const CART_UPDATE_ITEMS_TOTAL = 'CART_UPDATE_ITEMS_TOTAL';
export const CART_UPDATE_ADDONS_TOTAL = 'CART_UPDATE_ADDONS_TOTAL';

export const CART_UPDATE_ASAP_TIME = 'CART_UPDATE_ASAP_TIME';

export const CART_ORDER_POINTS = 'CART_ORDER_POINTS';
export const CART_POINTS_TO_MONEY = 'CART_POINTS_TO_MONEY';
export const CART_ADJUST_POINT_MONEY = 'CART_ADJUST_POINT_MONEY';

