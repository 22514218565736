import React, { useEffect, useState } from 'react';
import { Box, Button, Checkbox } from '@mui/material';
import { connect } from 'react-redux';
import { CURRENCY_SYMBOL } from '../../util/constants';
import './setMenu.scss';
import SetMenuAddons from './setMenuAddons';
import PopupMobile from '../util/popupMobile';
import useNotify from '../../hooks/useNotify';
import '../../assets/css/custom.scss';
import _ from 'lodash';
import StackedAnimation from '../util/Animations/StackedAnimation';


function AddStarterItem({
  item, step, handleStep, selectedItems, setSelectedItems, ...otherProps
}) {

  const [filterItems, setFilterItems] = useState([]);

  let arr = [];

  const [showSummary, setShowSummary] = useState(false);

   Object.keys(otherProps.items).map((item) => {
    otherProps.items[item].map((itm) => arr.push(itm))
  })


  const [showAddonPopup, setShowAddonPopup] = useState(false);

  const [addonItem, setAddonItem] = useState([]);

  const [selectedAddons, setSelectedAddons] = useState([]);

  const [checked, setChecked] = useState([]);

  const [shouldDisable, setShouldDisable] = useState([]);

  const [summaryItems, setSummaryItems] = useState({});

  const [notify] = useNotify();


  useEffect(() => {
    if (step === 'Set Menu Summary') {
      const itemWithGroup = _.groupBy(selectedItems, (item) => item.type)
      setSummaryItems(itemWithGroup);
      setShowSummary(true);
    }

    const filterArray = arr.filter((item) => item.type === step && item.is_group === 0)
    setFilterItems(filterArray);

    if (step !== 'Set Menu Summary') {
      setShowSummary(false);
    }

  }, [step])

  useEffect(() => {

    const count = selectedItems.filter((itm) => itm.type === step).length;
    if (item[step]?.allowed_qty === count) {
      setTimeout(() => {
        handleStep((prevStep) => prevStep + 1);
      }, 300);
    }

    const itemsIds = selectedItems.map((item) => item.id);

    setChecked(itemsIds);

    let disableItemIds = [];

    if (!showSummary && count === item[step]?.allowed_qty) {
       filterItems.map((i) => {
         const f = itemsIds.indexOf(i.id);
         if (f === -1) {
           disableItemIds.push(i.id);
         }
      });
    }
    setShouldDisable(disableItemIds);
  }, [selectedItems])


  const handleAddonPopup = (item) => {
    setShowAddonPopup(true);
    setAddonItem(item);
  }

  const addAdditionalItem = (item) => {
    if (item.addons.length) {
      handleAddonPopup(item);
      return;
    }
    addToStoreItemHandler(item);
  }

  const handleItemChecked = (checkItem, addon) => {

    const existingItemIndex = selectedItems.findIndex((itm) => {

      if (itm.id !== checkItem.id) return false;

      // checking if same addons exists in the item
      const itemsExistingAddons = itm?.selected_addon?.map(({ id }) => id);

      const matchingAddonWithExistingAddons = addon
        .filter((addon) => itemsExistingAddons.includes(addon.id))
        .length;
      return itemsExistingAddons.length === matchingAddonWithExistingAddons && itm.type === checkItem.type;
    });

    const existingItem = selectedItems[existingItemIndex];

    if (existingItemIndex !== -1) {
      itemRemoveFromStore(existingItem, existingItem.addons);
      return;
    }

    const count = selectedItems.filter((itm) => itm.type === step).length;
    if (item[step]?.allowed_qty === count) {
      notify.warning(`You can only choose ${item[step]?.allowed_qty} items`);
      return;
    }

    if (checkItem?.addons.length) {
      handleAddonPopup(checkItem);
      return;
    }
    addAdditionalItem(checkItem);
  }
  //remove additional item from state
  const itemRemoveFromStore = (item, addon) => {

    const existingItemIndex = selectedItems.findIndex((itm) => {

      if (itm.id !== item.id) return false;

      // if length is not same then they're different
      return (itm.id === item.id);

    });

    // if item not found just return all items
    if (existingItemIndex === -1) {
      return selectedItems;
    }

    const existingItem = selectedItems[existingItemIndex];

    // if item has more than 1 qty then deduct one
    if (existingItem.qty > 1) {

      const newItem = {
        ...existingItem,
        qty: (existingItem.qty - 1)
      };

      let allItems = [...selectedItems];

      allItems.splice(existingItemIndex, 1, newItem);
      setSelectedItems(allItems)

    } else { // or remove the item
      let allItems = [...selectedItems];

      allItems.splice(existingItemIndex, 1);
      setSelectedItems(allItems)
    }

    return selectedItems;
  }

  const allFilterItems = () => (
    filterItems.map((itm, index) => {
      const showPrice = item[step]?.items?.filter((ittm) => ittm.id === itm.id);
      let priceLength = 0;
      if (showPrice) {
        priceLength = showPrice.length;
      }
      return (
        <StackedAnimation duration={10} directionScale="25%">
          <Box
            key={`${itm.id}_${index}`}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className="hover-pointer filter-items"
            onClick={() => handleItemChecked(itm, itm.addons)}
          >
            <Box>
              {itm.name}
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
            >
              <Box className="additional-item-price">

                {
                priceLength !== 0
                && (
                  <span>
                      {CURRENCY_SYMBOL}
                    {showPrice[0].add_price.toFixed(2)}
                  </span>
                )
              }
                <Checkbox
                  disabled={shouldDisable.indexOf(itm.id) !== -1}
                  checked={checked.indexOf(itm.id) !== -1}
                />
              </Box>

            </Box>
          </Box>
        </StackedAnimation>
      )
    })
  );


  const summaryOfItems = () => (

    Object.keys(summaryItems).map((key, index) => (

      <Box
        key={`${key.id}_${index}`}
        p={1}
      >
        <Box className="summary-type">
          {key}
        </Box>

        {
          summaryItems[key]?.map((item) => (
            <Box key={item.id}>
              <Box
                display="flex"
                justifyContent="space-between"
              >
                <Box className="summary-item">
                  {item.name}

                </Box>
                <Box className="additional-item-price">
                  {
                   item.additional_price !== 0
                   && (
                     <span>
                       {CURRENCY_SYMBOL}
                       {item.additional_price}
                     </span>
                   )

                 }

                  {
                   item.additional_price === 0
                   && (
                     <span>include</span>
                   )
                 }
                </Box>
              </Box>

              {
                item.selected_addon?.map((addon, index) => (
                  <Box
                    key={index}
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Box className="summary-addon">
                      {addon.name}
                    </Box>
                    <Box className="summary-addon">
                      {
                        (addon.additional_price)
                        && (
                          <span>
                            {CURRENCY_SYMBOL}
                            {addon.additional_price}
                          </span>
                        )
                      }
                      {
                        (!addon.additional_price)
                        && (
                          <span>
                            inc.
                          </span>
                        )
                      }
                    </Box>
                  </Box>

                ))
              }
            </Box>

          ))
        }

      </Box>

    ))
  );


  const addToStoreItemHandler = (item, addon = []) => {

    const existingItemIndex = selectedItems.findIndex((itm) => {

      if (itm.id !== item.id) return false;

      // if length is not same then they're different
      if (itm?.selected_addon.length !== addon.length) return false;

      // checking if same addons exists in the item
      const itemsExistingAddons = itm?.selected_addon.map(({ id }) => id);

      const matchingAddonWithExistingAddons = addon
        .filter((addon) => itemsExistingAddons.includes(addon.id))
        .length;
      return itemsExistingAddons.length === matchingAddonWithExistingAddons && itm.type === item.type;
    });

    const existingItem = selectedItems[existingItemIndex];

    let newItemWithAddon;
    let arr = [...selectedItems];


    if (existingItemIndex === -1) {
      newItemWithAddon = { ...item, selected_addon: addon, qty: 1 }
      arr.push(newItemWithAddon);
    }

    setSelectedItems(arr);
    setShowAddonPopup(false);

  }

  useEffect(() => {
    if (selectedItems.length) {
      setSelectedItems(selectedItems);
    }
  }, [selectedItems]);

  return (
    <Box>
      {
        !showSummary
        && (
          <Box className="additional-item-box">
            {
              allFilterItems()
            }
          </Box>
        )
      }

      {
        showSummary
        && (
        <Box>
          {
            summaryOfItems()
          }
        </Box>
        )
      }

      {
        showAddonPopup

        && (
          <PopupMobile open={showAddonPopup} openHandler={setShowAddonPopup} title="Select Addons">
            <Box>
              <SetMenuAddons
                item={addonItem}
                selectHandler={setSelectedAddons}
                currentItem={item[step]}
              />
            </Box>
            <Box display="flex" justifyContent="center">
              <Button
                color="primary"
                variant="contained"
                onClick={() => addToStoreItemHandler(addonItem, selectedAddons)}
                disabled={otherProps.isOffDay}
              >
                { otherProps.isOffDay ? 'Today we\'re closed' : 'Add Addons' }
              </Button>
            </Box>
          </PopupMobile>
        )

      }
    </Box>
  );
}

const mapStateToProps = (state) => ({
  addons: state.cart.addons,
  items: state.menu.items,
  isOffDay: state.opening.isOffDay,
});

export default connect(mapStateToProps)(AddStarterItem);
