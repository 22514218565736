import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
 Box, Button, Grid, Modal, Backdrop
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import FadeAnimation from '../Animations/FadeAnimation';
import CloseIcon from '@mui/icons-material/Close';


function ModalComp({
  backDropTimeout = 500,
  submitBtnText = 'Submit',
  open,
  closeHandler,
  closeOnBackdropClick = true,
  submitHandler,
  showSubmitLoader = true,
  clickNode,
  title,
  cardBackgroundColor = '',
  modalBodyPadding = '',
  modalCardPosition = '',
  primaryBackground = false,
  hideFooter = false,
  hideHeader = false,
  maxDesktopWidth = '',
  minWidth = '',
  disableFocusEnforcement = false,
  ...otherProps
}) {

  if (isMobile) {
    maxDesktopWidth = '90vw';
  }

  const styles = {
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    primaryBackground: {
      backgroundColor: (theme) => theme.palette.primary.main,
    },
    paper: {
      backgroundColor: (theme) => theme.palette.background.paper,
      boxShadow: (theme) => theme.shadows[5],
    },
    modalCard: {
      maxWidth: `${maxDesktopWidth || 'auto'}`,
      minWidth: `${minWidth || 'auto'}`,
      minHeight: 'auto',
      overflow: 'auto',
      maxHeight: '100vh'
    },
    modalContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly'
    },
    modalTitle: {
      padding: (theme) => theme.spacing(1),
      backgroundColor: (theme) => theme.palette.primary.dark,
      color: (theme) => theme.palette.primary.contrastText,
      fontSize: '25px',
      borderBottom: '1px solid',
      borderColor: (theme) => theme.palette.red900,
      display: 'flex',
      justifyContent: 'space-between'
    },
    modalTitleCloseButton: {
      cursor: 'pointer',
      marginLeft: (theme) => theme.spacing(1)
    },
    modalBody: {
      padding: (theme) => theme.spacing(1),
    },
    modalFooter: {
      backgroundColor: (theme) => theme.palette.background.default,
      display: 'flex',
      justifyContent: 'flex-end',
      padding: (theme) => theme.spacing(2),
    },
    modalFooterCancelButton: {
      marginRight: 10
    }
  };
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (open !== undefined) {
      setShow(open);
    }
  }, [open]);

  const handleOpen = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);

    if (typeof closeHandler === 'function') {
      closeHandler(false);
    }
  };

  return (
    <div>
      {
        clickNode
        && (
        <span onClick={handleOpen}>
          {clickNode}
        </span>
        )
      }

      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        sx={{ ...styles.modal, color: primaryBackground ? styles.primaryBackground : '' }}
        open={show}
        onClose={handleClose}
        closeAfterTransition
        disableEnforceFocus={disableFocusEnforcement}
        // BackdropComponent={Backdrop}
        // disableBackdropClick={!closeOnBackdropClick}
        BackdropProps={{
          timeout: backDropTimeout,
        }}
      >
        <FadeAnimation in={show}>
          <Box style={{ position: modalCardPosition, backgroundColor: cardBackgroundColor }} sx={{ ...styles.paper, ...styles.modalCard }}>
            {
              !hideHeader
              && (
              <Box sx={styles.modalTitle}>
                <Box>{title}</Box>
                <Box sx={styles.modalTitleCloseButton} onClick={handleClose}>
                  <CloseIcon />
                </Box>
              </Box>
                )
            }


            <Box sx={styles.modalContent}>
              <Box sx={styles.modalBody}>
                {
                  otherProps.children || <h1>Default Modal Content</h1>
                }
              </Box>
              {
                !hideFooter
                && (
                <Box sx={styles.modalFooter}>
                  <Button
                    sx={styles.modalFooterCancelButton}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button color="secondary" variant="contained" onClick={submitHandler}>{submitBtnText}</Button>
                </Box>
)
              }
            </Box>
          </Box>
        </FadeAnimation>
      </Modal>
    </div>
  );
}

ModalComp.propTypes = {
  open: PropTypes.bool,
  backDropTimeout: PropTypes.number,
  submitBtnText: PropTypes.string,
  submitHandler: PropTypes.func,
  showSubmitLoader: PropTypes.bool,
  closeHandler: PropTypes.func,
  closeOnBackdropClick: PropTypes.bool,
  clickNode: PropTypes.node,
  title: PropTypes.string,
  maxDesktopWidth: PropTypes.string,
  hideFooter: PropTypes.bool,
};

export default ModalComp;
