import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

export default function RedirectTo({ fullUrl, showRedirectingText = false }) {

  useEffect(() => {
    window.location.replace(fullUrl);
  }, []);

  return showRedirectingText ? <Typography>Redirecting...</Typography> : null;
}

RedirectTo.propTypes = {
  fullUrl: PropTypes.string.isRequired,
  showRedirectingText: PropTypes.bool
};
