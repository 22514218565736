import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import {
 Dialog, AppBar, Toolbar, IconButton, Typography, Slide
} from '@mui/material';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function PopupMobile({
 open, openHandler, title, ...otherProps
}) {
  const styles = {
    appBar: {
      position: 'relative',
      paddingRight: '5px !important',
    },
    toolBar: {
    },
    title: {
      marginLeft: (theme) => theme.spacing(2),
      flex: 1,
      justifyContent: 'end',
      color: '#fff'
    },
    closeBtn: {
      marginRight: '-20px'
    }
  };

  return (
    <div>
      <Dialog fullScreen open={open} onClose={() => openHandler(false)} TransitionComponent={Transition}>
        <AppBar sx={styles.appBar}>
          <Toolbar sx={styles.toolBar}>
            <Typography variant="h6" sx={styles.title}>{title}</Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => openHandler(false)}
              aria-label="close"
              sx={styles.closeBtn}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        {
          otherProps.children
        }
      </Dialog>
    </div>
  );
}

PopupMobile.propTypes = {
  open: PropTypes.bool.isRequired,
  openHandler: PropTypes.func.isRequired,
  title: PropTypes.string
}
