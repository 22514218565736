import ModalComp from '../util/Modal/ModalComp';
import { useEffect, useState } from 'react';
import {
 Box, Grid, Typography, styled
} from '@mui/material';
import { apiRequest, formatDateTime } from '../../util/util';
import { API_GET_OFFERS, CURRENCY_SYMBOL } from '../../util/constants';
import useNotify from '../../hooks/useNotify';
import _ from 'lodash';
import { connect } from 'react-redux';
import { updateShowAutoOfferPopup } from '../../store/layout/layoutActions';

function Offers({ ...otherProps }) {

  const styles = {
    highlightWord: {
      color: (theme) => theme.palette.secondary.light,
      whiteSpace: 'nowrap'
    },
    offerContainer: {
      border: '2px solid',
      borderStyle: 'dashed',
      borderColor: (theme) => theme.palette.grey['500'],
      backgroundColor: 'antiquewhite',
      padding: 2,
      borderRadius: 7
    },
    discountTitle: {
      margin: (theme) => theme.spacing(1),
      color: (theme) => theme.palette.success.main
    },
    couponTitle: {
      color: (theme) => theme.palette.primary.main,
      margin: (theme) => theme.spacing(1),
    }
  };

  const Span = styled('span')``;
  const [notify] = useNotify();

  const [offers, setOffers] = useState({});
  const [open, setOpen] = useState(false);

  useEffect(() => {

    if ((
      offers.discount?.length
      || offers.coupon?.length
    )
      && !otherProps.show_auto_offer_popup
    ) {
      otherProps.updateShowAutoOfferPopup(true);
      setOpen(true);
    }
  }, [offers]);

  const closeHandler = () => {
    setOpen(false);
  };

  const openModal = () => {
    setOpen(true);
  };

  useEffect(() => {

    async function getOffers() {

      // dont load if offer already available
      if (!_.isEmpty(offers)) return;

      try {

        const response = await apiRequest.get(API_GET_OFFERS);

        setOffers({ ...response.data.data });

      } catch (e) {
        notify.error('Sorry! temporarily offers are unavailable');
      }
    }


    getOffers();

  }, []);

  const getOrderTypeForPrint = (type) => {
    if (type === 'both') {
      return 'Collection & Delivery'
    }

    return type.replace(type[0], type[0].toUpperCase());
  };

  const printOffers = () => (
    <>
      <Grid item xs={12} lg={6}>
        <Typography variant="h4" textTransform="uppercase" sx={styles.discountTitle}>Discounts</Typography>
        {
          !offers.discount?.length
          && (
          <Box mx={1}>
            <Typography
              variant="h5"
              sx={{
              p: 2
            }}
            >
              Sorry! No discount available at this time.
            </Typography>
          </Box>
)
        }
        {
          offers.discount?.map((discount) => (
            <Box
              mx={1}
              marginBottom={2}
              key={discount.id}
              sx={styles.offerContainer}
            >
              <Typography
                variant="h5"
                textTransform="uppercase"
                color="success.main"
              >
                {discount.fixed && CURRENCY_SYMBOL}
                {discount.amount}
                {!discount.fixed && ' %'}
                {' '}
                discount
              </Typography>
              <Typography>
                on
                {' '}
                {getOrderTypeForPrint(discount.for)}
                {' '}
                orders
              </Typography>
              {/*<Typography variant="caption">*/}
              {/*  from*/}
              {/*  {' '}*/}
              {/*  <Span sx={styles.highlightWord}>*/}
              {/*    {formatDateTime(discount.from)}*/}
              {/*  </Span>*/}
              {/*  {' to '}*/}
              {/*  <Span sx={styles.highlightWord}>*/}
              {/*    {formatDateTime(discount.to)}*/}
              {/*  </Span>*/}
              {/*</Typography>*/}
            </Box>
          ))
        }
      </Grid>

      <Grid item xs={12} lg={6}>
        <Typography variant="h4" textTransform="uppercase" sx={styles.couponTitle}>Coupons</Typography>
        {
          !offers.coupon?.length
          && (
          <Box mx={1}>
            <Typography variant="h5">Sorry! No coupon available at this time.</Typography>
          </Box>
)
        }
        {
          offers.coupon?.map((coupon) => (
            <Box mx={1} marginBottom={2} key={coupon.id} sx={styles.offerContainer}>
              <Typography variant="h5" color="primary">
                {coupon.code}
              </Typography>
              <Typography>
                <Span sx={styles.highlightWord}>
                  {coupon.fixed && CURRENCY_SYMBOL}
                  {`${coupon.amount} `}
                  {!coupon.fixed && '% '}
                </Span>
                off on
                {' '}
                {getOrderTypeForPrint(coupon.for)}
                {' '}
                orders
              </Typography>
              {/*<Typography variant="caption">*/}
              {/*  from*/}
              {/*  {' '}*/}
              {/*  <Span sx={styles.highlightWord}>*/}
              {/*    {formatDateTime(coupon.from)}*/}
              {/*  </Span>*/}
              {/*  {' to '}*/}
              {/*  <Span sx={styles.highlightWord}>*/}
              {/*    {formatDateTime(coupon.to)}*/}
              {/*  </Span>*/}
              {/*</Typography>*/}
            </Box>
          ))
        }

      </Grid>
    </>
);

  return (
    <>
      {
      otherProps.children(openModal)
    }

      <ModalComp
        title="Available Offers"
        open={open}
        closeHandler={closeHandler}
        hideFooter
      >
        <Grid container>

          {
          _.isEmpty(offers)
          && (
          <Grid item xs={12}>
            <Typography variant="h4">Sorry! No offers available right now</Typography>
          </Grid>
)
        }

          {
          !_.isEmpty(offers) && printOffers()
        }

        </Grid>
      </ModalComp>
    </>
  );
}

const mapStateToProps = (state) => ({
  show_auto_offer_popup: state.layout.show_auto_offer_popup,
});

const mapDispatchToProps = (dispatch) => ({
  updateShowAutoOfferPopup: (type) => dispatch(updateShowAutoOfferPopup(type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Offers);
