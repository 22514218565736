import React, { useRef, useState } from 'react';
import NavItem from './navItem';
import { connect } from 'react-redux';
import Logout from '../../pages/auth/logOut';
import UserAvatar from '../util/UserAvatar';
import { Link } from 'react-router-dom';
import useSetting from '../../hooks/useSetting';
import { SETTING_FILE_PATH } from '../../util/constants';
import routes from '../../util/routes';
import { isMobile } from 'react-device-detect';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material';


function Nav({ ...otherProps }) {

  const styles = {
    navbarFixed: {
      width: '100vw',
      position: 'fixed',
      zIndex: 11,
    },
  };

  const setting = useSetting([
    'phone',
    'site_logo_large',
    'navbar_fixed',
  ]);
  const mobileNavToggleRef = useRef(false);

  const Header = styled('header')``;

  const [openNav, setOpenNav] = useState(false);

  const showMenuItems = () => {
    setOpenNav((v) => !v);
  }


  return (
    <Header
      className="w3l-header-nav"
      sx={setting.navbar_fixed ? styles.navbarFixed : ''}
    >
      {/*/nav*/}
      <nav className="navbar navbar-expand-xl navbar-light fill px-lg-0 py-0 px-3">
        <div className="container nav-container">
          <Link to="/" className="navbar-brand"><img src={`${SETTING_FILE_PATH + setting.site_logo_large}`} height="50" alt="Site Logo" /></Link>

          {/* if logo is image enable this
          <a class="navbar-brand" href="#index.html">
            <img src="image-path" alt="Your logo" title="Your logo" style="height:35px;" />
          </a> */}
          {/*<button*/}
          {/*  ref={mobileNavToggleRef}*/}
          {/*  className="navbar-toggler collapsed"*/}
          {/*  type="button"*/}
          {/*  data-toggle="collapse"*/}
          {/*  data-target="#navbarSupportedContent"*/}
          {/*  aria-controls="navbarSupportedContent"*/}
          {/*  aria-expanded="false"*/}
          {/*  aria-label="Toggle navigation"*/}
          {/*>*/}
          {/*  <span className="fa icon-expand fa-bars" />*/}
          {/*  <span className="fa icon-close fa-times" />*/}
          {/*</button>*/}

          <button
            ref={mobileNavToggleRef}
            className={`navbar-toggler ${openNav ? '' : 'collapsed'}`}
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded={openNav}
            aria-label="Toggle navigation"
            onClick={showMenuItems}
          >
            {/*<span className="navbar-toggler-icon" />*/}
            {
              !openNav && <MenuIcon fontSize="large" sx={styles.mobileNavToggle} />
            }
            {
              openNav && <CloseIcon fontSize="large" sx={styles.mobileNavToggle} />
            }
          </button>

          <div className={`collapse navbar-collapse ${openNav ? 'show' : ''}`} id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">

              <NavItem name="Home" path="/" mobileMenuToggleRef={mobileNavToggleRef} />
              {/* <NavItem name="Order Online" path="/order" mobileMenuToggleRef={mobileNavToggleRef} /> */}
              {/* <NavItem name="About" path="/about" mobileMenuToggleRef={mobileNavToggleRef} /> */}
              {/* <NavItem name="Awards" path="/awards" mobileMenuToggleRef={mobileNavToggleRef} /> */}
              <NavItem name="Gallery" path="/gallery" mobileMenuToggleRef={mobileNavToggleRef} />
              {/* <NavItem name="Reservation" path="/reservation" mobileMenuToggleRef={mobileNavToggleRef} /> */}
              <NavItem name="Contact" path="/contact" mobileMenuToggleRef={mobileNavToggleRef} />


              {
                otherProps.isLoggedIn
                  ? <Logout name="LogOut" mobileMenuToggleRef={mobileNavToggleRef} />
                  : null
              }

            </ul>
            {/*/User avatar*/}
            {
              otherProps.isLoggedIn
              && (
              <Link to={routes.userProfile}>
                <div
                  className="search-right"
                  onClick={() => {
                    if (isMobile) {
                      mobileNavToggleRef?.current?.click();
                    }
                  }}
                >
                  <UserAvatar />
                </div>
              </Link>
              )
            }

            <Link to={routes.order} className="read-more btn btn-style btn-secondary mr-1 mb-1">
              <div
                onClick={() => {
                  if (isMobile) {
                    mobileNavToggleRef?.current?.click();
                  }
                }}
              >

                Order Online
              </div>
            </Link>
            {
              !otherProps.isLoggedIn && (
                <Link to={routes.signup} className="read-more btn btn-style btn-secondary mr-1 mb-1">
                  <div
                    onClick={() => {
                      if (isMobile) {
                        mobileNavToggleRef?.current?.click();
                      }
                    }}
                  >
                    Sign up
                  </div>
                </Link>
              )
            }

          </div>
        </div>
      </nav>
      {/*//nav*/}

    </Header>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.user.isLoggedIn,
  user: state.user.data
});

export default connect(mapStateToProps)(Nav);
