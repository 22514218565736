import {
  Box,
  Button,
  Card,
  Divider,
  FormControlLabel,
  Grid,
  Hidden,
  List,
  ListItem,
  Paper,
  Radio,
  RadioGroup,
  styled,
  TextField,
} from '@mui/material';
import * as dateFns from 'date-fns';
import _ from 'lodash';
import {
 useEffect, useMemo, useReducer, useState
} from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CartItem2 from '../../../components/cart/cartItem2';
import SetMenuCartItem from '../../../components/cart/SetMenuCartItem';
import NumberAnimation from '../../../components/util/Animations/NumberAnimation';
import useNotify from '../../../hooks/useNotify';
import useSetting from '../../../hooks/useSetting';
import {
  cartDiscountUpdate,
  cartItemAdd,
  cartItemAddonsUpdate,
  cartItemRemove,
  cartOrderNoteUpdate,
  cartOrderTypeUpdate,
  clearCoupon,
  clearDiscount
} from '../../../store/cart/cartActions';
import { CURRENCY_SYMBOL } from '../../../util/constants';
import { formSingleValidator, formValidator, validationRules } from '../../../util/formValidation';
import routes from '../../../util/routes';
import { moneyFormat } from '../../../util/util';
import './cart2.scss';

const errorTypes = {
  SUBMIT: 'SUBMIT',
  SUBMIT_SUCCESS: 'SUBMIT_SUCCESS',
  SUBMIT_FAIL: 'SUBMIT_FAIL',
  FIELD_ERROR: 'FIELD_ERROR',
};


const errorDefaultState = {
  loading: false,
  error: false,
  success: false,
  message: '',
  fields: {
    note: false,
  }
};


function errorReducer(state, { type, payload }) {
  switch (type) {
    case errorTypes.SUBMIT:
      return {
        ...state,
        loading: true
      }

    case errorTypes.SUBMIT_SUCCESS:
      return {
        ...state,
        error: false,
        success: true,
        loading: false,
        message: payload
      }

    case errorTypes.SUBMIT_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
        message: (payload) || 'Something went wrong, please try again later'
      }

    case errorTypes.FIELD_ERROR:
      return {
        ...state,
        fields: {
          ...state.fields,
          ...payload
        }
      }

    default:
      return state;
  }
}

function Cart2({ drawerControlHandler, ...otherProps }) {

  const styles = {
    root: {
      width: '100%',
      maxWidth: 420,
      backgroundColor: (theme) => theme.palette.background.paper,
    },
  };

  const setting = useSetting([
    'order_status',
    'order_disable_message',
  ]);

  const [errorState, errorDispatch] = useReducer(errorReducer, errorDefaultState);

  const [notify] = useNotify();

  const cartBtnStyle = {
    root: {
      width: '100%'
    }
  };

  const Div = styled('div')``;

  const navigate = useNavigate();

  const [orderType, setOrderType] = useState(otherProps.orderType);

  useEffect(() => {
    setOrderType(otherProps.orderType);
  }, [otherProps.orderType]);

  const [opensDay, setOpensDay] = useState('');

  useEffect(() => {

    otherProps.clearDiscount();
    otherProps.clearCoupon();

  }, [otherProps.cart.items, otherProps.orderType, otherProps.cart.addons]);

  const orderMinimumSetting = useSetting([
    'delivery_order_minimum',
    'collection_order_minimum',
    'delivery_order_delivery_time',
    'collection_order_delivery_time',
  ]);

  const [orderMinimum, setOrderMinimum] = useState(orderMinimumSetting.delivery_order_minimum);

  const [prepareTime, setPrepareTime] = useState(orderMinimumSetting.delivery_order_delivery_time);
  //to store order minimum value in the state from setting.
  //default delivery minimum set

  useEffect(() => {

    if (orderType === 'Collection') {
      setOrderMinimum(orderMinimumSetting.collection_order_minimum);
      setPrepareTime(orderMinimumSetting.collection_order_delivery_time);
    } else {
      setOrderMinimum(orderMinimumSetting.delivery_order_minimum);
      setPrepareTime(orderMinimumSetting.delivery_order_delivery_time);

    }

  }, [orderMinimumSetting, setOrderMinimum]);


  const orderTypeHandler = (e) => {
    setOrderType(e.target.value);
    otherProps.cartOrderTypeUpdate(e.target.value);
  };

  const ItemSubmitHandler = async () => {

    if (drawerControlHandler !== undefined) {
      drawerControlHandler('right', false);
    }

    const orderNote = document.getElementById('note').value;
    console.log('on', orderNote);

    if (orderNote?.length >= 1 && orderNote?.length < 5) {
      notify.warning('Order note should be between 5 to 100 characters');
      return;
    }

    otherProps.cartOrderNoteUpdate(orderNote);

    const totalAmount = (otherProps.cart.itemsTotal
      + otherProps.cart.addonsTotal
      + otherProps.cart.setMenusTotal).toFixed(2);

    if (!otherProps.cart.items.length && !otherProps.cart.set_menus.length) {
      notify.warning('Please add some item first');
      return;
    }

    if (orderMinimum > parseFloat(totalAmount)) {
      notify.warning(`Minimum order for ${orderType} is ${CURRENCY_SYMBOL}${orderMinimum}`);
      return;
    }

    navigate(routes.orderConfirmation);

  };

  const openingDays = useMemo(() => otherProps.opening.map((itm) => itm.day), [otherProps.opening]);

  useEffect(() => {
    if (otherProps.isClosed) {
      const today = new Date();
      const tomorrow = new Date();
      let i;
      for (i = 1; i <= 7; i += 1) {
        tomorrow.setDate(today.getDate() + i)
        const dayName = dateFns.format(tomorrow, 'EEE');
        if (openingDays.includes(dayName.toLowerCase())) {
          setOpensDay(dateFns.format(tomorrow, 'EEEE'));
          break;
        }
      }

    }

  }, [otherProps.isClosed]);

  const total = useMemo(() => moneyFormat(
      otherProps.cart.itemsTotal
      + otherProps.cart.addonsTotal
      + otherProps.cart.setMenusTotal
    ), [
    otherProps.cart.itemsTotal,
    otherProps.cart.addonsTotal,
    otherProps.cart.setMenusTotal
  ]);

  return (
    <Div sx={styles.root}>

      <Card xs={12} className="orderTypeDiv">
        <Box display="flex" justifyContent="space-between">
          <Box className="order-type-title" textTransform="uppercase" fontSize={18}>
            {orderType}
            {' '}
            Minimum
          </Box>
          <Box fontSize={18} textTransform="uppercase">
            {CURRENCY_SYMBOL}
            {orderMinimum}
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Box className="order-type-title" textTransform="uppercase" fontSize={18}>
            {orderType}
            {' '}
            Time
          </Box>
          <Box fontSize={18} textTransform="uppercase">
            {prepareTime}
            {' '}
            min
          </Box>
        </Box>
      </Card>

      <Paper className="bg-light mb-1">

        <List component="nav" className="OrderTypeRadioContainer">
          <ListItem button>
            <Grid container>
              <Grid item xs={12}>
                <Box className="order-type-title" fontSize={25} textAlign="center" marginTop={1}>
                  Choose Order Type
                </Box>
                <RadioGroup value={orderType} color="primary" onChange={orderTypeHandler}>

                  <Box marginTop={1} display="flex" justifyContent="space-between">


                    {
                      (otherProps.unavailableOrderType === 'Collection' || otherProps.unavailableOrderType === '')
                      && (
                        <Box>
                          <FormControlLabel
                            className="order-type-radio"
                            value="Delivery"
                            control={<Radio color="primary" />}
                            label="Delivery"
                          />
                        </Box>
                      )
                    }

                    {
                      (otherProps.unavailableOrderType === 'Delivery' || otherProps.unavailableOrderType === '')

                      && (
                        <Box>
                          <FormControlLabel
                            className="order-type-radio"
                            value="Collection"
                            control={<Radio color="primary" />}
                            label="Collection"
                          />
                        </Box>
                      )
                    }


                  </Box>
                </RadioGroup>

              </Grid>
            </Grid>
          </ListItem>
        </List>
      </Paper>

      <Paper>
        <List component="nav">
          {
            otherProps.cart.items.map((itm, index) => <CartItem2 key={`${itm.id}_${index}`} item={itm} index={index} />)
          }

          {
            otherProps.cart.set_menus.map((itm, index) => (
              <SetMenuCartItem key={itm.id} item={itm} index={index} />
            ))
          }

          <ListItem>
            <Divider />
          </ListItem>

          <Box display="flex" justifyContent="space-between" width="100%" border={1} className="total-div">
            <Box className="total-text">Total</Box>
            <Box className="total-price">
              <Hidden mdUp>
                {CURRENCY_SYMBOL}
                {total}
              </Hidden>

              <Hidden lgDown>

                {CURRENCY_SYMBOL}
                <NumberAnimation
                  number={total}
                  decimal={2}
                  component="span"
                />
              </Hidden>

            </Box>
          </Box>

        </List>
      </Paper>


      <Paper className="bg-light mb-1">
        {
          (!otherProps.isOffDay && !otherProps.isClosed && setting.order_status)
          && (
            <Box>
              <TextField
                type="text"
                variant="outlined"
                margin="dense"
                fullWidth
                name="note"
                label="Any Order Instruction?"
                id="note"
                autoComplete="off"
                inputProps={{
                  maxLength: 100
                }}
              />
              <Button
                type="button"
                variant="contained"
                color="primary"
                sx={cartBtnStyle.root}
                onClick={() => ItemSubmitHandler()}
                style={{ padding: 10 }}
              >
                Continue
              </Button>
            </Box>
          )
        }
        {
          otherProps.isOffDay
          && (
            <Button
              variant="outlined"
              color="primary"
              sx={cartBtnStyle.root}
              disabled
            >
              Today we're closed
            </Button>
          )
        }
        {
          otherProps.isClosed
          && (
            <Button
              variant="contained"
              color="primary"
              sx={cartBtnStyle.root}
              disabled
            >
              {'We\'re closed now.'}
              {/*{`Restaurant has closed now. You can Order for ${opensDay}`}*/}
            </Button>
          )
        }
        {
          !setting.order_status
          && (
            <Button variant="outlined" color="primary" sx={cartBtnStyle.root} disabled>
              {
                setting.order_disable_message
              }
            </Button>
          )
        }

      </Paper>

    </Div>
  );
}

const mapStateToProps = (state) => ({
  cart: state.cart,
  isOffDay: state.opening.isOffDay,
  isClosed: state.opening.isClosed,
  orderType: state.cart.order.type,
  opening: state.opening.data,
  note: state.cart.order.note,
  unavailableOrderType: state.opening.unavailableOrderType,
});

const mapDispatchToProps = (dispatch) => ({
  cartAddItem: (item) => dispatch(cartItemAdd(item)),
  cartRemoveItem: (item) => dispatch(cartItemRemove(item)),
  cartUpdateItemAddon: (item, addons) => dispatch(cartItemAddonsUpdate(item, addons)),
  cartOrderTypeUpdate: (type) => dispatch(cartOrderTypeUpdate(type)),
  cartOrderNoteUpdate: (note) => dispatch(cartOrderNoteUpdate(note)),
  cartDiscountUpdate: (discount) => dispatch(cartDiscountUpdate(discount)),
  clearCoupon: () => dispatch(clearCoupon()),
  clearDiscount: () => dispatch(clearDiscount()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Cart2);
