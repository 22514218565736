import axios from 'axios';
import { API_BASE_URL, APP_TIMEZONE, AUTH_TOKEN_NAME } from './constants';
import * as dateFns from 'date-fns';
import * as dateFnsTz from 'date-fns-tz';
import store from '../store';
import _ from 'lodash';
import { updateIsRestaurantClosed, updateOpeningHoursOpenState } from '../store/opening/openingActions';
import { updateRequireAuth } from '../store/user/userActions';
import WS from './ws';


export const DateFns = dateFns;
export const DateFnsTz = dateFnsTz;

export const getThemeStaticAsset = (assetPath) => `/static/theme/${assetPath}`;

export const apiRequest = axios.create({

  baseURL: API_BASE_URL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'app': 'web',
  },
  withCredentials: true,
});

export const EchoInstance = WS.getInstance();


apiRequest.interceptors.request.use((config) => {

  // add access token to the request
  const accessToken = getFromLocalStorage(AUTH_TOKEN_NAME, false);
  config.headers.common['Authorization'] = `Bearer ${accessToken}`;

  return config;
});

apiRequest.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);


const responseHandler = (response) => response;

const errorHandler = async (error) => {
  if (error.response.status === 419) {
    store.dispatch(updateRequireAuth(true));
  }
  return Promise.reject(error);
};

export const moneyFormat = (amount, fraction = 2) => parseFloat(amount.toFixed(fraction));

export const getDateFormat = (separator = '-') => `yyyy${separator}MM${separator}dd`;
export const getTimeFormat = (capitalAmPm = false) => `hh:mm ${capitalAmPm ? 'aa' : 'aaa'}`;

export const formatDateTime = (dateTime, format = '') => {
  const date = typeof dateTime === 'object' ? dateTime : new Date(dateTime);
  const dateFormat = format.length ? format : getDateFormat();

  return dateFns.format(date, dateFormat);
}

/**
 * Parse str using format & formats that
 * @param dateTime : string|Date
 * @param inputFormat : string
 * @param outFormat : string
 * @returns {string}
 */
export const formatDTime = (dateTime, inputFormat, outFormat = '') => {
  const date = dateTime instanceof Date ? dateTime : parseDTime(dateTime, inputFormat);
  const dateFormat = outFormat.length ? outFormat : getDateFormat();

  return dateFns.format(date, dateFormat);
}

export const mergeDateTime = (dateObj, timeObj) => {
  let timestampWithDate = dateFns.setHours(dateObj, timeObj.getHours())
    .setMinutes(timeObj.getMinutes(), timeObj.getSeconds());
return dateFns.toDate(timestampWithDate);
};

export function getTodaysTimeFromTimeString(timeString, getTimeStamp = false, timeSeparator = ':') {

  const timeObj = makeTimeFromTimeString(timeString, timeSeparator);

  if (getTimeStamp) {
    return timeObj.getTime();
  }

  const timeObjWithZone = applyTimeZone(dateFns.toDate(timeObj), APP_TIMEZONE);

  return timeObjWithZone;
}

/**
 * Converts time string (11:30:20) to Date object
 * @param timeString | String
 * @param timeSeparator | String
 * @returns {Date} | Date
 */
export function makeTimeFromTimeString(timeString, timeSeparator = ':') {
  if (typeof timeString !== 'string') {
    throw new Error(`Time string must be of type string, given: ${typeof timeSeparator}`);
  }

  const [hour, minute, second] = timeString.split(timeSeparator).map((itm) => parseInt(itm));

  let timestamp = dateFns.setHours(new Date(), hour).setMinutes(minute, second || 0);

  return dateFns.toDate(timestamp);
}

export function applyTimeZone(dateObj, timezone = APP_TIMEZONE) {

  if (!(dateObj instanceof Date)) {
    throw new Error(`'dateObj' must be instance of 'Date', type of '${typeof dateObj}' given`);
  }

  return dateFnsTz.utcToZonedTime(dateObj, timezone);
}

export function formatWithTimeZone(dateObj, format, timeZone = APP_TIMEZONE) {

  if (!(dateObj instanceof Date)) {
    throw new Error(`'dateObj' must be instance of 'Date', type of '${typeof dateObj}' given`);
  }

  if (typeof format !== 'string') {
    throw new Error(`'format' must be a valid format string, type of '${typeof format}' given`);
  }

  const timeWithTimeZone = applyTimeZone(dateObj, timeZone);

  return dateFnsTz.format(timeWithTimeZone, format, { timeZone });

}

export function isRestaurantClosed(hours) {

  const [toHour, toMinute] = hours.to.split(':');

  const hoursDetails = dateFns.setHours(dateFns.setMinutes(applyTimeZone(new Date()), toMinute), toHour);

  const currentDate = applyTimeZone(new Date());

  if (currentDate.getTime() > hoursDetails.getTime()) {
    store.dispatch(updateIsRestaurantClosed(true));
  } else {
    store.dispatch(updateIsRestaurantClosed(false));
  }
}

export function getTimeFormatFromTimeString(timeString, format = '', applyTimezone = true) {

  let timeObj = makeTimeFromTimeString(timeString);

  if (applyTimezone) {
    timeObj = applyTimeZone(timeObj, APP_TIMEZONE);
  }

  const finalFormat = format.length ? format : getTimeFormat();

  return dateFns.format(timeObj, finalFormat);
}

export function getCurrentClosingTime(selectedDaysHours) {
  const exactTime = selectedDaysHours.map((hours) => {
    const [fromHour, fromMin] = hours.from.split(':');
    const [toHour, toMin] = hours.to.split(':');

    const fromTimeObj = dateFns.setHours(dateFns.setMinutes(applyTimeZone(new Date()), fromMin), fromHour);
    const toTimeObj = dateFns.setHours(dateFns.setMinutes(applyTimeZone(new Date()), toMin), toHour);
    const currentTime = applyTimeZone(new Date());
    if (currentTime.getTime() >= fromTimeObj && currentTime.getTime() <= toTimeObj) {
      return toTimeObj;
    }
    return false;
  });
  return exactTime;
}

export const getValidOpeningStatus = (hours) => {

  // don't try to calculate if hours unavailable
  if (_.isEmpty(hours)) return false;

  const currentTimestamp = applyTimeZone(new Date(), APP_TIMEZONE).getTime();

  // convert timings to timestamps
  const validTimings = hours.filter((hour) => {
    const fromTimestamp = getTodaysTimeFromTimeString(hour.from, true);
    const toTimestamp = getTodaysTimeFromTimeString(hour.to, true);
    return ((currentTimestamp >= fromTimestamp) && (currentTimestamp <= toTimestamp));
  });

  const openingState = !!validTimings.length;


  // update store
  store.dispatch(updateOpeningHoursOpenState(openingState));

  return openingState;
}

export const convertTimeTo12Hour = (hours) => {

  let convertedHour;

  if (parseInt(hours) === 24 || parseInt(hours) === 0) {
    convertedHour = hours;
  }
  if (parseInt(hours) > 12) {
    convertedHour = parseInt(hours) - 12;
  } else {
    convertedHour = parseInt(hours);
  }
  // console.log('convert hour', convertedHour);
  return convertedHour.toString();
}


/***
 *
 * Use when time string in ISO format
 * Used in showing time in Order History Page exm: 10:30 AM
 * @param timeString : String
 * @param [format = do MMM, yyyy] : String
 * @param [timeZone = null]: String
 * @returns String
 *
 */

export const formatISODate = (timeString, format = 'do MMM, yyyy', timeZone = null) => {

  let timeDate = dateFns.parseJSON(timeString);

  if (timeZone !== null) {
    timeDate = DateFnsTz.utcToZonedTime(timeDate, timeZone);
  }

  return dateFns.format(timeDate, format);
};


/**
 *
 * @param {String} key
 * @param {Boolean} decode
 * @returns {any}
 */
export function getFromLocalStorage(key, decode = true) {

  const value = window.localStorage.getItem(key);

  return decode
    ? JSON.parse(value)
    : value;
}

/**
 *
 * @param {String} key
 * @param {Boolean} decode
 * @returns {any}
 */
export function pullFromLocalStorage(key, decode = true) {

  const value = getFromLocalStorage(key, decode);

  window.localStorage.removeItem(key);

  return value;
}

/**
 *
 * @param {String} key
 * @param {any} value
 * @param {Boolean} encode
 * @returns {any}
 */
export function putToLocalStorage(key, value, encode = true) {

  const finalValue = encode
    ? JSON.stringify(value)
    : value;
window.localStorage.setItem(key, finalValue);

  return finalValue;
}

/**
 *
 * @param parseStr : string
 * @param format : string
 * @param baseDate : Date
 * @returns {Date}
 */
export function parseDTime(parseStr, format, baseDate = new Date()) {
  return dateFns.parse(parseStr, format, baseDate);
}

/**
 * Scrolls to specific element or top if element not provided
 * @param scrollToElement : HTMLElement
 * @param useBringInView : boolean
 */
export function scrollToPos(scrollToElement, useBringInView = false) {

  // if element provided scroll to that
  if (scrollToElement instanceof HTMLElement) {

    if (useBringInView) {
      scrollToElement.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'center'
      });
      return;
    }

    const y = scrollToElement.getBoundingClientRect().top + window.scrollY;
    window.scroll({
      top: y,
      behavior: 'smooth'
    });
  }

  // or just go to top
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
}
