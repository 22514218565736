import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PopupMobile from '../util/popupMobile';
import { Box, Button } from '@mui/material';
import AddToCartAddons from './addToCartAddons';
import { connect } from 'react-redux';
import { cartItemAdd, cartItemAddonsUpdate } from '../../store/cart/cartActions';
import useNotify from '../../hooks/useNotify';
import ModalComp from '../util/Modal/ModalComp';
import AddSetMenu from './addSetMenu';
import { isMobile } from 'react-device-detect';
import PopupDesktop from '../util/popupDesktop';


function CartItemAddonsPopup({
  item, index, updating = false, openCallback, closeCallback, ...otherProps
}) {

  const [open, setOpen] = useState(false);

  const [selectedAddons, setSelectedAddons] = useState([])

  const [notify] = useNotify();

  const [showSetMenu, setShowSetMenu] = useState(false);

  // execute open & close callback
  useEffect(() => {
    if (open && typeof openCallback === 'function') {
      openCallback();
    }

    if (!open && typeof closeCallback === 'function') {
      closeCallback();
    }
  }, [open]);

  useEffect(() => {
    setShowSetMenu(false);
  }, [otherProps.setMenus]);


  const addToCartItemHandler = () => {

    if (!updating) {
      otherProps.cartAddItem(item, selectedAddons, updating, index);
      notify.success('Item Added Successfully');
    } else {
      otherProps.cartAddItem(item, selectedAddons, updating, index);
      notify.success('Addon updated');
    }

    //close popup
    setOpen(false);
    setSelectedAddons([]);
  };

  const handleCloseSetMenuModal = () => {
    setShowSetMenu(false);
  }

  const showAddonsHandler = () => {

    if (otherProps.isSetMenuSelected) {
      setShowSetMenu(true);
      return;
    }

    // don't show addons popup if there's no addon
    if (!item.addons?.length) {
      // add the item to cart
      addToCartItemHandler();
      return;
    }

    // open addons
    setOpen(true);
  };

  return (
    <>
      {/*popup show element*/}
      {
        otherProps.children(showAddonsHandler)
      }

      {
        !isMobile
        && (
          <PopupDesktop
            item={item}
            updating={updating}
            selectHandler={setSelectedAddons}
            addToCartHandler={addToCartItemHandler}
            open={open}
            openHandler={setOpen}
            title="Select Addons"
          />
        )
      }

      {
        isMobile
        && (
          <PopupMobile open={open} openHandler={setOpen} title="Select Addons">
            <Box>
              <AddToCartAddons
                item={item}
                updating={updating}
                selectHandler={setSelectedAddons}
              />
            </Box>
            <Box display="flex" justifyContent="center">
              <Button
                color="primary"
                variant="contained"
                onClick={addToCartItemHandler}
                disabled={otherProps.isOffDay}
              >
                { otherProps.isOffDay ? 'Today we\'re closed' : '' }
                { !otherProps.isOffDay && updating ? 'Update Item' : 'Add To Cart' }
              </Button>
            </Box>
          </PopupMobile>
        )
      }


      <ModalComp
        title={item.name}
        maxDesktopWidth="50vw"
        open={showSetMenu}
        hideFooter
        closeHandler={handleCloseSetMenuModal}
        closeOnBackdropClick
        disableFocusEnforcement
      >

        <AddSetMenu item={item} />
      </ModalComp>

    </>
  );
}

CartItemAddonsPopup.propTypes = {
  item: PropTypes.object.isRequired,
  updating: PropTypes.bool,
  children: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isOffDay: state.opening.isOffDay,
  isSetMenuSelected: state.menu.isSetMenuSelected,
  setMenus: state.cart.set_menus,
});

const mapDispatchToProps = (dispatch) => ({
  cartAddItem: (item, selectedAddon, isUpdate, index) => dispatch(cartItemAdd(item, selectedAddon, isUpdate, index)),
  cartUpdateItemAddon: (item, addons) => dispatch(cartItemAddonsUpdate(item, addons)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CartItemAddonsPopup);
