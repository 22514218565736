import React from 'react';
import PageHeader from '../../components/pageHeader/pageHeader';
import { connect } from 'react-redux';

function Points() {

  return (
    <>

      <PageHeader pageName="Points" bgClass="breadcrumb-bg-services" />

      {/* features-4 */}

      <section className="grids-1 py-5">
        <div className="grids py-lg-5 py-md-4">
          <div className="container">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h1 className="text-center">Points Content Implement soon.</h1>


            </div>

          </div>
        </div>
      </section>


    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,

});

export default connect(mapStateToProps)(Points);
