import React from 'react';
import {
 Box, Divider, Grid, ListItem
} from '@mui/material';
import { connect } from 'react-redux';
import CartItemMenu from './cartItemMenu';
import { CURRENCY_SYMBOL } from '../../util/constants';


function CartItem({ item, index, ...otherProps }) {

  const cartItemAddonStyle = {
    root: {
      backgroundColor: '#f8e4e8'
    }
  };

  const addons = () => item?.selected_addon.map((addon, index) => (

    <Box
      key={`${addon.id}_${index}`}
      display="flex"
      justifyContent="space-between"
      sx={cartItemAddonStyle.root}
    >
      <Box style={{ fontSize: 15 }}>{addon.name}</Box>
      <Box>
        {CURRENCY_SYMBOL}
        {(addon.price * item.qty).toFixed(2)}
      </Box>
    </Box>
    ));

  return (
    <ListItem>
      <Grid container>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between">
            <Box style={{ fontSize: 17 }}>{item.name}</Box>
            <Box display="flex">
              <Box mr={1}>
                <CartItemMenu item={item} index={index} qty={item.qty} />
              </Box>
              <Box>
                {CURRENCY_SYMBOL}
                {
                  (item.price * item.qty).toFixed(2)
                }
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {
            addons()
          }
        </Grid>
      </Grid>
      <Divider />
    </ListItem>
  );
}

const mapStateToProps = (state) => ({
  addons: state.cart.addons
});

export default connect(mapStateToProps)(CartItem);
