import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import React from 'react';
import { isMobile } from 'react-device-detect';

export default function NavItem({ name, path, mobileMenuToggleRef }) {

  const { pathname } = useLocation();
  const activeClass = (pathname === path) ? 'active' : '';

  const closeMenuHandler = () => {
    if (isMobile) {
      mobileMenuToggleRef?.current?.click();
    }
  };

  return (
    <li
      className={`nav-item ${activeClass}`}
      onClick={closeMenuHandler}
    >
      <Link to={path} className="nav-link">{name}</Link>
    </li>
  );
}

NavItem.propTypes = {
  name: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired
};
