import { useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as dateFns from 'date-fns';
import './TimePicker.scss';
import { Backdrop, Box } from '@mui/material';
import { applyTimeZone, getTimeFormat, mergeDateTime } from '../../../util/util';
import useSetting from '../../../hooks/useSetting';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';



function TimePicker({
  hours,
  date,
  minuteStep = 10,
  format = getTimeFormat(),
  open = false,
  closeHandler,
  changeHandler,
  defaultValue = null,
  allowClearing = false,
  inputClassName = '',
  inputIcon = <></>,
  disabledHours = [],
  ...otherProps
}) {

  const styles = {
    backdrop: {
      // eslint-disable-next-line no-unsafe-optional-chaining
      zIndex: (theme) => theme.zIndex?.drawer + 1,
      color: '#fff',
      position: 'absolute',
    },
    buttons: {
      marginTop: '10px',
      textAlign: 'end'
    },
    theInput: {
      display: 'none'
    }
  };
  const setting = useSetting(['collection_order_delivery_time', 'delivery_order_delivery_time']);

  const [time, setTime] = useState(() => {

    if (defaultValue instanceof Date) {
      return defaultValue;
    }

    return _.isEmpty(defaultValue) ? applyTimeZone(new Date()) : applyTimeZone(new Date(defaultValue));
  });


  const timingSetting = useSetting([
    'full_time_order_start',
    'full_time_order_end',
    'delivery_order_delivery_time',
    'collection_order_delivery_time',
  ]);

  const openingDetails = useMemo(() => {

    const hoursDetails = hours.map((hour) => {
      // get hours & minutes from str
      const [fromHour, fromMinute] = hour.from.split(':');
      const [toHour, toMinute] = hour.to.split(':');


      const hoursDetails = {
        from: dateFns.setHours(dateFns.setMinutes(applyTimeZone(new Date()), fromMinute), fromHour),
        to: dateFns.setHours(dateFns.setMinutes(applyTimeZone(new Date()), toMinute), toHour)
      };


      hoursDetails.from = mergeDateTime(date, hoursDetails.from);
      hoursDetails.to = mergeDateTime(date, hoursDetails.to);

      if (otherProps.orderType === 'Delivery') {
        if (!timingSetting.full_time_order_start) {
          hoursDetails.from = dateFns.addMinutes(
hoursDetails.from,
            timingSetting.delivery_order_delivery_time
);
        }
        if (!timingSetting.full_time_order_end) {
          hoursDetails.to = dateFns.subMinutes(
hoursDetails.to,
            timingSetting.delivery_order_delivery_time
);
        }
      }

      if (otherProps.orderType === 'Collection') {
        if (!timingSetting.full_time_order_start) {
          hoursDetails.from = dateFns.addMinutes(
hoursDetails.from,
            timingSetting.collection_order_delivery_time
);
        }
        if (!timingSetting.full_time_order_end) {
          hoursDetails.to = dateFns.subMinutes(
hoursDetails.to,
            timingSetting.collection_order_delivery_time
);
        }
      }


      return hoursDetails;
    });


    return hoursDetails;

  }, [hours, date, timingSetting]);


  const preCloseHandler = (time) => {

    // merge time & date
    let timeWithDate = mergeDateTime(date, time);

    setTime(timeWithDate);
    changeHandler(timeWithDate);
    closeHandler();
  };

  const getStartTime = (definedStartTime) => {

    const isToday = dateFns.isToday(definedStartTime);

    const bufferMinutes = (otherProps.orderType.toLowerCase() === 'delivery')
      ? setting.delivery_order_delivery_time
      : setting.collection_order_delivery_time
    ;

    // add buffer time
    let newStartTime = definedStartTime;

    if (isToday) {
      const currentTimeIncludingBuffer = dateFns.addMinutes(applyTimeZone(new Date()), bufferMinutes);

      // if current time is greater than opening time
      if (currentTimeIncludingBuffer.getTime() > newStartTime.getTime()) {
        newStartTime = currentTimeIncludingBuffer;
      }
    }

    return newStartTime;
  };

    const filterTime = (time) => { // false = disable

      const currentDate = applyTimeZone(new Date());
      const selectedDate = mergeDateTime(date, time);

      // don't allow past time
      if (selectedDate.getTime() < currentDate.getTime()) return false;

      // check if the time is a valid opening time
      const shouldOpen = openingDetails.map((openingTime) => {
        let from = getStartTime(openingTime.from).getTime();
        const to = openingTime.to.getTime();

        return selectedDate.getTime() >= from && selectedDate.getTime() <= to;
      });

      // check if time is valid among multiple opening times
      const validHours = shouldOpen.filter((time) => time);

      // if any opening time is valid then the time is valid
      return !!(validHours.length);
    };




  return (
    <Backdrop sx={styles.backdrop} open={open}>
      <Box>
        <DatePicker
          inline
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={minuteStep}
          timeCaption="Select Time"
          dateFormat={format}
          filterTime={filterTime}
          selected={time}
          onChange={preCloseHandler}
        />
      </Box>
    </Backdrop>
  );

}

TimePicker.propTypes = {
  hours: PropTypes.arrayOf(PropTypes.object),
  date: PropTypes.instanceOf(Date),
  open: PropTypes.bool,
  closeHandler: PropTypes.func,
  defaultValue: PropTypes.oneOfType([PropTypes.instanceOf(Date)]),
  className: PropTypes.string,
  inputIcon: PropTypes.elementType,
  changeHandler: PropTypes.func,
  format: PropTypes.string,
  minuteStep: PropTypes.number,
  allowClearing: PropTypes.bool,
  disabledHours: PropTypes.arrayOf(PropTypes.number)
};

const mapStateToProps = (state) => ({
  orderType: state.cart.order.type,
  openingTime: state.opening.todaysTiming,
});

export default connect(mapStateToProps)(TimePicker);
