import React, { useEffect, useReducer, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  TextField,
  Alert,
  CircularProgress,
  Checkbox,
  Avatar,
  Button,
  CssBaseline,
  FormControlLabel,
  Paper,
  Box,
  Grid,
  Typography,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { connect } from 'react-redux';
import { footerShowHide, headerShowHide } from '../../store/layout/layoutActions';
import { login } from '../../store/user/userActions';
import routes from '../../util/routes';
import Copyright from '../../components/util/copyright';
import useRedirectIfAuthenticated from '../../hooks/useRedirectIfAuthenticated';
import useRedirectToIntended from '../../hooks/useRedirectToIntended';
import loginImage from '../../assets/images/login.jpg';
import '../../assets/css/custom.scss';
import SlideAnimation from '../../components/util/Animations/SlideAnimation';


const errorTypes = {
  SUBMIT: 'SUBMIT',
  SUBMIT_SUCCESS: 'SUBMIT_SUCCESS',
  SUBMIT_FAIL: 'SUBMIT_FAIL',
  FIELD_ERROR: 'FIELD_ERROR',
};

const errorDefaultState = {
  loading: false,
  error: false,
  success: false,
  message: '',
  fields: {
    email: false,
    password: false,
    remember: false
  }
};

function errorReducer(state, { type, payload }) {
  switch (type) {
    case errorTypes.SUBMIT:
      return {
        ...state,
        loading: true
      }

    case errorTypes.SUBMIT_SUCCESS:
      return {
        ...state,
        error: false,
        success: true,
        loading: false,
        message: payload
      }

    case errorTypes.SUBMIT_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
        message: (payload) || 'Something went wrong, please try again later'
      }

    case errorTypes.FIELD_ERROR:
      return {
        ...state,
        fields: {
          ...state.fields,
          ...payload
        }
      }

    default:
      return state;
  }
}

function Login(props, { ...otherProps }) {

  // redirect users if they already authenticated
  useRedirectIfAuthenticated();

  // redirect to the page they wanted to go before interception
  useRedirectToIntended();

  const styles = {
    root: {
      height: '100vh',
    },
    image: {
      backgroundImage: `url('${loginImage}')`,
      backgroundRepeat: 'no-repeat',
      backgroundColor:
        (theme) => (theme.palette.mode === 'light' ? (theme) => theme.palette.grey[50] : (theme) => theme.palette.grey[900]),
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    paper: {
      margin: (theme) => theme.spacing(8, 4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: (theme) => theme.spacing(1),
      backgroundColor: (theme) => theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: (theme) => theme.spacing(1),
    },
    submit: {
      margin: (theme) => theme.spacing(3, 0, 2),
    },
  };

  // hide/show header footer
  useEffect(() => {

    // hiding header footer
    toggleHeaderFooter(false);

    // showing header footer
    return () => toggleHeaderFooter(true);

  }, []);

  const toggleHeaderFooter = (show) => {
    props.showHideHeader(show);
    props.showHideFooter(show);
  }

  const [isloading, setIsLoading] = useState(false);

  // handle form inputs
  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
    remember: false,
  });


  const rememberChangeHandler = (e) => {
    setCredentials({
      ...credentials,
      remember: e.target.checked
    });
  };

  const [email, setEmail] = useState('');
  const emailChangeHandler = (e) => {
    setEmail(e.target.value);
  }

  const loginChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCredentials({
      ...credentials,
      [name]: value
    });

  };

  const submitHandler = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    await props.login(credentials.email, credentials.password, credentials.remember);
    setIsLoading(false);
  };


  return (

    <SlideAnimation in>
      <>
        <Grid container component="main" sx={styles.root}>
          <CssBaseline />
          <Grid item sm={false} lg={8} sx={styles.image} />
          <Grid item xs={12} sm={12} md={6} lg={4} className="login-form-center" component={Paper} elevation={6} square>
            <Box sx={styles.paper}>

              <Avatar sx={styles.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Log in
              </Typography>

              {
                props.url.isRedirected
                && <Alert severity="warning" variant="standard">Please login to continue</Alert>
              }
              {
                props.errors?.email
                && <Alert severity="error" variant="standard">{props.errors.email[0]}</Alert>
              }

              <Box component="form" sx={styles.form} noValidate onSubmit={submitHandler}>
                <TextField
                  type="text"
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  name="email"
                  id="email"
                  label="Email Address"
                  value={credentials.email}
                  onChange={loginChangeHandler}
                />

                <TextField
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  name="password"
                  id="password"
                  label="Password"
                  type="password"
                  value={credentials.password}
                  onChange={loginChangeHandler}
                />
                <FormControlLabel
                  control={(
                    <Checkbox
                      value="remember"
                      color="primary"
                      checked={credentials.remember}
                      onChange={rememberChangeHandler}
                    />
                  )}
                  label="Remember me"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={styles.submit}
                >
                  Sign In
                  {
                    isloading
                    && <CircularProgress color="info" size={25} style={{ marginLeft: 7 }} />
                  }
                </Button>

                <Grid container>
                  <Grid item xs>
                    <RouterLink to={routes.forgetPassword}>
                      Forgot password?
                    </RouterLink>
                  </Grid>
                  <Grid item>
                    <RouterLink to={routes.signup}>
                      Don't have an account? Sign Up
                    </RouterLink>
                  </Grid>
                </Grid>

                <Box>
                  <Box display="flex" justifyContent="center" my={4}>
                    <Typography component="h1" variant="h5">OR</Typography>
                  </Box>
                  <Box display="flex" justifyContent="center">
                    <RouterLink to={routes.home}>
                      <Button variant="outlined" color="primary">Back to Home</Button>
                    </RouterLink>
                  </Box>
                </Box>

                <Box mt={5}>
                  <Copyright />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </>
    </SlideAnimation>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.user.isLoggedIn,
  user: state.user.data,
  errors: state.user.errors,
  url: state.url
});

const mapDispatchToProps = (dispatch) => ({
  showHideHeader: (shouldShow) => dispatch(headerShowHide(shouldShow)),
  showHideFooter: (shouldShow) => dispatch(footerShowHide(shouldShow)),
  login: (email, password, remember) => dispatch(login(email, password, remember))
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
