import { useState } from 'react';
import { connect } from 'react-redux';
import styles from '../menu/allMenu.module.scss';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CartItemAddonsPopup from './cartItemAddonsPopup';
import { useSpring, animated } from 'react-spring';
import useNotify from '../../hooks/useNotify';
import useSetting from '../../hooks/useSetting';

function AddToCart({ item, ...otherProps }) {

  const [notify] = useNotify();

  const orderSetting = useSetting([
    'order_status',
  ]);

  const [hoverState, setHoverState] = useState(false);

  const animationProps = useSpring({
    transform: hoverState
      ? 'scale(1.1,1.1)'
      : 'scale(1,1)',
    // transition: `transform ${50}ms`,
  });
  const AnimeIcon = animated(AddCircleOutlineIcon);

  const animationStart = () => {
    setHoverState(true);
  };

  const animationStop = () => {
    setHoverState(false);
  };

  const openAddonsPopupHandler = (cb) => {
    if (otherProps.isOffDay) {
      notify.info('Today we\'re closed');
      return;
    }

    if (!orderSetting.order_status) {
      notify.info('Currently online order is unabailable');
      return;
    }


    cb();
  };

  return (
    <CartItemAddonsPopup
      item={item}
    >
      {
        (openAddonsPopup) => (
          <AnimeIcon
            onMouseEnter={animationStart}
            onMouseLeave={animationStop}
            onClick={() => openAddonsPopupHandler(openAddonsPopup)}
            className={styles.AddItemToCart}
            style={animationProps}
          />
        )
      }
    </CartItemAddonsPopup>
  );
}

const mapStateToProps = (state) => ({
  isOffDay: state.opening.isOffDay
});

export default connect(mapStateToProps)(AddToCart);
