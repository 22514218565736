import React, {
 useEffect, useMemo, useReducer, useState
} from 'react';
import * as dateFns from 'date-fns';
import { connect } from 'react-redux';
import _ from 'lodash';
import TimePicker from '../util/TimePicker/TimePicker';
import {
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  CircularProgress,
  Box,
} from '@mui/material';
import {
  apiRequest,
  applyTimeZone,
  getCurrentClosingTime,
  getDateFormat,
  getTimeFormat,
  getTodaysTimeFromTimeString, getValidOpeningStatus,
  mergeDateTime
} from '../../util/util';
import { formSingleValidator, formValidator, validationRules } from '../../util/formValidation';
import useNotify from '../../hooks/useNotify';
import useSetting from '../../hooks/useSetting';
import {
 API_ADD_RESERVATON, APP_TIMEZONE, RESERVATION_BUFFER_MIN, SETTING_FILE_PATH
} from '../../util/constants';
import '../../assets/css/custom.scss';
import { DatePicker, MobileTimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import store from '../../store';
import { updateOpeningHoursOpenState } from '../../store/opening/openingActions';


const errorTypes = {
  SUBMIT: 'SUBMIT',
  SUBMIT_SUCCESS: 'SUBMIT_SUCCESS',
  SUBMIT_FAIL: 'SUBMIT_FAIL',
  FIELD_ERROR: 'FIELD_ERROR',
};

const defaultReservationFields = {
  name: '',
  email: '',
  persons: '',
  contact: '',
  bookingDate: '',
  bookingTime: '',
  requirements: '',
  heardOn: '',
}

const errorDefaultState = {
  loading: false,
  error: false,
  success: false,
  message: '',
  fields: {
    name: false,
    email: false,
    persons: false,
    contact: false,
    bookingDate: false,
    bookingTime: false,
    heardOn: false,
  }
};


//handle address fields minimum errors
function errorReducer(state, { type, payload }) {
  switch (type) {
    case errorTypes.SUBMIT:
      return {
        ...state,
        loading: true
      }

    case errorTypes.SUBMIT_SUCCESS:
      return {
        ...state,
        error: false,
        success: true,
        loading: false,
        message: payload
      }

    case errorTypes.SUBMIT_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
        message: (payload) || 'Something went wrong, please try again later'
      }

    case errorTypes.FIELD_ERROR:
      return {
        ...state,
        fields: {
          ...state.fields,
          ...payload
        }
      }

    default:
      return state;
  }
}


function ReservationComponent({ ...otherProps }) {


  const styles = {
    circularProgress: {
      color: 'primary',
      size: '4rem',
      thickness: 1.6,
    },
  };

  const reservationSetting = useSetting([
    'reservation_img',
    'reservation_status',
    'reservation_disable_message'
  ]);

  const [disable, setDisable] = useState(false);

  const [reservationBtnText, setReservationBtnText] = useState('Reservation');
  const [reservationFormState, reservationFormHandler] = useState({
    name: '',
    email: '',
    persons: '',
    contact: '',
    bookingDate: '',
    bookingTime: '',
    requirements: '',
    heardOn: '',
  });

  const [errorState, errorDispatch] = useReducer(errorReducer, errorDefaultState);


  useEffect(() => {

    if (otherProps.user.data.name === '') return;

    reservationFormHandler({
      ...reservationFormState,
      name: otherProps.user.data.name,
      contact: otherProps.user.data.phone,
      email: otherProps.user.data.email,
    });


  }, [otherProps.user]);


  //notifaction hooks
  const [notify] = useNotify();

  const validationSchema = {
    name: [
      validationRules.required(),
      validationRules.containOnlyAlphabet(),
      validationRules.min([6]),
      validationRules.max([30])
    ],
    email: [
      validationRules.required(),
      validationRules.email(),
    ],
    contact: [
      validationRules.required(),
      validationRules.containOnlyNumber(),
    ],
    persons: [
      validationRules.required(),
      validationRules.containOnlyNumber(),
      validationRules.persons(),
    ],
    requirements: [
      validationRules.min([8]),
      validationRules.max([50])
    ],
    heardOn: [
      validationRules.string(),
      validationRules.max([50])
    ]
  };

  const formHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    reservationFormHandler({
      ...reservationFormState,
      [name]: value
    });


    // handle field errors in realtime
    _.debounce(async () => {

      let validationErrors = await formSingleValidator({
        [name]: value
      }, validationSchema);


      errorDispatch({
        type: errorTypes.FIELD_ERROR,
        payload: validationErrors
      });
    }, 500)();
  }

  useEffect(() => {
    if (reservationFormState.name
      && reservationFormState.contact
      && reservationFormState.persons
      && reservationFormState.email
    ) {
      setReservationBtnText('Submit');
    }
  }, [reservationFormState]);

  const [loading, setLoading] = useState(false);

  const [bufferMin] = useState(RESERVATION_BUFFER_MIN);

  const isRestaurantOpen = useMemo(
    () => getValidOpeningStatus(otherProps.todayOpening.hours),
    [otherProps.todayOpening.hours]
  );

  const [othersShow, setOthersShow] = useState(false);

  const [promotionalText, setPromotionalText] = useState('');

  const [hideTimePicker, setHideTimePicker] = useState(false);


  const [imageLoader, setImageLoader] = useState(true);


  const [timePickerOpen, setTimePickerOpen] = useState(false);

  const [selectedDate, setSelectedDate] = useState(() => (otherProps.date.length ? applyTimeZone(new Date(otherProps.date)) : applyTimeZone(new Date())));

  const selectedDaysHours = useMemo(() => {

    const selectedDayName = dateFns.format(selectedDate, 'EEE').toLowerCase();

    // set selected days hours
    const selectedDay = otherProps.opening.find((itm) => itm.day === selectedDayName);

    const hours = selectedDay ? selectedDay.hours : [{ from: '00:00:00', to: '23:59:59' }];

    return hours;

  }, [selectedDate, otherProps.opening]);


  const [selectedTime, setSelectedTime] = useState(() => {
    const date = otherProps.date.length ? applyTimeZone(new Date(otherProps.date)) : applyTimeZone(new Date());

    // if date time available then set that
    if (otherProps.date.length) {
      return applyTimeZone(new Date(`${date.toDateString()} ${otherProps.time}`));
    }

    return false;
  });


  const isToday = () => {
    const currentTime = applyTimeZone(new Date());
    const todayDate = `${currentTime.getFullYear()}-${currentTime.getMonth() + 1}-${currentTime.getDate()}`;
    const selectedDateValue = `${selectedDate.getFullYear()}-${selectedDate.getMonth() + 1}-${selectedDate.getDate()}`;
    return todayDate === selectedDateValue;
  }

  const today = isToday();

  useEffect(() => {

    if (otherProps.isClosed && today) {
      setHideTimePicker(true);
      notify.warning('Restaurant has closed now please pick next date to order')
    }
    if (otherProps.isOffDay) {
      setHideTimePicker(true);
      setReservationBtnText('Restaurant closed today, reserve for next available days')
    }
    if (otherProps.isOffDay && !today) {
      setHideTimePicker(false);
      setReservationBtnText('Reservation');
    }
    if (otherProps.isClosed && !today) {
      setHideTimePicker(false);
    }

  }, [otherProps.isClosed, today, otherProps.isOffDay]);

  const currentTime = applyTimeZone(new Date());

  useEffect(() => {

    const clossingTimeArray = getCurrentClosingTime(selectedDaysHours);

    const closingTime = clossingTimeArray.filter((time) => time);

    const currentTimeWithBuffer = new Date(currentTime.getTime() + parseInt(bufferMin * 60000));

    if (today) {
      if (currentTimeWithBuffer?.getTime() >= closingTime[0]?.getTime() || !isRestaurantOpen) {
        setHideTimePicker(true);
        setDisable(true);
        setReservationBtnText('Reservation has off for today, reserve for next available days');
      } else {
        setHideTimePicker(false);
        setDisable(false);
        setReservationBtnText('Reservation');
      }
    } else {
      setHideTimePicker(false);
      setDisable(false);
      setReservationBtnText('Reservation');
    }

  }, [currentTime]);

  useEffect(() => {
    if (otherProps.isOffDay) {
      setReservationBtnText('Restaurant closed today, reserve for next available days')
    }
  }, []);

  const openingDays = useMemo(() => otherProps.opening.map((itm) => itm.day), [otherProps.opening]);

  const dayDisableHandler = (day) => {

    // enable all days if opening days not set
    if (openingDays.length === 0) {
      return false;
    }

    const dayName = dateFns.format(day, 'EEE').toLowerCase();

    // only enable days that have timing set
    return !openingDays.includes(dayName);
  };

  const getSelectedTime = () => {
    if (selectedTime === false) return '';
    let time = dateFns.format(selectedTime, getTimeFormat())
    return time;
  };

  const handleOtherChoose = (event) => {
    setPromotionalText(event.target.value);
  };

  useEffect(() => {
    if (promotionalText === 'other') {
      setOthersShow(true);
    } else {
      setOthersShow(false);
    }

  }, [promotionalText]);

  const reservationSubmitHandler = async (e) => {

    e.preventDefault();

    const { error, data } = await formValidator(reservationFormState, validationSchema);

    if (error) {
      errorDispatch({
        type: errorTypes.FIELD_ERROR,
        payload: data
      });
      return;
    }

    if (reservationFormState.contact.toString().length < 7) {
      notify.warning('Contact Number should at least 7 digit');
      return;
    }

    if (!selectedTime) {
      notify.default('Please Select Reservation time');
      return;
    }

    let finalText = (promotionalText !== 'other') ? promotionalText : reservationFormState.heardOn;

    setLoading(true);
    try {

      const mergeDateAndTime = mergeDateTime(selectedDate, selectedTime);
      const reservationData = ({
        name: reservationFormState.name,
        contact: reservationFormState.contact,
        persons: reservationFormState.persons,
        email: reservationFormState.email,
        note: reservationFormState.requirements,
        datetime: dateFns.format(mergeDateAndTime, 'yyyy-MM-dd HH:mm:ss'),
        found_on: finalText,
      });


      const reservationResponse = await apiRequest.post(API_ADD_RESERVATON, reservationData);

      if (reservationResponse.data.status) {
        reservationFormHandler(defaultReservationFields);

        setLoading(false);
        notify.success('Reservation Successful. You will notified shortly');

      } else {
        setLoading(false);
        notify.error(reservationResponse.data.messages);
      }
    } catch (e) {
      setLoading(false);
      notify.error('Something wrong. Please try later');
    }
  }


  useEffect(() => {
    if (reservationSetting.reservation_img) {
     setImageLoader(false);
   } else {
      setImageLoader(true);
    }
  }, [reservationSetting])

  return (
    <>

      {/* features-4 */}
      <section className="w3l-services-6-main w3l-contact-2 reservationSection backgroundDark">
        <div className="services-6 pt-5 pb-3">
          <div className="container">

            <h2 className="text-center reservation-title" color="primary"><u> Book A Table Online </u></h2>

            <div className="row serv_sec_info pt-5 pb-5">
              <div className="col-lg-6 col-md-6 col-sm-12 banner_bottom_grid help img-fluid radius-image">
                {
                  !imageLoader
                  && (
                  <img
                    src={SETTING_FILE_PATH + reservationSetting.reservation_img}
                    alt="Reservation"
                    className="img-fluid radius-image"
                  />
                  )
                }

                {
                  imageLoader
                  && (
                  <CircularProgress
                    sx={styles.circularProgress}
                  />
                  )
                }
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 banner_bottom_left">

                <div className="contact-right">
                  <Box component="form" noValidate onSubmit={reservationSubmitHandler}>

                    <TextField
                      type="text"
                      variant="outlined"
                      margin="dense"
                      required
                      fullWidth
                      name="name"
                      label="Full Name"
                      autoComplete="off"
                      id="name"
                      error={!!errorState.fields.name}
                      helperText={errorState.fields.name}
                      value={reservationFormState.name || ''}
                      onChange={formHandler}
                    />

                    <TextField
                      type="text"
                      variant="outlined"
                      margin="dense"
                      required
                      fullWidth
                      name="email"
                      label="Email Address"
                      autoComplete="off"
                      id="email"
                      error={!!errorState.fields.email}
                      helperText={errorState.fields.email}
                      value={reservationFormState.email || ''}
                      onChange={formHandler}
                    />

                    <TextField
                      type="text"
                      variant="outlined"
                      margin="dense"
                      required
                      fullWidth
                      name="contact"
                      label="Contact Number"
                      autoComplete="off"
                      id="contact"
                      error={!!errorState.fields.contact}
                      helperText={errorState.fields.contact}
                      value={reservationFormState.contact || ''}
                      onChange={formHandler}
                    />

                    <TextField
                      type="text"
                      variant="outlined"
                      margin="dense"
                      required
                      fullWidth
                      name="persons"
                      label="Number of Persons"
                      autoComplete="off"
                      id="persons"
                      error={!!errorState.fields.persons}
                      helperText={errorState.fields.persons}
                      value={reservationFormState.persons || ''}
                      onChange={formHandler}
                    />

                    <LocalizationProvider dateAdapter={AdapterDateFns}>

                      <Box display="flex" alignItems="center">

                        <Box ml={0} mr={2} mt={1} mb={1}>

                          <DatePicker
                            label="Select Date"
                            value={selectedDate}
                            onChange={setSelectedDate}
                            renderInput={(params) => <TextField {...params} />}
                            showTodayButton
                            name="date"
                            id="date"
                            minDate={new Date()}
                            shouldDisableDate={dayDisableHandler}
                          />

                        </Box>

                        <Box mb={1}>
                          {
                            !hideTimePicker
                            && (
                              <TextField
                                type="text"
                                variant="outlined"
                                margin="dense"
                                required
                                name="time"
                                label="Select Time"
                                id="time"
                                autoComplete="off"
                                value={getSelectedTime()}
                                onFocus={() => setTimePickerOpen(true)}
                              />
                            )
                          }

                          <TimePicker
                            open={timePickerOpen}
                            date={selectedDate}
                            hours={selectedDaysHours}
                            defaultValue={selectedTime}
                            closeHandler={() => setTimePickerOpen(false)}
                            changeHandler={(time) => setSelectedTime(time)}
                          />
                        </Box>
                      </Box>


                    </LocalizationProvider>

                    <TextField
                      type="text"
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      name="requirements"
                      label="Any Special Requirements"
                      autoComplete="off"
                      id="requirements"
                      error={!!errorState.fields.requirements}
                      helperText={errorState.fields.requirements}
                      value={reservationFormState.requirements || ''}
                      onChange={formHandler}
                    />

                    <InputLabel id="demo-customized-select-label" className="reservation-hear-about-text">
                      Where did you hear about us?
                    </InputLabel>
                    <Select
                      labelId="demo-customized-select-label"
                      id="demo-customized-select"
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      style={{ marginBottom: 15 }}
                      placeholder="Choose One"
                      value={promotionalText}
                      onChange={handleOtherChoose}
                    >
                      <MenuItem value="facebook">Facebook</MenuItem>
                      <MenuItem value="trip-advisor">Trip Advisor</MenuItem>
                      <MenuItem value="instagram">Instagram</MenuItem>
                      <MenuItem value="google search">Google Search</MenuItem>
                      <MenuItem value="recommendations">Recommendations</MenuItem>
                      <MenuItem value="newspaper">Newspaper</MenuItem>
                      <MenuItem value="Existing Customer">Existing Customer</MenuItem>
                      <MenuItem value="other">Others</MenuItem>
                    </Select>


                    {
                      othersShow
                      && (
                        <TextField
                          type="text"
                          variant="outlined"
                          fullWidth
                          name="heardOn"
                          label="Where did you hear about us?"
                          autoComplete="off"
                          style={{ marginBottom: 15, marginTop: 10 }}
                          id="heardOn"
                          error={!!errorState.fields.heardOn}
                          helperText={errorState.fields.heardOn}
                          value={reservationFormState.heardOn || ''}
                          onChange={formHandler}
                        />
                      )
                    }


                    {
                      reservationSetting.reservation_status
                      && (
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          disabled={loading || disable}
                          margin="dense"
                          style={{ padding: 10, marginTop: 5 }}
                        >
                          {
                            loading
                            && <CircularProgress size={25} />
                          }
                          {reservationBtnText}
                        </Button>
                      )
                    }

                    {
                      !reservationSetting.reservation_status
                      && (
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          disabled
                          margin="dense"
                          style={{ padding: 10, marginTop: 5 }}
                        >
                          {reservationSetting.reservation_disable_message}
                        </Button>
                      )
                    }

                    {/* <BtnTheme  useFillStyle={true}>Confirm Reservation</BtnTheme> */}
                  </Box>
                </div>

              </div>
            </div>

          </div>
        </div>
      </section>

    </>
  );

}

const mapStateToProps = (state) => ({
  user: state.user,
  date: state.cart.delivery.date,
  time: state.cart.delivery.time,
  opening: state.opening.data,
  deliveryTime: state.cart.delivery.time,
  isClosed: state.opening.isClosed,
  isOffDay: state.opening.isOffDay,
  todayOpening: state.opening.todaysTiming,
});

export default connect(mapStateToProps)(ReservationComponent);
