import React from 'react';
import './allMenu.module.scss';

import {
 Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography
} from '@mui/material';
import { CURRENCY_SYMBOL } from '../../util/constants';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddToCart from '../cart/addToCart';

export default function MenuItem({ item, groupItems }) {

  const styles = {
    menuItemPrice: {
      fontSize: 17,
      fontWeight: 600,
      whiteSpace: 'nowrap',
    },
    menuGroup: {
      width: '100%',
    },
    menuGroupHeading: {
      backgroundColor: (theme) => theme.palette.secondary.dark,
      borderRadius: 5
    },
    menuGroupHeadingText: {
      color: (theme) => theme.palette.grey['50'],
    },
    menuGroupHeadingTextCount: {
      color: (theme) => theme.palette.grey['300'],
    },
  };

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const renderItem = (item) => (
    <Grid container key={item.id} className="MenuItem">
      <Grid item xs={12} className="ItemNameAndPriceContainer">
        <Typography variant="h4" component="h2" className="ItemName">
          {item.name}
        </Typography>
        <Box className="ItemPriceBox">
          <Box sx={styles.menuItemPrice}>
            {CURRENCY_SYMBOL}
            {' '}
            {item.price.toFixed(2)}
          </Box>
          <Box ml={1}>
            <AddToCart item={item} />
          </Box>
        </Box>
      </Grid>
      {
          item.description
          && (
          <Grid item xs={12}>
            <Typography variant="body2" component="div" className="ItemDescription">
              {item.description}
            </Typography>
          </Grid>
)
        }

    </Grid>
    );

  const renderGroup = (item) => (
    <Accordion
      expanded={expanded === item.id}
      onChange={handleChange(item.id)}
      className="MenuItemGroup"
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className="AddItemToCart MenuItemGroupIcon" />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={styles.menuGroupHeading}
      >
        <Typography
          variant="h4"
          component="h2"
          sx={styles.menuGroupHeadingText}
        >
          {item.name}
        </Typography>
        <Typography
          variant="subtitle1"
          sx={styles.menuGroupHeadingTextCount}
          style={{ marginLeft: 7 }}
          className="MenuItemGroupItemCount"
        >
          {groupItems.length}
          {' '}
          {(groupItems.length > 1) ? 'Items' : 'Item'}
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ flexDirection: 'column' }}>
        {
            groupItems.map((itm) => renderItem(itm))
          }
      </AccordionDetails>
    </Accordion>
);

  if (!item.is_group && !item.group_id) {
    return renderItem(item);
  }

  if (item.is_group) {
    return renderGroup(item);
  }

  if (item.group_id) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

}
