import React from 'react';
import './allMenu.module.scss';
import {
 Accordion, AccordionDetails, AccordionSummary, Box, Card, Grid, Typography
} from '@mui/material';
import { CURRENCY_SYMBOL } from '../../util/constants';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddToCart2 from '../cart/addToCart2';
import './categoryMenu.scss';

export default function MenuItem2({ item, groupItems }) {

  const styles = {
    menuGroup: {
      width: '100%',
    },
    menuGroupHeading: {
      backgroundColor: (theme) => theme.palette.secondary.dark,
    },
    menuGroupHeadingTextCount: {
      color: 'yellow',
    },
  };

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const renderItem = (item) => (

    <Card key={item.id} className="MenuItem">
      <Grid item xs={12} className="ItemNameAndPriceContainer" bgcolor="antiquewhite">
        <Box xs={12}>
          <Typography variant="h4" component="h2" className="ItemName">
            {item.name}
          </Typography>

          <Typography variant="body2" component="div" className="ItemDescription">
            {item.description}
          </Typography>

        </Box>

        <Box className="ItemPriceBox">
          <Box className="item-price">
            {CURRENCY_SYMBOL}
            {' '}
            {item.price.toFixed(2)}
          </Box>

          <Box ml={1} className="price-icon-div">
            <AddToCart2 item={item} />
          </Box>
        </Box>
      </Grid>
    </Card>
    );

  const renderGroup = (item) => (
    <Accordion
      expanded={expanded === item.id}
      onChange={handleChange(item.id)}
      className="MenuItemGroup"
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className="AddItemToCart MenuItemGroupIcon" />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={styles.menuGroupHeading}
      >
        <Typography
          variant="h4"
          component="h2"
          className="group-item-title"
        >
          {item.name}
        </Typography>
        <Typography
          variant="subtitle1"
          className="MenuItemGroupItemCount"
          sx={styles.menuGroupHeadingTextCount}
          style={{ marginLeft: 7 }}
        >
          {groupItems.length}
          {' '}
          {(groupItems.length > 1) ? 'Items' : 'Item'}
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ flexDirection: 'column' }} className="group-item-details-div">
        {
            groupItems.map((itm) => renderItem(itm))
          }
      </AccordionDetails>
    </Accordion>
);

  if (!item.is_group && !item.group_id) {
    return renderItem(item);
  }

  if (item.is_group) {
    return renderGroup(item);
  }

  if (item.group_id) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

}
