import React from 'react';
import { connect } from 'react-redux';
import useSetting from '../../hooks/useSetting';
import '../../assets/css/custom.scss';

import { Typography, Link } from '@mui/material';

function Copyright() {

  const setting = useSetting([
    'site_title',
    'designed_by',
    'copyright_text',
  ]);

  return (
    <Typography align="center" className="footer-text" component="div">
      Crafted by
      {' '}
      <a
        target="_blank"
        href="https://ordering2online.co.uk/"
        rel="noreferrer"
      >
        Ordering2Online
      </a>
      , Hosted on
      {' '}
      <a
        target="_blank"
        href="https://snebtaf.co.uk/"
        rel="noreferrer"
      >
        {setting.designed_by}
      </a>
      {' '}
      <span dangerouslySetInnerHTML={{ __html: setting.copyright_text }} />
      {' '}
      {setting.site_title}

    </Typography>
  );
}

const mapStateToProps = (state) => ({
  isLogin: state.user.isLoggedIn,
});

export default connect(mapStateToProps)(Copyright)
