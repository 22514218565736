import React, { useEffect, useMemo, useState } from 'react';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { LocalizationProvider, MobileTimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
 Box, Button, Grid, TextField, Typography
} from '@mui/material';
import * as dateFns from 'date-fns';
import { connect } from 'react-redux';
import useNotify from '../../hooks/useNotify';
import useSetting from '../../hooks/useSetting';
import { cartDeliveryDateUpdate, cartDeliveryTimeUpdate, cartUpdateASAPTime } from '../../store/cart/cartActions';
import { clearUnavailableOrderWithTime, updateUnavailableOrderWithTime } from '../../store/opening/openingActions';
import { ADDITIONAL_BUFFER_MIN } from '../../util/constants';
import {
  applyTimeZone,
  convertTimeTo12Hour,
  getCurrentClosingTime,
  getDateFormat,
  getTimeFormat,
  getTimeFormatFromTimeString,
  getValidOpeningStatus
} from '../../util/util';

function DateTime({ ...otherProps }) {

  const [selectedDate, setSelectedDate] = useState(() => (otherProps.deliveryDate.length
    ? applyTimeZone(new Date(otherProps.deliveryDate))
    : applyTimeZone(new Date())))
  ;

  const timingSetting = useSetting([
    'full_time_order_start',
    'full_time_order_end',
    'delivery_order_delivery_time',
    'collection_order_delivery_time',
  ]);

  const [notify] = useNotify();

  const [availableTodayDelivery, setAvailableTodayDelivery] = useState([]);

  const [orderTypeUnavailable, setOrderTypeUnavailable] = useState('');

  const [hideTimePicker, setHideTimePicker] = useState(false);

  const [hideTimePickerField, setHideTimePickerField] = useState(false);

  const [isDisable, setIsDisable] = useState(false);

  const [bufferMin, setBufferMin] = useState(
    () => (timingSetting.full_time_order_start ? 0 : timingSetting.delivery_order_delivery_time)
  );

  const selectedDaysHours = useMemo(() => {
    // sync updated time & date to store
    otherProps.setDeliveryDate(dateFns.format(selectedDate, getDateFormat()));

    const selectedDayName = dateFns.format(selectedDate, 'EEE')
      .toLowerCase();

    // set selected days hours
    const selectedDay = otherProps.opening.find((itm) => itm.day === selectedDayName);

    const hours = selectedDay ? selectedDay.hours : [{ from: '00:00:00', to: '23:59:59' }];

    return hours;

  }, [selectedDate, otherProps.opening]);


  const [selectedTime, setSelectedTime] = useState(() => {
    const date = otherProps.deliveryDate.length ? applyTimeZone(new Date(otherProps.deliveryDate)) : null;

    // if date time available then set that
    if (otherProps.deliveryTime.length) {
      return new Date(`${date.toDateString()} ${otherProps.deliveryTime}`);
    }

    return false;
  });

  const [availableTimeMsg, setAvailableTimeMsg] = useState([]);

  useEffect(() => {

    otherProps.clearUnavailableOrderWithTime();
    otherProps.setDeliveryIsASAPTime(otherProps.isAsap);

    otherProps.todayTiming.hours?.map((val) => {

      const [toHour, toMinute] = val.to.split(':');

      const [fromHour, fromMinute] = val.to.split(':');

      const toTime = dateFns.setHours(dateFns.setMinutes(applyTimeZone(new Date()), toMinute), toHour);

      const fromTime = dateFns.setHours(dateFns.setMinutes(applyTimeZone(new Date()), fromMinute), fromHour);

      const currentDate = applyTimeZone(new Date());

      if (currentDate >= fromTime || currentDate <= toTime) {

        const isDelivery = val.enabled_delivery;
        const isCollection = val.enabled_collection;

        if (!isDelivery && otherProps.orderType === 'Delivery') {
          setOrderTypeUnavailable('Delivery');
        } else if (!isCollection && otherProps.orderType === 'Collection') {
          setOrderTypeUnavailable('Collection');
        }
      }

    });


    if (!timingSetting.full_time_order_start) {

      if (otherProps.orderType === 'Delivery') {
        const availableDelivery = otherProps.todayTiming.hours?.filter((val) => val.enabled_delivery === true);
        setAvailableTodayDelivery(availableDelivery);
        const setTime = parseInt(timingSetting.delivery_order_delivery_time) + ADDITIONAL_BUFFER_MIN;
        setBufferMin(setTime);
      } else {
        const availableCollection = otherProps.todayTiming.hours.filter((val) => val.enabled_collection === true);
        setAvailableTodayDelivery(availableCollection);
        const setTime = parseInt(timingSetting.collection_order_delivery_time) + ADDITIONAL_BUFFER_MIN
        setBufferMin(setTime);
      }
    } else {
      setBufferMin(ADDITIONAL_BUFFER_MIN);
    }


  }, [otherProps.orderType])

  useEffect(() => {
    if (selectedTime === false) return;
    otherProps.updateUnavailableOrderWithTime('');

    otherProps.todayTiming.hours.map((val) => {


      const [toHour, toMinute] = val.to.split(':');

      const [fromHour, fromMinute] = val.from.split(':');

      const toTime = dateFns.setHours(dateFns.setMinutes(applyTimeZone(new Date()), toMinute), toHour);

      const fromTime = dateFns.setHours(dateFns.setMinutes(applyTimeZone(new Date()), fromMinute), fromHour);


      if (selectedTime >= fromTime && selectedTime <= toTime) {


        const isDelivery = val.enabled_delivery;
        const isCollection = val.enabled_collection;

        if (!isDelivery && otherProps.orderType === 'Delivery') {
          notify.warning('Delivery is not available at this time.');
          otherProps.updateUnavailableOrderWithTime(otherProps.orderType);

        } else if (!isCollection && otherProps.orderType === 'Collection') {
          notify.warning('Collection is not available at this time.');
          otherProps.updateUnavailableOrderWithTime(otherProps.orderType);
        }
      }

    });


    if (!otherProps.isAsap) {
      otherProps.setDeliveryTime(dateFns.format(selectedTime, getTimeFormat()));
    }

  }, [selectedTime, otherProps.isAsap]);


  useEffect(() => {
    if (otherProps.isClosed) {
      const today = new Date();
      const tomorrow = new Date();
      let i;
      for (i = 1; i <= 7; i += 1) {
        tomorrow.setDate(today.getDate() + i)
        const dayName = dateFns.format(tomorrow, 'EEE');
        if (openingDays.includes(dayName.toLowerCase())) {
          otherProps.setDeliveryDate(tomorrow);
          setSelectedDate(applyTimeZone(new Date(tomorrow)));
          // return dateFns.format(tomorrow, 'EEEE');
          break;
        }
      }

    }

  }, [otherProps.isClosed]);

  const openingDays = useMemo(() => otherProps.opening.map((itm) => itm.day), [otherProps.opening]);

  const dayDisableHandler = (day) => {

    // enable all days if opening days not set
    if (openingDays.length === 0) {
      return false;
    }

    const dayName = dateFns.format(day, 'EEE')
      .toLowerCase();

    // only enable days that have timing set
    return !openingDays.includes(dayName);
  };

  const isToday = () => {
    const currentTime = applyTimeZone(new Date());
    const todayDate = `${currentTime.getFullYear()}-${currentTime.getMonth() + 1}-${currentTime.getDate()}`;
    const selectedDateValue = `${selectedDate.getFullYear()}-${selectedDate.getMonth() + 1}-${selectedDate.getDate()}`;
    return todayDate === selectedDateValue;
  }

  const checkAvailableTimeText = () => {
    const msg = selectedDaysHours.map((time) => {
      let timeMsg = '';
      const currentTime = applyTimeZone(new Date());
      const today = isToday();
      let [fromHour, fromMin] = time.from.split(':');
      let [toHour, toMin] = time.to.split(':');
      fromHour = dateFns.setHours(dateFns.setMinutes(applyTimeZone(new Date()), parseInt(fromMin)), parseInt(fromHour));
      toHour = dateFns.setHours(dateFns.setMinutes(applyTimeZone(new Date()), parseInt(toMin)), parseInt(toHour));

      if (currentTime.getTime() > toHour.getTime()) {
        return false;
      }

      if (!today) {
        const fromHourFormat = dateFns.format(fromHour, 'hh:mm');
        const ToHourFormat = dateFns.format(toHour, 'hh:mm');
        const timePeriod = dateFns.format(fromHour, 'aaa');
        const timeEndPeriod = dateFns.format(toHour, 'aaa');
        timeMsg = `from ${fromHourFormat} ${timePeriod} To ${ToHourFormat} ${timeEndPeriod} `;
      }


      if (currentTime.getTime() >= fromHour.getTime() && currentTime.getTime() <= toHour.getTime() && today) {

        let bufferTime = new Date(currentTime.getTime() + parseInt(bufferMin * 60000));

        let bufferEndTime = new Date(toHour.getTime() - parseInt(bufferMin * 60000));

        const bufferTimeFormat = dateFns.format(bufferTime, 'hh:mm');

        const bufferTimeEndFormat = dateFns.format(bufferEndTime, 'hh:mm');

        const timePeriod = dateFns.format(bufferTime, 'aaa');

        const timeEndPeriod = dateFns.format(bufferEndTime, 'aaa');

        const validMsgTimeChecker = new Date(bufferTime.getTime() + parseInt(bufferMin * 60000));

        if (validMsgTimeChecker.getTime() >= bufferEndTime.getTime()) {
          timeMsg = `Order has taken until ${bufferTimeEndFormat} ${timeEndPeriod} `;
        } else {
          timeMsg = `from ${bufferTimeFormat} ${timePeriod} To ${bufferTimeEndFormat} ${timeEndPeriod} `;
        }

      } else {

        const bufferTime = new Date(fromHour.getTime() + parseInt(bufferMin * 60000));

        const bufferEndTime = new Date(toHour.getTime() - parseInt(bufferMin * 60000));

        const bufferTimeFormat = dateFns.format(bufferTime, 'hh:mm');

        const bufferTimeEndFormat = dateFns.format(bufferEndTime, 'hh:mm');

        const timePeriod = dateFns.format(bufferTime, 'aaa');

        const timeEndPeriod = dateFns.format(bufferEndTime, 'aaa');

        const validMsgTimeChecker = new Date(bufferTime.getTime() + parseInt(bufferMin * 60000));

        if (validMsgTimeChecker.getTime() >= bufferEndTime.getTime()) {
          timeMsg = `Order has taken until ${bufferTimeEndFormat} ${timeEndPeriod} `;
        } else {
          timeMsg = `from ${bufferTimeFormat} ${timePeriod} To ${bufferTimeEndFormat} ${timeEndPeriod} `;
        }
      }
      return timeMsg;
    });
    setAvailableTimeMsg(msg);
  }

  //set initial timing of timepicker
  useEffect(() => {

    //check if current time + buffer time > of closing time then hide timePicker
    const clossingTimeArray = getCurrentClosingTime(selectedDaysHours);

    const closingTime = clossingTimeArray.filter((time) => time);

    const currentTime = applyTimeZone(new Date());

    const closingBuffer = parseInt(bufferMin) + parseInt(bufferMin);

    const currentTimeWithBuffer = new Date(currentTime.getTime() + parseInt(closingBuffer * 60000));

    const today = isToday();

    if (otherProps.isOpen) {
      handleAsapClick();
    }

    if (!otherProps.isClosed) {

      const time = getSelectedTime();

      if (!selectedTime && isRestaurantOpen && isFullTimeStart) {
        setSelectedTime(time);
      }

      if (!selectedTime && isRestaurantOpen && !isFullTimeStart) {
        const withBufferTime = new Date(parseInt(time.getTime()) + parseInt(bufferMin * 60000));
        setSelectedTime(withBufferTime);
      }

      if (!selectedTime && !isRestaurantOpen && isFullTimeStart) {
        setSelectedTime(time);
      }

      if (!selectedTime && !isRestaurantOpen && !isFullTimeStart) {
        const openingWithBuffer = new Date(parseInt(time?.getTime()) + parseInt(bufferMin * 60000));
        setSelectedTime(openingWithBuffer);
      }
    } else {
      setHideTimePickerField(false);
    }

  }, []);

  const isRestaurantOpen = useMemo(
    () => getValidOpeningStatus(otherProps.todayOpening.hours),
    [otherProps.todayOpening.hours]
  );

  //control state of timepicker disable depends on isAsap
  useEffect(() => {
    if (otherProps.isAsap) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  }, [otherProps.isAsap])

  useEffect(() => {
    if (otherProps.deliveryTime.length) return;

    if (orderTypeUnavailable === '') {
      otherProps.setDeliveryIsASAPTime(isRestaurantOpen);

    } else {
      otherProps.setDeliveryIsASAPTime(otherProps.isAsap);
    }
  }, [isRestaurantOpen, orderTypeUnavailable]);


  const [isFullTimeStart, setIsFullTimeStart] = useState(false);
  const [isFullTimeEnd, setIsFullTimeEnd] = useState(false);
  const [availableToHour, setAvailableToHour] = useState();
  const [availableToMinute, setAvailableToMinute] = useState();
  const [availableFromHour, setAvailableFromHour] = useState();
  const [availableFromMinute, setAvailableFromMinute] = useState();

  const getSelectedTime = () => {

    let [fromHour, fromMin] = selectedDaysHours[0].from.split(':');
    let [toHour, toMin] = selectedDaysHours[0].to.split(':');
    let [lastFromHour, lastFromMin] = selectedDaysHours[selectedDaysHours.length - 1].from.split(':');
    let [lastToHour, lastToMin] = selectedDaysHours[selectedDaysHours.length - 1].to.split(':');

    fromHour = dateFns.setHours(dateFns.setMinutes(applyTimeZone(new Date()), fromMin), fromHour);
    toHour = dateFns.setHours(dateFns.setMinutes(applyTimeZone(new Date()), toMin), toHour);
    lastFromHour = dateFns.setHours(dateFns.setMinutes(applyTimeZone(new Date()), lastFromMin), lastFromHour);
    lastToHour = dateFns.setHours(dateFns.setMinutes(applyTimeZone(new Date()), lastToMin), lastToHour);
    const currentTime = applyTimeZone(new Date());

    let startTime;

    if (selectedDaysHours.length === 1) {
      if (fromHour.getTime() <= currentTime.getTime() && currentTime.getTime() <= toHour.getTime()) {
        startTime = currentTime;
      }
      if (fromHour.getTime() > currentTime.getTime()) {
        startTime = fromHour;
      }
    } else {

      if (fromHour.getTime() <= currentTime.getTime() && currentTime.getTime() <= toHour.getTime()) {
        startTime = currentTime;
      }

      if (fromHour.getTime() > currentTime.getTime()) {
        startTime = fromHour;
      }

      if (currentTime.getTime() > toHour.getTime() && currentTime.getTime() < lastFromHour.getTime()) {
        startTime = lastFromHour;
      }
      if (lastFromHour.getTime() <= currentTime.getTime() && currentTime.getTime() <= lastToHour.getTime()) {
        startTime = currentTime;
      }
    }

    return startTime;

  }

  useEffect(() => {
    if (selectedDaysHours.length > 1) {
      let [fromHour, fromMinute] = selectedDaysHours[0].from.split(':');
      let [toHour, toMinute] = selectedDaysHours[selectedDaysHours.length - 1].to.split(':');

      if (!isFullTimeStart) {
        setAvailableFromMinute(parseInt(fromMinute) + parseInt(bufferMin));
      } else {
        setAvailableFromMinute(fromMinute);
      }

      if (!isFullTimeEnd) {
        setAvailableToMinute(parseInt(toMinute) - parseInt(bufferMin));
      } else {
        setAvailableToMinute(toMinute);
      }

      //set start time to end-time of full day
      setAvailableFromHour(fromHour);
      setAvailableToHour(toHour);
    } else {
      selectedDaysHours.map((val) => {
        let [toHour, toMinute] = val.to.split(':');
        let [fromHour, fromMinute] = val.from.split(':');

        if (!isFullTimeStart) {
          setAvailableFromMinute(parseInt(fromMinute) + parseInt(bufferMin));
        } else {
          setAvailableFromMinute(fromMinute);
        }

        if (!isFullTimeEnd) {
          setAvailableToMinute(parseInt(toMinute) - parseInt(bufferMin));
        } else {
          setAvailableToMinute(toMinute);
        }
        setAvailableToHour(toHour);
        setAvailableFromHour(fromHour);
      })
    }
  }, [selectedDaysHours, isFullTimeStart, isFullTimeEnd])

  //set full time ordering will start or not
  useEffect(() => {

    if (timingSetting.full_time_order_start) {
      setIsFullTimeStart(true);
    } else {
      setIsFullTimeStart(false);
    }

    if (timingSetting.full_time_order_end) {
      setIsFullTimeEnd(true);
    } else {
      setIsFullTimeEnd(false);
    }

  }, [timingSetting.full_time_order_start, timingSetting.full_time_order_end]);

  const convertTimeObject = (timeStr) => new Date(timeStr)

  const getShouldOpenTimeLoop = (time, clockType) => {

    const currentTime = applyTimeZone(new Date());

    const today = isToday();

    let selectedDateValue = `${selectedDate.getFullYear()}-${selectedDate.getMonth() + 1}-${selectedDate.getDate()}`;

    let value;
    if (selectedTime) {

      const convertedTime = parseInt(convertTimeTo12Hour(selectedTime.getHours()));

      const merediam = otherProps.deliveryTime.substr(6, otherProps.deliveryTime.length);
      const hour = selectedTime.getHours();
      const min = selectedTime.getMinutes();
      const seconds = selectedTime.getSeconds();

      const timeToStr = `${selectedDateValue} ${parseInt(convertTimeTo12Hour(time))}:${min}:${seconds}`;
      const timeObj = convertTimeObject(timeToStr);
      value = selectedDaysHours?.map((todayTimes) => {

        let [toHour, toMin] = todayTimes.to.split(':');
        let [fromHour, fromMin] = todayTimes.from.split(':');

        const timeFrom = dateFns.setHours(dateFns.setMinutes(applyTimeZone(new Date()), fromMin), fromHour);
        const timeTo = dateFns.setHours(dateFns.setMinutes(applyTimeZone(new Date()), toMin), toHour);


        if (clockType === 'hour') {
          if (currentTime.getTime() < timeFrom.getTime()) {
            return (timeFrom.getHours() <= time && timeTo.getHours() <= time)
          }
          if (currentTime.getTime() >= timeFrom.getTime() && currentTime.getTime() <= timeTo.getTime()) {
            if (merediam === 'am') {
              return !(currentTime.getHours() <= time)
            }
            if (merediam === 'pm') {
              return !((currentTime.getHours() + 12) <= time)

            }
          }

          if (currentTime.getTime() > timeTo.getTime()) {
            return (fromHour.getHours() <= time && time <= toHour.getHours())

          }
        }


        if (clockType === 'minutes') {
          if (currentTime.getTime() < timeFrom.getTime()) {
            return ((timeFrom.getMinutes() + parseInt(bufferMin)) <= time && time <= (timeTo.getMinutes() - parseInt(bufferMin)))
          }
          if (currentTime.getTime() >= timeFrom.getTime() && currentTime.getTime() <= timeTo.getTime()) {
            if (selectedTime.getHours() === currentTime.getHours()) {
              return ((currentTime.getMinutes() + parseInt(bufferMin)) >= time)
            }
            if (selectedTime.getHours() === timeTo.getHours()) {
              return ((timeTo.getMinutes() - parseInt(bufferMin)) <= time)
            }
          }

          if (currentTime.getTime() > timeTo.getTime()) {
            return (timeFrom.getMinutes() <= time && time <= timeTo.getMinutes())
          }
        }


        // toHour = convertTimeTo12Hour(parseInt(toHour));
        // fromHour = convertTimeTo12Hour(parseInt(fromHour))

        // if (clockType === 'hours' && today) {
        //   if (merediam === 'am' && currentTime.getHours() <= 11) {
        //     return !(time >= parseInt(currentTime.getHours()))
        //   }
        //   if (merediam === 'am' && currentTime.getHours() > 11) {
        //     return (time);
        //   }
        //   if (merediam === 'pm' && currentTime.getHours() <= 11) {
        //     return !(time);
        //   }
        //   if (merediam === 'pm' && currentTime.getHours() > 11) {
        //     return !(time >= parseInt(convertTimeTo12Hour(currentTime.getHours())) && time <= 11)
        //   }
        // }

        // // show only current available time compare with current date
        // if ((clockType === 'minutes' && today)
        //   && convertedTime === parseInt(convertTimeTo12Hour(currentTime.getHours()))
        //   && convertedTime !== parseInt(toHour)) {
        //   return !(time >= (parseInt(currentTime.getMinutes())) + parseInt(bufferMin))
        // }

        // if (clockType === 'minutes' && parseInt(toHour) === convertedTime) {
        //   let handleMinutes;
        //   if (isFullTimeStart) {
        //     handleMinutes = toMin;
        //   } else {
        //     handleMinutes = parseInt(toMin) - parseInt(bufferMin);
        //   }

        //   return !(time <= handleMinutes);
        // }

        // if (clockType === 'minutes' && parseInt(fromHour) === convertedTime) {
        //   let openingTime;
        //   if (isFullTimeStart) {
        //     openingTime = fromMin;
        //   } else {
        //     openingTime = parseInt(fromMin) + parseInt(bufferMin);
        //   }
        //   return !(time >= openingTime);
        // }


        return false;
      })
    }
    return !!(value?.filter((v) => v).length);
  }

  const [shouldOpen, setShouldOpenPicker] = useState(false);

  const [closePicker, setClosePicker] = useState(false);

  useEffect(() => {
    if (closePicker) {
      setShouldOpenPicker(false);
      setClosePicker(false);
    }
  }, [closePicker])

  // //handle timepicker & time after restaurant closing for future order
  // useEffect(() => {

  //   const today = isToday();

  //   if (otherProps.isClosed && today) {
  //     setHideTimePickerField(true);
  //     otherProps.setDeliveryTime('');
  //   } else if (otherProps.isOpen) {
  //     setHideTimePickerField(true);
  //   } else {
  //     setHideTimePickerField(false);
  //   }

  // }, [selectedDate]);

  const handleTimePicker = () => {
    checkAvailableTimeText();
    if (closePicker) return;
    setShouldOpenPicker(true);
  }

  const getAcceptDate = (accept) => {

    setClosePicker(false);

    const isValidTimes = isValidTime(accept);

    if (isValidTimes) {
      setClosePicker(true);
    } else if (!otherProps.isClosed) {
      setClosePicker(false);
      notify.warning('Please Select a valid time');
    } else {
      setClosePicker(true);
    }

  }


  const isValidTime = (acceptedTime) => {

    const today = isToday();
    const currentTime = applyTimeZone(new Date());

    const valid = selectedDaysHours.map((todayTime) => {
      const [fromHour, fromMin] = todayTime.from.split(':');
      const [toHour, toMin] = todayTime.to.split(':');

      let hoursDetails = {
        from: dateFns.setHours(dateFns.setMinutes(applyTimeZone(new Date()), fromMin), fromHour),
        to: dateFns.setHours(dateFns.setMinutes(applyTimeZone(new Date()), toMin), toHour)
      };

      hoursDetails.from = new Date(hoursDetails.from.getTime() + parseInt(bufferMin * 60000));
      hoursDetails.to = new Date(hoursDetails.to.getTime() - parseInt(bufferMin * 60000));
      const newBufferMin = parseInt(bufferMin) - 1; //to fix some second issue for double datetime object
      const currentWithBuffer = new Date(currentTime.getTime() + parseInt(newBufferMin * 60000));

      if (today) {
        return (acceptedTime.getTime() >= hoursDetails.from.getTime()
          && acceptedTime.getTime() <= hoursDetails.to.getTime()
          && acceptedTime.getTime() >= currentWithBuffer.getTime())
      }
      return (acceptedTime.getTime() >= hoursDetails.from.getTime()
        && acceptedTime.getTime() <= hoursDetails.to.getTime())


    })

    return valid.filter((val) => val).length;
  }

  const handleAsapClick = () => {
    otherProps.setDeliveryIsASAPTime(true);
    setHideTimePickerField(true);
  }

  const handleSelectTimeClick = () => {
    otherProps.setDeliveryIsASAPTime(false);
    setShouldOpenPicker(true);
    setHideTimePickerField(false);
  }

  const handleClosePicker = () => {
    getAcceptDate(selectedTime);
  }


  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>

      <Grid container>
        <Grid item xs={12} md={6}>

          <form noValidate>

            {/* <Box style={{ width: 210 }}>
              <DatePicker
                label="Select Date"
                value={selectedDate}
                onChange={setSelectedDate}
                renderInput={(params) => <TextField {...params} />}
                showTodayButton
                name="date"
                id="date"
                minDate={new Date()}
                shouldDisableDate={dayDisableHandler}
              />
            </Box> */}

            <Box display="flex">
              {
                isRestaurantOpen && orderTypeUnavailable === '' && (
                  <Box display="flex" alignItems="center" mt={3} mb={1}>
                    <Button
                      variant={`${otherProps.cart.delivery.isAsapTime ? 'contained' : 'outlined'}`}
                      startIcon={otherProps.cart.delivery.isAsapTime ? <RadioButtonCheckedIcon />
                        : <RadioButtonUncheckedIcon />}
                      onClick={handleAsapClick}
                      color="primary"
                      sx={{ marginRight: '5px' }}
                    >
                      ASAP
                    </Button>


                    <Box sx={{ marginRight: '5px' }}>OR</Box>
                    <Button
                      variant={`${otherProps.cart.delivery.isAsapTime ? 'outlined' : 'contained'}`}
                      startIcon={otherProps.cart.delivery.isAsapTime ? <RadioButtonUncheckedIcon />
                        : <RadioButtonCheckedIcon />}
                      onClick={handleSelectTimeClick}
                      color="primary"
                    >
                      Select Time
                    </Button>


                  </Box>
                )
              }

              {
                orderTypeUnavailable !== '' && (
                  <Typography
                    variant="body1"
                    style={{
                      marginLeft: 5,
                      marginTop: 10,
                    }}
                    color="primary"
                  >
                    {`${orderTypeUnavailable} order available in the following times:`}

                  </Typography>
                )
              }

              {
                orderTypeUnavailable !== ''
                && availableTodayDelivery.map((val) => (
                  <Typography
                    kay={`hr${val.id}`}
                    variant="body1"
                    style={{
                      marginLeft: 15
                    }}
                    color="secondary"
                  >
                    {getTimeFormatFromTimeString(val.from, '', false)}
                    {' '}
                    to
                    {getTimeFormatFromTimeString(val.to, '', false)}
                  </Typography>
                ))
              }
            </Box>

            {
              !hideTimePickerField
              && (
                <Box className="time-picker" mt={2} onClick={handleTimePicker}>
                  <MobileTimePicker
                    label="Select Time"
                    value={selectedTime}
                    onChange={(time) => setSelectedTime(time)}
                    minutesStep={5}
                    name="time"
                    id="time"
                    toolbarTitle={(
                      <Box>
                        Available Time:
                        {
                          availableTimeMsg.map((msg, index) => (
                            <p key={index} className="timeMsg">{msg}</p>
                          ))
                        }
                      </Box>
                    )}
                    ampm
                    open={shouldOpen}
                    showToolbar
                    disabled={isDisable}
                    onClose={handleClosePicker}
                    renderInput={(params) => <TextField {...params} />}
                    shouldDisableTime={getShouldOpenTimeLoop}
                    onAccept={getAcceptDate}
                    minTime={new Date(0, 0, 0, availableFromHour, availableFromMinute)}
                    maxTime={new Date(0, 0, 0, availableToHour, availableToMinute)}
                  />
                </Box>
              )

            }

          </form>

        </Grid>
      </Grid>

    </LocalizationProvider>
  );
}

const mapStateToProps = (state) => ({
  deliveryDate: state.cart.delivery.date,
  deliveryTime: state.cart.delivery.time,
  opening: state.opening.data,
  todayTiming: state.opening.todaysTiming,
  cart: state.cart,
  isClosed: state.opening.isClosed,
  isOpen: state.opening.isOpen,
  isAsap: state.cart.delivery.isAsapTime,
  todayOpening: state.opening.todaysTiming,
  orderType: state.cart.order.type,
});

const mapDispatchToProps = (dispatch) => ({
  setDeliveryDate: (date) => dispatch(cartDeliveryDateUpdate(date)),
  setDeliveryTime: (time) => dispatch(cartDeliveryTimeUpdate(time)),
  setDeliveryIsASAPTime: (isASAP) => dispatch(cartUpdateASAPTime(isASAP)),
  updateUnavailableOrderWithTime: (type) => dispatch(updateUnavailableOrderWithTime(type)),
  clearUnavailableOrderWithTime: () => dispatch(clearUnavailableOrderWithTime()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DateTime);
