import { useState, useEffect } from 'react';
import PaymentMethods from '../cards/paymentMethods';
import CardModal from '../cards/cardModal';
import StripeModal from '../cards/stripeModal';
import { cartPaymentMethodUpdate } from '../../store/cart/cartActions';
import { connect } from 'react-redux';
import useSetting from '../../hooks/useSetting';
import { Card, Grid } from '@mui/material';
import * as dateFns from 'date-fns';
import { applyTimeZone, getCurrentClosingTime } from '../../util/util';
import useNotify from '../../hooks/useNotify';
import { updateSetTimeOutRef, updateShowPaymentModal } from '../../store/layout/layoutActions';
import '../../assets/css/custom.scss';

function CardPayment({ ...otherProps }) {

  const classes = {
    root: {
      maxWidth: 350,
      padding: 3,
    },
  };

  const [selectCard, setSelectCard] = useState('cash');

  const [showModal, setShowModal] = useState(false);

  const [notify] = useNotify();

  // const [clearTimeOut, setClearTimeOut] = useState(false);

  const paymentSetting = useSetting([
    'payment_stripe'
  ]);

  useEffect(() => {
    if (otherProps.paymentMethod !== 'points') {
      return;
    }
    setSelectCard('points');
  }, [otherProps.paymentMethod]);


  const setCloseTimeOut = (close = false, duration = 0) => {
    let paymentTimeOut;
    if (!close) {
      paymentTimeOut = setTimeout(() => {
        setShowModal(false);
        setSelectCard('cash');
        otherProps.updateSetTimeOutRef(null);
        notify.warning('Restaurant has Closed Please try with tomorrow date', 10000);
      }, duration);

      otherProps.updateSetTimeOutRef(paymentTimeOut);
    }
    if (close) {
      clearTimeout(otherProps.timeOutRef);
      otherProps.updateSetTimeOutRef(null);
    }
  }

  useEffect(() => {
    setShowModal((selectCard === 'card'));
    otherProps.updatePaymentMethod(selectCard)

    if (selectCard === 'card' && otherProps.isRestaurantOpen) {

      let todayDate = dateFns.format(new Date(), 'yyyy-LL-dd');

      if (todayDate === otherProps.orderDate) {

        const clossingTimeArray = getCurrentClosingTime(otherProps.todayHours);
        const closingTime = clossingTimeArray.filter((time) => time);
        const currentTime = applyTimeZone(new Date());
        let duration = Math.abs(currentTime.getTime() - closingTime[0].getTime() - 5000);

        setCloseTimeOut(false, duration);
      }

    }

  }, [selectCard])


  useEffect(() => {

    if (otherProps.isModalClosed) {

      setCloseTimeOut(true);
      otherProps.updateShowPaymentModal(false);
    }

  }, [otherProps.isModalClosed]);


  const resetPaymentMethod = () => {
    setSelectCard('cash');
    setShowModal(false);
    otherProps.updateShowPaymentModal(true);
  };


  return (
    <>
      {
      selectCard !== 'points'
      && (
      <PaymentMethods
        cartSelection={selectCard}
        cartSelectionHandler={setSelectCard}
      />
)
    }

      {
      selectCard === 'points'
      && (
      <Grid container sx={{ marginTop: 1 }}>
        <Card className="no-payment-text" sx={{ ...classes.root, width: '100%' }}>
          You can complete this order with points only, no payment required.

        </Card>
      </Grid>
)
    }


      {
      !paymentSetting.payment_stripe
      && (
      <CardModal
        show={showModal}
        selectedPaymentMethod={selectCard}
        resetPaymentMethodHandler={resetPaymentMethod}
      />
)
    }

      {
      paymentSetting.payment_stripe
      && (
      <StripeModal
        show={showModal}
        selectedPaymentMethod={selectCard}
        resetPaymentMethodHandler={resetPaymentMethod}
      />
)
    }

    </>
  );
}

const mapStateToProps = (state) => ({
  paymentMethod: state.cart.payment.method,
  adjustAmount: state.cart.adjustPointMoney,
  isRestaurantOpen: state.opening.isOpen,
  orderDate: state.cart.delivery.date,
  todayHours: state.opening.todaysTiming.hours,
  isModalClosed: state.layout.show_payment_modal,
  timeOutRef: state.layout.set_time_out_ref,
});

const mapDispatchToProps = (dispatch) => ({
  updatePaymentMethod: (method) => dispatch(cartPaymentMethodUpdate(method)),
  updateShowPaymentModal: (isOpen) => dispatch(updateShowPaymentModal(isOpen)),
  updateSetTimeOutRef: (ref) => dispatch(updateSetTimeOutRef(ref)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardPayment);
