import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  Box, Button, colors, MobileStepper, Paper, Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import useNotify from '../../hooks/useNotify';
import { setMenuAdd } from '../../store/cart/cartActions';
import { CURRENCY_SYMBOL } from '../../util/constants';
import AddStarterItem from './addStarterItem';
import './setMenu.scss';




const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    backgroundColor: theme.palette.background.default,
  },
  nav: {
    backgroundColor: colors.grey['200'],
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));


function AddSetMenu({ item, ...otherProps }) {

  const classes = useStyles();

  const [starterItem, setStarterItem] = useState([]);

  const [extraCost, setExtraCost] = useState(item.price.toFixed(2));

  let steps = Object.keys(item.types);
  steps.push('Set Menu Summary');
  const [notify] = useNotify();
  // steps
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = steps.length;
  const isLastStep = (activeStep === maxSteps - 1);

  const [allowedItems, setAllowedItems] = useState(0);


  useEffect(() => {
    if (steps[activeStep] === 'Set Menu Summary') return;

    const currentType = item.types[steps[activeStep]];
    setAllowedItems(currentType.allowed_qty);
  }, [activeStep]);

  useEffect(() => {

    if (steps[activeStep] === 'Set Menu Summary') return;

    const currentType = item.types[steps[activeStep]];


    starterItem
      ?.map((item1, index) => {

        const foundItem = currentType.items.find((item2) => item1.id === item2.id);
        if (!foundItem) {
            const exactItem = starterItem[index];

            if (exactItem.additional_price) {
              return false;
            }

          const newItem = {
            ...exactItem,
            additional_price: 0.00,
          }
          return starterItem.splice(index, 1, newItem);
        }
        // adding additional price

        const exactItem = starterItem[index];

        const newItem = {
          ...exactItem,
          additional_price: foundItem.add_price.toFixed(2),
        };
        return starterItem.splice(index, 1, newItem);
      })
      .filter((itm) => itm)
    ;

    currentType.items
      ?.map((item, index) => (
        item?.addons?.map((addon1) => {
          //filter specific item
          const foundItem = starterItem?.find((itm) => itm.id === item.id);
          if (!foundItem) {
            return false;
          }
          const itemIndex = _.findIndex(starterItem, { id: foundItem.id });

          //filter specific item which addons price will added
          const foundAddon = foundItem.selected_addon?.find((addon2) => addon1.id === addon2.id);
          if (!foundAddon || foundAddon.length === 0) {
            return false;
          }

          const newItem = {
            ...foundItem,
            selected_addon: foundItem.selected_addon.map((ad) => {
              if (ad.id === foundAddon.id) {
                const newAddon = {
                  ...foundAddon,
                  additional_price: addon1.add_price.toFixed(2),
                }
                return newAddon;
              }
              return {
                ...ad,
              }
            })
          }

          //return specific addon with additional price
         return starterItem.splice(itemIndex, 1, newItem);
        }).filter((adn) => adn)

      )).filter((itm2) => itm2);


    const itemTotal = calculateItemsTotal(starterItem);

    const addonTotal = calculateAddonsTotal(starterItem);


    const total = (itemTotal + addonTotal).toFixed(2);

    setExtraCost(parseFloat(total));

  }, [starterItem]);


  function calculateItemsTotal(items) {
    const total = items?.reduce((acc, itm) => acc + parseFloat(itm?.additional_price), 0);
    return total;
  }


  function calculateAddonsTotal(items) {

    const total = items.reduce((acc, item) => {

      const itemAddonsTotal = item.selected_addon.reduce((acc, addon) => {
        let price = 0.00;
        if (addon.additional_price) {
          price = addon.additional_price;
        }
        return acc + parseFloat(price)
      }, 0);

      return acc + itemAddonsTotal;
    }, parseFloat(item.price));

    return total;
  }

  const handleNext = () => {

    const count = starterItem.filter((itm) => itm.type === steps[activeStep]).length;

    if (count !== parseInt(allowedItems) && steps[activeStep] !== 'Set Menu Summary') {
      notify.warning(`Please choose ${allowedItems} items to proceed`);
      return;
    }

    // handle last step
    if (isLastStep) {

      const convertedItem = {
        ...item,
        qty: 1,
        total_cost: parseFloat(extraCost).toFixed(2),
      };
      const itemWithAdditions = { ...convertedItem, Additional_items: starterItem }

      notify.success('Set Menu Added');

      otherProps.setMenuAdd(itemWithAdditions);

      return;
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case stepIndex:
        return (
          <AddStarterItem
            handleStep={setActiveStep}
            item={item.types}
            step={steps[stepIndex]}
            selectedItems={starterItem}
            setSelectedItems={setStarterItem}
          />
        )

      default:
        throw new Error('Undefined step');
    }
  };

  return (
    <div className="setMenuBody">
      {/*heading*/}
      <Paper square elevation={0} className={classes.header}>
        <Typography variant="h4">{`${steps[activeStep]}`}</Typography>
      </Paper>

      {/*bottom*/}
      <MobileStepper
        className={classes.nav}
        steps={maxSteps}
        position="static"
        variant="text"
        activeStep={activeStep}
        nextButton={(
          <div className="buttonDiv">
            <Button className="backButton" size="small" onClick={handleBack} disabled={activeStep === 0}>
              <KeyboardArrowLeftIcon />
              Back
            </Button>
            <Button size="small" onClick={handleNext}>
              {isLastStep ? 'Add To Cart' : 'Next'}
              <KeyboardArrowRightIcon />
            </Button>
          </div>
        )}
        backButton={(
          <Box className="priceBox">
            Price:
            {' '}
            {CURRENCY_SYMBOL}
            {parseFloat(extraCost).toFixed(2)}
          </Box>
        )}
      />

      {/*content*/}
      <Box className="setMenuItemSize">
        {getStepContent(activeStep)}
      </Box>


    </div>
  );
}

const mapStateToProps = (state) => ({
  addons: state.cart.addons,
  items: state.menu.items,
  setMenus: state.menu.set_menus,
});

const mapDispatchProps = (dispatch) => ({
  setMenuAdd: (menuItems) => dispatch(setMenuAdd(menuItems)),
});

export default connect(mapStateToProps, mapDispatchProps)(AddSetMenu);
