/* eslint-disable default-param-last */
import {
  FIRST_ARRIVAL,
  FOOTER_HIDE,
  FOOTER_SHOW,
  HEADER_HIDE,
  HEADER_SHOW, SET_TIME_OUT_REF,
  SHOW_AUTO_OFFER_POPUP,
  SHOW_LEFT_SIDE_BAR,
  SHOW_PAYMENT_MODAL,
  SHOW_PROMOTIONAL_POPUP
} from './layoutTypes';

const defaultState = {
  show_header: true,
  show_footer: true,
  show_promotional_popup: false,
  show_auto_offer_popup: false,
  show_left_side_bar: false,
  first_arrival: false,
  show_payment_modal: false,
  set_time_out_ref: null,
};

const reducer = (state = defaultState, { type, payload }) => {

  switch (type) {
    case HEADER_SHOW:
      return {
        ...state,
        show_header: true
      }

    case HEADER_HIDE:
      return {
        ...state,
        show_header: false
      }

    case FOOTER_SHOW:
      return {
        ...state,
        show_footer: true
      }
    case SHOW_PAYMENT_MODAL:
      return {
        ...state,
        show_payment_modal: payload,
      }

    case FOOTER_HIDE:
      return {
        ...state,
        show_footer: false
      }
    case SHOW_PROMOTIONAL_POPUP:
      return {
        ...state,
        show_promotional_popup: payload
      }
    case SHOW_AUTO_OFFER_POPUP:
      return {
        ...state,
        show_auto_offer_popup: payload
      }

    case SET_TIME_OUT_REF:
      return {
        ...state,
        set_time_out_ref: payload,
      }

    case SHOW_LEFT_SIDE_BAR:
      return {
        ...state,
        show_left_side_bar: payload,
      }
    case FIRST_ARRIVAL:
      return {
        ...state,
        first_arrival: payload
      }

    default: return state
  }

};

export default reducer;
