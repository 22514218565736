import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { Box, Hidden, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import BottomNav from './BottomNav';
import useSetting from '../../hooks/useSetting';
import { SETTING_FILE_PATH } from '../../util/constants';
import routes from '../../util/routes';
import Copyright from '../util/copyright';
import './footer.scss';
import { getTimeFormatFromTimeString } from '../../util/util';


function Footer2({ ...otherProps }) {

  const setting = useSetting(['site_title',
    'facebook_url',
    'instagram_url',
    'trip_advisor',
    'address',
    'country_code',
    'phone',
    'display_email',
    'designed_by',
    'copyright_text',
    'site_logo_large',
    'about_description',

  ]);

  let todayName = new Date().toString().split(' ')[0];


  const footerTiming = useMemo(() => (
    <>
      {
        otherProps.opening.map((day) => (
          todayName.toLowerCase() === day.day
            ? (
              <Box key={day.id}>
                <Typography
                  variant="body1"
                  className="text-capitalize, footer-time"
                  component="span"
                  style={{
                    fontSize: 18,
                    fontWeight: 600
                  }}
                >
                  {day.day}
                </Typography>
                {
                  day.hours.map((hour) => (
                    // eslint-disable-next-line react/jsx-key
                    <Typography
                      kay={`hr${hour.id}`}
                      variant="body1"
                      className="footer-time"
                      component="span"
                      style={{
                        marginLeft: 0
                      }}
                    >
                      {getTimeFormatFromTimeString(hour.from, '', false)}
                      {' '}
                      -
                      {getTimeFormatFromTimeString(hour.to, '', false)}
                    </Typography>
                  ))
                }
              </Box>
            ) : <></>
        ))
      }
    </>
  ), [otherProps.opening]);

  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }


  if (!otherProps.show_footer) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }


  return (
    <>
      <section className="w3l-footer-29-main">
        <footer className="footer-59391">
          <div className="border-bottom pb-5 mb-4">
            <div className="container">
              <div className="row align-items-center">

                <div className="col-lg-4 text-lg-center ">
                  <ul>
                    <li>
                      <p className="footer-address">
                        <i className="fa fa-map-marker" />
                        {setting.address}
                      </p>
                    </li>

                  </ul>
                </div>
                <div className="col-lg-2">
                  <ul>
                    <li>
                      <a
                        className="footer-phone"
                        href={`tel:${setting.country_code}-${setting.phone}`}
                      >
                        <i className="fa fa-phone" />
                        (
                        {setting.country_code}
                        )-
                        {setting.phone}
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="col-lg-3">
                  <ul>
                    <li>
                      <a href={`mailto: ${setting.display_email}`} className="footer-email">
                        <i className="fa fa-envelope-open-o" />
                        {setting.display_email}
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="col-lg-3">
                  <img src={`${SETTING_FILE_PATH + setting.site_logo_large}`} height="70" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row align-items-center">

              <div className="col-lg-6 order-2 order-lg-1 mb-3 mb-lg-0">
                <ul className="list-unstyled nav-links m-0 nav-left">
                  <li><Link to={routes.about}>About Us</Link></li>
                  <li><Link to={routes.privacyPolicy}>Privacy Policy</Link></li>
                  <li><Link to={routes.termsConditions}>Terms & Conditions</Link></li>
                  <li><Link to={routes.allergyInformation}>Allergy Information</Link></li>
                </ul>
              </div>

              <div className="col-lg-3 text-lg-center footer-time order-lg-2 mb-3 mb-lg-0">

                {
                  footerTiming
                }
              </div>

              <div className="col-lg-3 text-lg-right order-3 order-lg-3">
                <ul className="list-unstyled nav-links social nav-right text-lg-right">
                  <li>
                    <a
                      href={setting.facebook_url}
                      target="_blank"
                      className="facebook"
                      rel="noreferrer"
                    >
                      <i className="fa fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a
                      href={setting.trip_advisor}
                      target="_blank"
                      className="twitter"
                      rel="noreferrer"
                    >
                      <i className="fa fa-tripadvisor" />
                    </a>
                  </li>
                  <li>
                    <a
                      href={setting.instagram_url}
                      target="_blank"
                      className="instagram"
                      rel="noreferrer"
                    >
                      <i className="fa fa-instagram" />
                    </a>
                  </li>

                </ul>
              </div>
            </div>
          </div>
        </footer>

      </section>

      <section className="w3l-footer-29-main w3l-copyright">
        <div className="container">
          <div className="bottom-copies">
            <div className="copy-footer-29 text-center">
              <Copyright />
            </div>
          </div>
        </div>

        <Hidden lgDown>
          <button onClick={() => topFunction()} id="movetop" title="Go to top">&#10548;</button>
        </Hidden>


        <Hidden mdUp>
          <BottomNav />
        </Hidden>


      </section>


    </>
  );
}

const mapStateToProps = (state) => ({

  show_footer: state.layout.show_footer,
  opening: state.opening.data,
  isLogin: state.user.isLoggedIn,
});

export default connect(mapStateToProps)(Footer2);
