import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  colors,
  Grid,
  MobileStepper,
  Paper,
  Typography,
  styled,
} from '@mui/material';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from '@stripe/react-stripe-js';

import './stripePayment.css';
import useNotify from '../../hooks/useNotify';
import { connect } from 'react-redux';
import { apiRequest } from '../../util/util';
import {
 API_CONFIRM_ORDER, API_GET_ORDER_TOKEN, API_STORE_ORDER
} from '../../util/constants';
import { useNavigate } from 'react-router-dom';
import routes from '../../util/routes';
import { updateShowPaymentModal } from '../../store/layout/layoutActions';

const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize: 18,
          color: '#424770',
          letterSpacing: '0.025em',
          fontFamily: 'Source Code Pro, monospace',
          '::placeholder': {
            color: '#aab7c4'
          },

        },
        invalid: {
          color: '#9e2146'
        }
      }
    }),
    []
  );

  return options;
};


const SplitForm = ({ ...otherProps }) => {

  const stripe = useStripe();
  const elements = useElements();
  const steps = ['Card Details'];

  const styles = {
    root: {
      maxWidth: 400,
      flexGrow: 1,
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      height: 50,
      paddingLeft: (theme) => theme.spacing(4),
      backgroundColor: (theme) => theme.palette.background.default,
    },
    nav: {
      backgroundColor: colors.grey['200'],
      marginTop: (theme) => theme.spacing(2),
      marginBottom: (theme) => theme.spacing(2)
    }
  };


  const [activeStep, setActiveStep] = useState(0);

  const maxSteps = steps.length;

  const [paymentInProgres, setPaymentInProgress] = useState(false);

  const options = useOptions();

  const navigate = useNavigate();

  const [paymentState, setPaymentState] = useState({
    total: 0,
    processing: false,
    paid: false
  });

  const [notify] = useNotify();

  const [tokenState, setTokenState] = useState({
    loading: true,
    token: '',
    id: '',
    initialized: false
  });

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <>
            {
              (tokenState.token === '')
              && <CircularProgress />
            }
            {
              (stripe && elements && tokenState.token !== '')
              && (
                <Grid container>
                  <Grid item xs={12} lg={12}>
                    <form>
                      <div>
                        <label>
                          Card number
                          <CardNumberElement
                            options={options}

                          />
                        </label>
                      </div>

                      <div>
                        <label>
                          Expiration date
                          <CardExpiryElement
                            options={options}
                          />
                        </label>

                      </div>

                      <div>
                        <label>
                          CVC
                          <CardCvcElement
                            options={options}
                          />
                        </label>
                      </div>
                    </form>

                  </Grid>

                </Grid>
              )
            }


          </>
        );

      default:
        throw new Error('Undefined step');
    }
  };

  const applyToken = async () => {

    try {

      const response = await getToken();

      setTokenState({
        ...tokenState,
        loading: false,
        initialized: false,
        ...response.data
      });

      return response.data;

    } catch (e) {
      notify.error('SERVER ERROR: Something went wrong. Try again later!');
      return false;
    }

  };

  const getToken = async () => {

    let totalAmount = otherProps.totalItemCost + otherProps.totalAddonsCost;
    totalAmount += otherProps.orderDelivery.charge;
    totalAmount -= otherProps.orderDiscount.value;
    totalAmount -= otherProps.orderCoupon.value;

    setPaymentState({
      ...paymentState,
      total: totalAmount
    });

    let tokenRequestData = {
      amount: totalAmount.toFixed(2),
    }

    const orderToken = await apiRequest.post(API_GET_ORDER_TOKEN, tokenRequestData);
    return orderToken
  };

  // get token if payment type is card & don't have token
  useEffect(() => {
    // don't continue if token exists
    if (tokenState.token.length) return;

    applyToken();

  }, []);

  const saveOrder = async (trx_id) => {

    const { cart } = otherProps;

    const orderRequestData = {
      payment: {
        method: cart.payment.method,
        status: true,
        trxId: trx_id,
      }
    };

    try {
      const orderResponse = await apiRequest.post(API_CONFIRM_ORDER, orderRequestData);
      return orderResponse.data;

    } catch (e) {

      notify.error('Something went wrong, Please try again in a while.');
      return { statue: false };
    }

  };

  const handleSubmit = async (event) => {

    event.preventDefault();

    setPaymentInProgress(true);

    if (!stripe || !elements) {
      setPaymentInProgress(false);
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const payload = await stripe.confirmCardPayment(tokenState.token, {
      payment_method: {
        card: elements.getElement(CardNumberElement)
      }
    });

    if (payload.error) {
      setPaymentInProgress(false);
      notify.error(payload.error.message);
      return;
    }

    if (payload.paymentIntent) {
      const orderSave = await saveOrder(payload.paymentIntent.id);

      if (orderSave.status) {
        setPaymentInProgress(false);
        otherProps.updateShowPaymentModal(true);
        notify.success('Order Successfull');
        navigate(routes.orderSuccess);
      } else {
        setPaymentInProgress(false);
        notify.warning('Something wrong, please try later');
      }

    }

  };

  return (
    <Box sx={styles.root}>
      {/*heading*/}
      <Paper square elevation={0} sx={styles.header}>
        <Typography variant="h4">{steps[activeStep]}</Typography>
      </Paper>

      {/*content*/}
      <Box>
        {getStepContent(activeStep)}
      </Box>

      {/*bottom*/}
      <MobileStepper
        sx={styles.nav}
        steps={maxSteps}
        position="static"
        variant="text"
        activeStep={activeStep}
        nextButton={
            paymentInProgres
              ? <CircularProgress />
              : (
                <Button size="small" onClick={handleSubmit}>
                  Pay

                </Button>
              )
          }
      />
    </Box>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  cart: state.cart,
  totalItemCost: state.cart.itemsTotal,
  totalAddonsCost: state.cart.addonsTotal,
  orderDiscount: state.cart.discount,
  orderCoupon: state.cart.coupon,
  orderDelivery: state.cart.delivery,
  deliveryAddress: state.cart.delivery.address,
  orderType: state.cart.order.type,
});

const mapDispatchToProps = (dispatch) => ({
  updateShowPaymentModal: (isOpen) => dispatch(updateShowPaymentModal(isOpen)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SplitForm);
