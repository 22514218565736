import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import './MainSlider.style.scss';
import useSetting from '../../../hooks/useSetting';
import { SETTING_FILE_PATH } from '../../../util/constants';
import routes from '../../../util/routes';
import Slider from 'react-slick';
import CompanyName from '../../util/CompanyName';


function MainSlider() {

  const setting = useSetting([
    'site_title',
    'home_page_caption',
    'home_page_tagline',
    'slider_img_1',
    'slider_img_2',
    'slider_img_3',
    'slider_vid_enable',
    'slider_vid_1',
    'slider_vid_2',
    'slider_vid_3',
    'slider_vid_4',
    'slider_vid_5',
  ]);

  const videos = useMemo(() => [
    setting.slider_vid_1,
    setting.slider_vid_2,
    setting.slider_vid_3,
    setting.slider_vid_4,
    setting.slider_vid_5,
  ].filter((vid) => vid), [setting]);

  const [readyVideos, setReadyVideos] = useState([]);

  const [sliders, setSliders] = useState(() => [
    {
      image: '',
      title: '',
      subtitle: '',
      description: '',
      links: [
        {
          title: 'Order Online',
          variant: 'primary',
          target: routes.order
        },
        // {
        //   title: 'Book A Table',
        //   variant: 'secondary',
        //   target: routes.reservation
        // }
      ]
    },
    {
      image: '',
      title: '',
      subtitle: '',
      description: '',
      links: [
        {
          title: 'Order Online',
          variant: 'primary',
          target: routes.order
        },
        // {
        //   title: 'Book A Table',
        //   variant: 'secondary',
        //   target: routes.reservation
        // }
      ]
    },
    {
      image: '',
      title: '',
      subtitle: '',
      description: '',
      links: [
        {
          title: 'Order Online',
          variant: 'primary',
          target: routes.order
        },
        // {
        //   title: 'Book A Table',
        //   variant: 'secondary',
        //   target: routes.reservation
        // }
      ]
    }
  ]);


  useEffect(() => {

    const firstSlide = sliders[0];

    if (firstSlide.image) return;

    if (setting.slider_img_1) {

      const newSlides = sliders.map((itm, idx) => ({
        ...itm,
        image: setting[`slider_img_${idx + 1}`],
        title: `${setting.site_title}`,
        subtitle: setting.home_page_caption
      }));

      setSliders(newSlides);
    }

  }, [setting]);


  const sliderElements = () => sliders.map((slider, idx) => (
    <div className="item" key={idx}>
      <div
        className="slider-info banner-view banner-top1"
        style={{ backgroundImage: `url(${SETTING_FILE_PATH + slider.image})` }}
      >
        <div className="banner-info container">
          <h3 className="banner-text">Welcome To</h3>
          {/*<h3 className="banner-text">{slider.title}</h3>*/}
          <CompanyName tag="h3" />
          <h5 className="banner-text">{slider.subtitle}</h5>
          <p>{slider.description}</p>
          {
            slider.links.map((link, idx2) => (
              <Link
                key={idx2}
                to={link.target}
                className={`btn ${link.variant === 'primary' ? 'btn-primary' : 'transparent-btn'} btn-style ml-2 mt-2`}
              >
                {link.title}
              </Link>
            ))
          }
        </div>
      </div>
    </div>
  ));

  let settings = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 30000000,
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const playFirstVideo = (e) => {
    const video = e.target;
    const videoId = video.dataset.id;

    // save the video instance
    setReadyVideos((prevVideos) => [...prevVideos, video]);

    // only play first video
    if (videoId != 0) return;

    video.style.opacity = 1;
    video.play();
  }

  const playNextVideo = (e) => {
    const endedVideo = e.currentTarget;
    const endedVideoId = parseInt(endedVideo.dataset.id);

    // hide ended video
    endedVideo.style.opacity = 0;

    const nextVideo = readyVideos[endedVideoId + 1];

    // if next video is not available
    if (!nextVideo) {
      // play first video
      const firstVideo = readyVideos[0];
      firstVideo.style.opacity = 1;
      firstVideo.play();

      return;
    }

    nextVideo.style.opacity = 1;
    nextVideo.play();

  }

  return (
    <section className="w3l-main-slider">
      <div className="companies20-content">
        <div className="companies-wrapper">
          {
            !setting?.slider_vid_enable
            && (
              <Slider {...settings}>
                {
                  sliderElements()
                }
              </Slider>
            )

          }

          {

            setting?.slider_vid_enable
            && (
              <div className="item">
                <div className="slider-info banner-view">
                  {/* <video
                  className="sliderVideo"
                  muted
                  autoplay
                  loop
                  // onEnded={playNextVideo}
                  // onCanPlay={playFirstVideo}
                >
                  <source src={SETTING_FILE_PATH + setting.slider_vid_1} type="video/mp4" />
                </video> */}
                  {
                    videos.map((video, idx) => (
                      <video
                        key={video}
                        className="sliderVideo"
                        muted
                        data-id={idx}
                        onEnded={playNextVideo}
                        onCanPlay={playFirstVideo}
                      >
                        <source src={SETTING_FILE_PATH + video} type="video/mp4" />
                      </video>
                    ))
                  }

                  <div className="banner-info container">

                    <h3 className="banner-text">
                      Welcome To
                    </h3>
                    <h3 className="banner-text">
                      {setting.site_title}
                    </h3>
                    <h5 className="banner-text">{setting.home_page_caption}</h5>
                    <p>{setting.home_page_tagline}</p>

                    <Link
                      to={routes.order}
                      className="btn btn-primary btn-style ml-2 mt-2"
                    >
                      Order Online
                    </Link>

                    <Link
                      to={routes.reservation}
                      className="btn transparent-btn btn-style mt-2 ml-2"
                    >
                      Reservation
                    </Link>

                  </div>
                </div>
              </div>
            )


          }

        </div>
      </div>
    </section>
  );
}

export default MainSlider;
